import React, { useContext, useEffect, useState } from "react";
import Back from "../components/Back";
import LocationPicker from "../components/LocationPicker";
import DashCard from "../components/DashCard";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedEmp, setSelectedDist } from "../redux/assignmentSlice";
import axiosInstance from "../interceptor/axiosInstance";
import AssgDistCard from "../components/AssgDistCard";
import { IoSearchOutline } from "react-icons/io5";
import { CircularProgress } from "@mui/material";

const AssignWork = () => {
  const [showProgress, setShowProgress] = useState();
  const [districts, setDistricts] = useState();
  const [filteredDistricts, setFilteredDistricts] = useState();
  const selectedEmp = useSelector(getSelectedEmp);
  const stateName = useSelector(state => state.state.stateName)
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(setSelectedDist(null));
  };

  const getDistricts = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `/api/district/get/data/${stateName}`
      );
      const districts = response?.data?.Data;
      setDistricts(districts);
      setFilteredDistricts(districts);
    } catch (err) {
      console.error(err);
    } finally {
      setShowProgress(false);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const result = districts?.filter((item) =>
      item?.district_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDistricts(result);
  };
  useEffect(() => {
    getDistricts();
  }, []);

  return (
    <div className="w-[90%] mx-auto">
      <div className="font-poppins">
        <div className="my-2 flex items-center justify-between">
          <h2 className="font-bold relative flex items-center text-blackSubDark text-lg">
            <span className="">
              <Back />
            </span>{" "}
            Employee Assignments
          </h2>

          {/* <LocationPicker /> */}
        </div>
      </div>

      <div className="my-2 flex items-center justify-start gap-8">
        <DashCard
          title="Employee name"
          color="white"
          item={selectedEmp?.name}
        />
        <DashCard
          title="Employee Id"
          color="lightBlue"
          item={selectedEmp?.emp_id}
        />
        <DashCard
          title="Status"
          color="lightYellow"
          item={selectedEmp?.user_status}
        />
      </div>
      <div className="my-3 bg-white flex items-center w-[560px] h-[40px] border border-gray-300 rounded-lg px-4 justify-between font-roboto shadow-md">
        <IoSearchOutline className="text-textGrey" />
        <input
          type="text"
          onChange={(e) => {
            handleSearch(e);
          }}
          placeholder="Search by district name"
          className="focus:outline-none w-3/4 text-textGrey text-[14px]"
        />
      </div>
      {showProgress ? (
        <div className="w-[50%] h-[30vh] flex items-center justify-center">
          <CircularProgress size={24} />
        </div>
      ) : (
        <div className="h-[47vh] overflow-y-scroll custom-scrollbar w-[80%] mt-4">
          <table className="w-full border-spacing-y-1 border-separate font-poppins">
            <thead>
              <tr className="mb-4 text-sm">
                <td className="pl-2 text-[15px] font-semibold">District</td>
                <td className="text-[15px] font-semibold">Total Wells</td>
                <td className="text-[15px] font-semibold">Active Well</td>
                <td className="text-[15px] font-semibold">Closed Wells</td>
              </tr>
            </thead>
            <tbody>
              {filteredDistricts?.map((item, index) => (
                <AssgDistCard
                  key={index}
                  item={item}
                  detailsIcon="false"
                  assign="true"
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AssignWork;
