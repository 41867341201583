import React from "react";
import { formatDateToShow } from "../utils/formatDate";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

const TableData = ({
  progress,
  data,
  handleRowClick,
  lastElementObserver,
  intermediateLoading,
}) => {
  const location = useLocation();
  const isEmployeeAnalytics = location.pathname.includes("employee-analytics");
  const heading = [
    "#",
    "District",
    "Assigned To",
    "Status",
    "Approve Status",
    "Assigned",
    "Pending",
    "Completed",
    "Sample Collect count",
    "Assign Date",
    "Session Start and End date",
  ];
  return (
    <table
      className={`min-w-full ${data?.length > 0 ? "h-auto" : "h-full"} ${
        progress && "h-full"
      } font-poppins table-fixed`}
    >
      <thead className="sticky bg-white w-full top-0">
        <tr>
          {heading?.map((head, index) => (
            <th
              key={index}
              className="text-[#000000] top-0 text-[13px] p-3 text-center"
            >
              {head}
            </th>
          ))}
        </tr>
      </thead>
      {progress ? (
        <tbody>
          <tr className="my-auto">
            <td colSpan={heading?.length} className="text-center">
              <CircularProgress size={24} />
            </td>
          </tr>
        </tbody>
      ) : data.length > 0 ? (
        <tbody>
          {data?.map((item, index) => (
            <tr
              ref={
                isEmployeeAnalytics && index === data?.length - 1
                  ? lastElementObserver
                  : null
              }
              key={index}
              className="cursor-pointer"
              onClick={() =>
                !isEmployeeAnalytics &&
                handleRowClick(
                  item?.district_name,
                  item?.district_id,
                  item?.emp_id,
                  item?.name,
                  item?.date,
                  item?.emp_assigned_date,
                  item?.state
                )
              }
            >
              <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                {index + 1}
              </td>
              <td className="flex flex-col items-center border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                <span>{item?.district_name}</span> <span>({item?.state})</span>
              </td>
              <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                {item?.name}
              </td>
              <td
                className={`${
                  item?.status === "Pending"
                    ? "text-red-500"
                    : item?.status === "In Progress"
                    ? "text-yellow-500"
                    : item?.status === "Completed"
                    ? "text-activeGreen"
                    : ""
                } border-b font-medium text-[#404040] text-[13px] text-center border-gray-200 p-3`}
              >
                {item?.status}
              </td>
              <td
                className={`${
                  item?.sessionstatus === "Pending"
                    ? "text-red-500"
                    : item?.sessionstatus === "Approved"
                    ? "text-activeGreen"
                    : ""
                } border-b font-medium text-[#404040] text-[13px] text-center border-gray-200 p-3`}
              >
                {item?.sessionstatus}
              </td>
              <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                {item?.survey_assigned}
              </td>
              <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                {item?.survey_pending}
              </td>
              <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                {item?.survey_completed}
              </td>
              <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                {item?.survey_sample_count}
              </td>
              <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                {formatDateToShow(item?.emp_assigned_date)}
              </td>
              <td className="border-b text-[#404040] text-[13px] text-center border-gray-200 p-3">
                {`${formatDateToShow(item?.session_start_date)} - ${
                  item?.session_end_date
                    ? formatDateToShow(item?.session_end_date)
                    : "Not assigned"
                }`}
              </td>
            </tr>
          ))}
          {isEmployeeAnalytics && intermediateLoading && (
            <tr>
              <td colSpan={heading?.length} className="text-center my-4">
                <CircularProgress color="default" size={17} />
              </td>
            </tr>
          )}
        </tbody>
      ) : (
        <tbody>
          <tr className="my-auto">
            <td colSpan={heading?.length} className="text-center">
              No data found.
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default TableData;
