import React, { useContext, useEffect, useState } from "react";
import moreBtn from "../assets/moreBtn.png";
import EmployeeCard from "./EmployeeCard";
import { useSelector } from "react-redux";
import { getUsers } from "../redux/usersSlice";
import { EmpManagementContext } from "../context/EmpManagementContext";
import axiosInstance from "../interceptor/axiosInstance";
import userPlaceholder from "../assets/user-placehoder.png";
import ConfirmationModal from "./ConfirmationModal";
import UpdateSuccessModal from "./UpdateSuccessModal";
const EmployeeList = ({ setOpen, open, searchTerms }) => {
  const users = useSelector(getUsers);
  const [page, setPage] = useState(1);
  const [moreOpen, setMoreOpen] = useState();
  const [confirmationModal, setConfirmationModal] = useState();
  const [selectedUser, setSelecteduser] = useState();

  const {
    setEditUserDetails,
    setCreateUser,
    setEmpModal,
    empModal,
    fetchUsers,
  } = useContext(EmpManagementContext);

  const handleEditUser = (user) => {
    setEditUserDetails(user);
    setCreateUser(true);
    setMoreOpen();
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const filterUser = users?.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerms.toLowerCase()) ||
      user.emp_id.toString().includes(searchTerms)
  );

  const selectPage = (selectedPage) => {
    const totalPages = Math.ceil(filterUser?.length / 5);
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPages &&
      selectedPage !== page
    )
      setPage(selectedPage);
  };

  const handleOpenEmpModal = (user) => {
    setEmpModal(user);
    setMoreOpen();
  };

  const handleUserStatusUpdate = (user) => {
    const response = axiosInstance.put("api/employee/status/deactive", {
      emp_id: user?.emp_id,
      user_status: user?.user_status === "Active" ? "Inactive" : "Active",
    });
    if (response) {
      setOpen("Status Update");
      setMoreOpen();
      setSelecteduser();
      setConfirmationModal();
      fetchUsers();
    }
  };

  const handleDelUser = (user) => {
    const response = axiosInstance.delete(
      `api/employee/data/delete/${user?.emp_id}`
    );
    if (response) {
      setOpen("Delete");
      setMoreOpen();
      setCreateUser();
      setConfirmationModal();
    }
  };

  return (
    <>
      <div className="bg-white rounded-md h-[70vh] overflow-y-auto custom-scrollbar shadow-sm my-6 font-roboto px-4 pt-3">
        {filterUser?.length > 0 ? (
          <table className="w-full border-b-collapse">
            <thead>
              <tr className="uppercase text-[15px] font-bold border-b border-blackSub">
                <td className="px-1 py-2">No.</td>
                <td className="px-4 py-2">Profile</td>
                <td className="px-4 py-2">Username</td>
                <td className="px-4 py-2">Unique ID</td>
                <td className="px-4 py-2">Role</td>
                <td className="px-4 py-2">Status</td>
                <td className="px-4 py-2"></td>
              </tr>
            </thead>
            <tbody>
              {/* {filterUser?.slice(page * 5 - 5, page * 5)?.map((user, index) => ( */}
              {filterUser?.map((user, index) => (
                <tr
                  className="capitalize text-sm border-b border-lightGrey"
                  key={index}
                >
                  <td className="px-1 py-2 ">{index + 1}.</td>

                  <td className="px-4 py-2">
                    <img
                      alt="profile"
                      src={user?.user_pic ? user?.user_pic : userPlaceholder}
                      className="w-10 h-10 rounded-full"
                    />
                  </td>
                  <td className="px-4 py-2 w-52">
                    {user?.name?.length > 40
                      ? `${user.name.slice(0, 40)}...`
                      : user?.name}
                  </td>
                  <td className="px-4 py-2 ">{user?.emp_id}</td>
                  <td className="px-4 py-2">{user?.role}</td>
                  <td
                    className={`px-4 py-2 ${
                      user?.user_status === "Active"
                        ? "text-activeGreen"
                        : "text-errorRed"
                    } font-semibold`}
                  >
                    {user?.user_status}
                  </td>
                  <td className="cursor-pointer px-4 py-2 relative">
                    <img
                      onClick={() =>
                        moreOpen === index ? setMoreOpen() : setMoreOpen(index)
                      }
                      src={moreBtn}
                      alt="more-btn"
                      className="w-7 h-7 rounded-full"
                    />

                    <ul
                      className={`absolute ${
                        moreOpen === index ? "opacity-100 z-10" : "opacity-0"
                      } transition-opacity bg-zinc-200 shadow-md text-xs w-32  rounded-lg top-4 -left-32 ${
                        user?.assigned_status === "Assigned"
                          ? "px-5 py-2"
                          : "p-2"
                      }  flex flex-col items-center gap-2`}
                    >
                      <li
                        onClick={() => handleEditUser(user)}
                        className="hover:underline  text-[15px] p-2"
                      >
                        Edit
                      </li>
                      <li
                        onClick={() => handleOpenEmpModal(user)}
                        className="hover:underline  text-[15px] p-2"
                      >
                        View
                      </li>
                      {user?.assigned_status === "Assigned" ? null : (
                        <>
                          {/* <li
                            onClick={() => {
                              setSelecteduser(user);
                              setConfirmationModal("delete");
                            }}
                            className="hover:underline text-errorRed  text-[15px] p-2"
                          >
                            Delete
                          </li> */}
                          <li
                            onClick={() => {
                              setSelecteduser(user);
                              setConfirmationModal(
                                user?.user_status === "Active"
                                  ? "deactivate"
                                  : "activate"
                              );
                            }}
                            className={`hover:underline text-[15px] p-2 ${
                              user?.user_status === "Active"
                                ? "text-errorRed"
                                : "text-successGreen"
                            }`}
                          >
                            {user?.user_status === "Active"
                              ? "Deactivate"
                              : "Activate"}
                          </li>
                        </>
                      )}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center my-4">No users found.</p>
        )}
      </div>
      {confirmationModal && (
        <ConfirmationModal
          setConfirmationModal={setConfirmationModal}
          type={confirmationModal}
          handleDelUser={handleDelUser}
          handleUserStatusUpdate={handleUserStatusUpdate}
          selectedUser={selectedUser}
          confirmationModal={confirmationModal}
        />
      )}
      {empModal && <EmployeeCard user={empModal} setEmpModal={setEmpModal} />}

      {open === "Status Update" && (
        <UpdateSuccessModal
          modal={open === "Status Update" && true}
          onSuccessFunction={fetchUsers}
          message={"User status updated successfully."}
          closeModal={() => setOpen()}
        />
      )}
      {open === "Delete" && (
        <UpdateSuccessModal
          modal={open === "Delete" && true}
          onSuccessFunction={fetchUsers}
          message={"User deleted successfully."}
          closeModal={() => setOpen()}
        />
      )}
    </>
  );
};

export default EmployeeList;
