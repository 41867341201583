import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useContext, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useLocation } from "react-router-dom";
import edit from "../assets/edit.png";
import placeholder from "../assets/placeholder.png";
import { DistrictsContext } from "../context/DistrictsContext";
import axiosInstance from "../interceptor/axiosInstance";
import { formatDateToShow } from "../utils/formatDate";
import EditForm from "./EditForm";
const WellHistoryDetailsCard = ({
  wellId,
  well,
  editBtn,
  approveBtn,
  assignedDate,
  setSelectedWell,
}) => {
  const [ModalOpen, setModalOpen] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const { setUpdatedModal } = useContext(DistrictsContext);
  const location = useLocation();

  const isSurveyReviewRoute = location.pathname.includes("survey-review");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen("image Preview");
  };

  const handleCloseImageModal = () => {
    setModalOpen();
    setSelectedImage("");
  };

  const handleApprove = async (well) => {
    const body = {
      well_id: [well?.well_id],
      Status: "Approved",
      admin_name: "Jalsakthi",
      survey_id: [well?.survey_id],
    };

    try {
      setShowProgress(true);
      const response = await axiosInstance.put(`/api/well/post/Approve`, body);
      if (!response.ok) {
        throw new Error("Error");
      }
    } catch (error) {
      console.error("Error updating status", error);
    } finally {
      setShowProgress(false);
    }
  };

  const surveyData = well?.well_type.toLowerCase().includes("piezometer")
    ? [
        {
          label: "Protection Box",
          value: well?.protection_box_status || "-",
        },
        {
          label: "Type Of Lock",
          value: well?.type_of_lock || "-",
        },

        {
          label: "Water Level (mbmp)",
          value: well?.water_level || "-",
        },
        {
          label: "Electrical Conductivity",
          value: well?.electrical_conductivity || "-",
        },
        {
          label: "Temperature(°C)",
          value: well?.temperature || "-",
        },
        {
          label: "pH",
          value: well?.ph_value || "-",
        },
        {
          label: "Ground Water Level Sample :",
          value: "",
        },
        {
          label: "sample status",
          value:
            well?.sample_taken === 0
              ? "Not Taken"
              : well?.sample_taken === 1
              ? "Taken"
              : "-",
        },
        {
          label: "Base Parameter",
          value: well?.well_bp_id,

          values:
            well?.sample_taken === 1
              ? [
                  {
                    label: "TH",
                    value: well?.th_value || "-",
                  },
                  {
                    label: "Ca",
                    value: well?.ca_value || "-",
                  },
                  {
                    label: "Mg",
                    value: well?.mg_value || "-",
                  },
                  {
                    label: "Na",
                    value: well?.na_value || "-",
                  },
                  {
                    label: "K",
                    value: well?.k_value || "-",
                  },
                  {
                    label: "CO3",
                    value: well?.co3_value || "-",
                  },
                  {
                    label: "HCO3",
                    value: well?.hco3_value || "-",
                  },
                  {
                    label: "CL",
                    value: well?.cl_value || "-",
                  },
                  {
                    label: "SO4",
                    value: well?.so4_value || "-",
                  },
                  {
                    label: "NO3",
                    value: well?.no3_value || "-",
                  },
                ]
              : null,
        },
        {
          label: "Heavy Metal",
          value: well?.well_hm_id,
          values:
            well?.sample_taken === 1
              ? [
                  {
                    label: "F",
                    value: well?.f_value || "-",
                  },
                  {
                    label: "U",
                    value: well?.u_value || "-",
                  },
                ]
              : null,
        },
        {
          label: "Remark",
          value: well?.remarks || "-",
        },
      ]
    : [
        {
          label: "Depth of Well (mbgl)",
          value: well?.survey_depth || "-",
        },
        {
          label: "Measuring Point (magl)",
          value: well?.survey_measuring_point || "-",
        },
        {
          label: "Diameter of Well (m)",
          value: well?.survey_diameter || "-",
        },

        {
          label: "Curbing Depth (mbgl)",
          value: well?.curbing_depth || "-",
        },
        {
          label: "Water Level (mbmp)",
          value: well?.water_level || "-",
        },
        {
          label: "Electrical Conductivity",
          value: well?.electrical_conductivity || "-",
        },
        {
          label: "Temperature(°C)",
          value: well?.temperature || "-",
        },
        {
          label: "pH",
          value: well?.ph_value || "-",
        },
        {
          label: "Use of Well",
          value: well?.use_of_well || "-",
        },

        {
          label: "Ground Water Level Sample :",
          value: "",
        },
        {
          label: "sample status",
          value:
            well?.sample_taken === 0
              ? "Not Taken"
              : well?.sample_taken === 1
              ? "Taken"
              : "-",
        },
        {
          label: "Base Parameter",
          value: well?.well_bp_id,
          values:
            well?.sample_taken === 1
              ? [
                  {
                    label: "TH",
                    value: well?.th_value || "-",
                  },
                  {
                    label: "Ca",
                    value: well?.ca_value || "-",
                  },
                  {
                    label: "Mg",
                    value: well?.mg_value || "-",
                  },
                  {
                    label: "Na",
                    value: well?.na_value || "-",
                  },
                  {
                    label: "K",
                    value: well?.k_value || "-",
                  },
                  {
                    label: "CO3",
                    value: well?.co3_value || "-",
                  },
                  {
                    label: "HCO3",
                    value: well?.hco3_value || "-",
                  },
                  {
                    label: "CL",
                    value: well?.cl_value || "-",
                  },
                  {
                    label: "SO4",
                    value: well?.so4_value || "-",
                  },
                  {
                    label: "NO3",
                    value: well?.no3_value || "-",
                  },
                ]
              : null,
        },
        {
          label: "Heavy Metal",
          value: well?.well_hm_id,
          values:
            well?.sample_taken === 1
              ? [
                  {
                    label: "F",
                    value: well?.f_value || "-",
                  },
                  {
                    label: "U",
                    value: well?.u_value || "-",
                  },
                ]
              : null,
        },
        {
          label: "Remark",
          value: well?.remarks || "-",
        },
      ];

  const surveyList = well?.well_type.toLowerCase().includes("piezometer")
    ? [
        {
          label: "Well Type",
          value: well?.well_type || "-",
        },
        {
          label: "Village",
          value: well?.village || "-",
        },
        {
          label: "Location Details",
          value: well?.location_detail || "-",
        },
        {
          label: "Latitude",
          value: well?.latitude || "-",
        },
        {
          label: "Longitude",
          value: well?.longitude || "-",
        },
        {
          label: "DMS Latitude",
          value: well?.dms_latitude || "-",
        },
        {
          label: "DMS Longitude",
          value: well?.dms_longitude || "-",
        },
        {
          label: "Depth Of Well (m)",
          value: well?.depth || "-",
        },
        {
          label: "Measuring Point (magl)",
          value: well?.measuring_point || "-",
        },

        {
          label: "Diameter of Well (m)",
          value: well?.diameter || well?.survey_diameter || "-",
        },
        {
          label: "Vendor",
          value: well?.vendor || "-",
        },
      ]
    : [
        {
          label: "Well Type",
          value: well?.well_type || "-",
        },
        {
          label: "Village",
          value: well?.village || "-",
        },
        {
          label: "Location Details",
          value: well?.location_detail || "-",
        },
        {
          label: "Latitude",
          value: well?.latitude || "-",
        },
        {
          label: "Longitude",
          value: well?.longitude || "-",
        },
        {
          label: "DMS Latitude",
          value: well?.dms_latitude || "-",
        },
        {
          label: "DMS Longitude",
          value: well?.dms_longitude || "-",
        },
        {
          label: "Depth of Well (mbgl)",
          value: well?.depth || "-",
        },
        {
          label: "Measuring Point (magl)",
          value: well?.measuring_point || "-",
        },
        {
          label: "Diameter of Well (m)",
          value: well?.diameter || "-",
        },
        {
          label: "Curbing Depth (mbgl)",
          value: well?.curbing_depth || "-",
        },
        {
          label: "Use of Well",
          value: well?.use_of_well || "-",
        },
      ];

  const wellDetailsHeader = [
    { label: "State", value: well?.state },
    { label: "District", value: well?.district_name },
    { label: "Firka", value: well?.firka },
  ];

  return (
    <div className="rounded-lg font-roboto w-[100%] bg-white pb-5 h-[80vh] overflow-y-auto custom-scrollbar">
      <div className="flex items-start bg-white rounded-lg gap-3 px-3 pb-4 justify-between">
        <div className="w-[50%]">
          <div className="flex items-center justify-between">
            <h1 className="font-poppins font-bold pt-4  text-buttonSecondary text-[17px] mb-2">
              Survey details
            </h1>
            {editBtn === true &&
            well?.survey_approve === "Approved" &&
            well?.sample_taken === 1 ? (
              <img
                onClick={() => setModalOpen("editView")}
                className="w-4 h-4  top-5 right-3 cursor-pointer"
                src={edit}
              />
            ) : null}
          </div>

          <table className="table-fixed text-xs border-spacing-y-2 border-separate text-blackSub">
            {isSurveyReviewRoute ? (
              <tbody>
                <tr>
                  <td>Survey Assigned</td>
                  <td className="px-1">:</td>
                  <td>{formatDateToShow(well?.assign_date) || "-"}</td>
                </tr>
                <tr>
                  <td>Survey By</td>
                  <td className="px-1">:</td>
                  <td>{well?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Employee ID</td>
                  <td className="px-1">:</td>
                  <td>{well?.empid || "-"}</td>
                </tr>
                <tr>
                  <td>Survey submitted</td>
                  <td className="px-1">:</td>
                  <td>{formatDateToShow(well?.createdat) || "-"}</td>
                </tr>
                <tr>
                  <td>Session Start - Session End </td>
                  <td className="px-1">:</td>
                  <td>
                    {formatDateToShow(well?.session_start_date)} -{" "}
                    {formatDateToShow(well?.session_end_date) || "Not assigned"}
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>Survey Assigned</td>
                  <td className="px-1">:</td>
                  <td>{formatDateToShow(well?.assign_date) || "-"}</td>
                </tr>
                <tr>
                  <td>Survey submitted</td>
                  <td className="px-1">:</td>
                  <td>{well?.createdat || "-"}</td>
                </tr>
                <tr>
                  <td>Survey By</td>
                  <td className="px-1">:</td>
                  <td>{well?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Employee ID</td>
                  <td className="px-1">:</td>
                  <td>{well?.emp_id || "-"}</td>
                </tr>
                <tr>
                  <td>Approved By</td>
                  <td className="px-1">:</td>
                  <td>{well?.reviewed_by || "-"}</td>
                </tr>
              </tbody>
            )}
          </table>

          {surveyData?.map((item, index) => (
            <>
              {item?.label === "Base Parameter" ||
              item?.label === "Heavy Metal" ? (
                <>
                  <h1 className="text-textGrey text-xs mt-4">
                    {item?.label} -{" "}
                    <span className="text-errorRed text-sm font-medium">
                      ({item?.value})
                    </span>
                  </h1>

                  <div className="grid grid-cols-2">
                    {item?.values?.map((item, i) => (
                      <div key={i} className="mt-2">
                        <h1 className="text-textGrey text-xs my-1">
                          {item?.label}
                        </h1>
                        <p className="font-medium text-blackSubDark">
                          {item?.value}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div key={index} className="mt-4">
                  <h1 className="text-textGrey text-xs my-1">{item?.label}</h1>
                  <p className="font-medium text-blackSubDark">{item?.value}</p>
                </div>
              )}
            </>
          ))}

          <h1 className="text-textGrey text-xs my-3">Photos</h1>
          <div className="flex flex-wrap gap-3 mt-2">
            {well?.well_image && well?.well_image?.length > 0 ? (
              well?.well_image?.map((image, i) => (
                <img
                  onClick={() => handleImageClick(image)}
                  key={i}
                  className="w-24 h-auto cursor-pointer rounded-lg"
                  src={image || placeholder}
                  alt={`well-image-${i}`}
                />
              ))
            ) : (
              <p>No images available</p>
            )}
          </div>
        </div>

        <div className="border border-l border-gray-200 border-1 w-0 h-[820px] mt-24 ">
          {" "}
        </div>
        <div className="w-[50%]">
          <h1 className="font-poppins font-bold pt-4 text-buttonSecondary text-[17px] mb-2">
            Well id : {well?.ex_well_id || "-"}
          </h1>
          <div className="flex flex-col items-start gap-1">
            {wellDetailsHeader?.map((item, i) => (
              <h4
                key={i}
                className="font-poppins text-sm font-bold text-buttonSecondary inline-block"
              >
                {item?.label} <span className="mx-1">:</span> {item?.value}
              </h4>
            ))}
          </div>
          <h4 className="font-poppins text-[15px] font-bold pt-2 text-buttonSecondary">
            Well status :{" "}
            <span
              className={`${
                well?.status === "Active"
                  ? "text-successGreen "
                  : "text-errorRed"
              } font-bold text-sm`}
            >
              {well?.status || "-"}
            </span>{" "}
          </h4>
          {well?.well_type.toLowerCase().includes("piezometer") && (
            <h4 className="font-poppins font-bold text-[15px] text-buttonSecondary">
              Key No : {well?.key_no || "-"}
            </h4>
          )}

          <p className="border border-x mt-4"></p>

          {surveyList.map((item) => {
            return (
              <div>
                <h1 className="text-textGrey text-xs mt-4">{item.label}</h1>
                <p className="text-[15px] text-blackSubDark">{item.value}</p>
              </div>
            );
          })}
          <h1 className="text-textGrey font-normal text-xs mt-4 mb-2">Map</h1>
          <iframe
            name="gMap"
            style={{ width: "100%", height: "250px" }}
            className="rounded-lg"
            src={`https://www.google.com/maps/embed/v1/place?q=${well?.latitude},${well?.longitude}&key=AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE`}
          ></iframe>
        </div>
      </div>
      {approveBtn === true && well?.survey_approve === "Pending" ? (
        <button
          onClick={() => {
            handleApprove(well);
            setUpdatedModal(true);
          }}
          className="bg-skyBlue text-white px-8 py-2 m-2 text-sm rounded-md"
        >
          {showProgress ? (
            <CircularProgress color="default" size={13} />
          ) : (
            "Approve"
          )}
        </button>
      ) : null}

      {/* Modal to display selected image */}
      <Modal
        open={ModalOpen === "image Preview"}
        onClose={handleCloseImageModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style }}>
          <button
            onClick={handleCloseImageModal}
            className="absolute top-5 right-5 bg-black bg-opacity-50 rounded-full text-white p-1"
          >
            <IoIosClose className="text-3xl" />
          </button>
          <div>
            <img
              className="w-[80%] h-[80%] mx-auto"
              src={selectedImage}
              alt="Selected Image"
            />
          </div>
        </Box>
      </Modal>

      <Modal
        open={ModalOpen === "editView"}
        onClose={handleCloseImageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute rounded-xl h-[80%] w-[50%] py-2 px-6 top-1/2 left-1/2 bg-white -translate-x-1/2 -translate-y-1/2">
          <EditForm
            setSelectedWell={setSelectedWell}
            handleCloseImageModal={handleCloseImageModal}
            well={well}
          />
        </div>
      </Modal>
    </div>
  );
};

const style = {
  padding: "4rem 0",
  width: "auto",
  height: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default WellHistoryDetailsCard;
