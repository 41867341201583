import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import excel from "../assets/Excel.png";

import excelImg from "../assets/Excel.png";
import previewImg from "../assets/Search.svg";
import upload_icon from "../assets/upload.svg";
import UpdateSuccessModal from "../components/UpdateSuccessModal";
import axiosInstance from "../interceptor/axiosInstance";
import { clearSession } from "../redux/sessionSlice";
import { setState } from "../redux/stateSlice";
import {
  tableHeadersAllType,
  tableHeadersDugWell,
  tableHeadersPiezometer,
} from "../utils/tableData";
import DownloadOrPreviewReport from "./DownloadOrPreviewReport";
import SessionDataTableModal from "./SessionDataTableModal";
import SurveyConfirmationModal from "./SurveyConfirmationModal";
import TableData from "./TableData";
import UploadSampleModal from "./UploadSampleModal";
import { formatDateToShow } from "../utils/formatDate";

const TableComponent = ({
  reportData,
  showProgress,
  selectedSession,
  completionStatus,
  getData,
  checkSession,
}) => {
  const [calendarDropDown, setCalendarDropDown] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [showSessionEndModal, setShowSessionEndModal] = useState();
  const [progress, setProgress] = useState(false);
  const [updatedModal, setUpdatedModal] = useState();
  const [showUploadSampleModal, setShowUploadSampleModal] = useState();
  const [uploadSample, setUploadSample] = useState(false);
  const [sessionHistoryData, setSessionHistoryData] = useState();
  const [originalSessionData, setOriginalSessionData] = useState();
  const [showPreviewModal, setShowPreviewModal] = useState();
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedWellType, setSelectedWellType] = useState(null); // To store selected well type
  const [showFieldSelectionModal, setShowFieldSelectionModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dugWellData = sessionHistoryData?.filter((survey) =>
    survey?.well_type?.toLowerCase()?.trim()?.includes("dug well")
  );
  const piezometerData = sessionHistoryData?.filter((survey) =>
    survey?.well_type?.toLowerCase()?.trim()?.includes("piezometer")
  );
  const fieldNames = {
    // Dug Well fields (Well Profile)
    state: "State",
    district_name: "District",
    session_start_date: "Session start date",
    session_end_date: "Session end date",
    firka: "Firka",
    village: "Village",
    ex_well_id: "Well ID",
    well_type: "Well Type",
    status: "Well Status",
    location_detail: "Location Details",
    latitude: "Latitude",
    longitude: "Longitude",
    dms_latitude: "DMS Latitude",
    dms_longitude: "DMS Longitude",
    depth: "Depth Of well(mbgl)",
    measuring_point: "Measuring point(magl)",
    diameter: "Diameter Of well(m)",
    curbing_depth: "Curbing depth(mbgl)",
    use_of_well: "Use of well",

    // Dug Well fields (Survey Details)
    name: "Survey By",
    assign_date: "Assigne Date",
    createdat: "Survey Submit",
    survey_depth: "Depth Of Well(mbgl)",
    survey_measuring_point: "Measuring Point(magl)",
    survey_diameter: "Diameter Of Well(m)",
    survey_curbing_depth : "Curbing Depth(mbgl)",
    water_level: "Water Level(mbmp)",
    survey_use_of_well :"Use of Well",
    electrical_conductivity: "Electrical Conductivity (ms/cm)",
    temperature: "Temperature",
    ph_value: "pH Value",
    sample_taken: "Sample Collect Status",
    well_bp_id: "Base Parameter sample Number",
    th_value: "TH",
    ca_value: "Ca",
    mg_value: "Mg",
    na_value: "Na",
    k_value: "K",
    co3_value: "CO3",
    hco3_value: "HCO3",
    cl_value: "Cl",
    so4_value: "SO4",
    no3_value: "NO3",
    well_hm_id: "Heavy Metals Sample number",
    f_value: "F",
    u_value: "U",
    remarks: "Remarks",

    // Piezometer fields (Well Profile)
    key_no: "Key Number",
    vendor: "Vendor",
    protection_box_status: "Protection Box Status",
    type_of_lock: "Type of Lock",

    // Piezometer fields (Survey Details)
    // Repeat the same pattern for piezometer fields...
  };

  const fieldTitles = {
    "Dug Well": "Dug Well Profile Fields",
    Piezometer: "Piezometer Profile Fields",
    "All Well Type":"Download All Well Type"
  };
  const availableFields = {
    "Dug Well": {
      "Well Profile": [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "curbing_depth",
        "use_of_well",
      ],
      "Survey Details": [
        "name",
        "session_start_date",
        "session_end_date",
        "assign_date",
        "createdat",
        "survey_depth",
        "survey_measuring_point",
        "survey_diameter",
        "survey_curbing_depth",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "survey_use_of_well",
        "remarks",
        "sample_taken",
      ],
      "Ground Water Sample": {
        well_bp_id: [
          "well_bp_id",
          "th_value",
          "ca_value",
          "mg_value",
          "na_value",
          "k_value",
          "co3_value",
          "hco3_value",
          "cl_value",
          "so4_value",
          "no3_value",
        ],
        well_hm_id: ["well_hm_id", "f_value", "u_value"],
      },
    },
    Piezometer: {
      "Well Profile": [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "key_no",
        "vendor",
        
      ],
      "Survey Details": [
        "name",
        "session_end_date",
        "session_start_date",
        "assign_date",
        "createdat",
        // "survey_depth",
        "protection_box_status",
        "type_of_lock",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "remarks",
        "sample_taken",
      ],
      "Ground Water Sample": {
        well_bp_id: [
          "well_bp_id",
          "th_value",
          "ca_value",
          "mg_value",
          "na_value",
          "k_value",
          "co3_value",
          "hco3_value",
          "cl_value",
          "so4_value",
          "no3_value",
        ],
        well_hm_id: ["well_hm_id", "f_value", "u_value"],
      },
    },
    "All Well Type": {
      "Well Profile": [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "curbing_depth",
        "use_of_well",
        "key_no",
        "vendor",
      ],
      "Survey Details": [
        "name",
        "session_start_date",
        "session_end_date",
        "assign_date",
        "createdat",
        "survey_depth",
        "survey_measuring_point",
        "survey_diameter",
        "survey_curbing_depth",
        "protection_box_status",
        "type_of_lock",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "survey_use_of_well",
        "remarks",
        "sample_taken",
      ],
      "Ground Water Sample": {
        well_bp_id: [
          "well_bp_id",
          "th_value",
          "ca_value",
          "mg_value",
          "na_value",
          "k_value",
          "co3_value",
          "hco3_value",
          "cl_value",
          "so4_value",
          "no3_value",
        ],
        well_hm_id: ["well_hm_id", "f_value", "u_value"],
      },
    },
  };
  const handleDownloadExcel = (wellType) => {
    // Clear previously selected fields
    setSelectedFields([]); // Reset selected fields

    setSelectedWellType(wellType); // Store the selected well type
    setShowFieldSelectionModal(true); // Show the modal
  };

  const handleFieldSelection = () => {
    // Generate the Excel sheet with the selected fields
    generateExcel(selectedFields);
    setShowFieldSelectionModal(false); // Close the modal after selection
  };

  const generateExcel = (fields) => {
    if (!sessionHistoryData || sessionHistoryData.length === 0) {
      alert("No data available for download.");
      return;
    }
  
    // const filterData = sessionHistoryData?.filter((well) =>
    //   well?.well_type.toLowerCase().includes(selectedWellType.toLowerCase())
    // );


    const filterData = sessionHistoryData.filter((well) => {
      if (selectedWellType === "All Well Type") {
        return (
          well?.well_type.toLowerCase().includes("dug well") ||
          well?.well_type.toLowerCase().includes("piezometer")
        );
      }
      return well?.well_type.toLowerCase().includes(selectedWellType.toLowerCase());
    });

    const fixedOrder = {
      "Dug Well": [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "curbing_depth",
        "use_of_well",
        "name",
        "session_end_date",
        "session_start_date",
        "assign_date",
        "createdat",
        "survey_depth",
        "survey_measuring_point",
        "survey_diameter",
        "survey_curbing_depth",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "survey_use_of_well",
        "remarks",
        "sample_taken",
        "well_bp_id",
        "th_value",
        "ca_value",
        "mg_value",
        "na_value",
        "k_value",
        "co3_value",
        "hco3_value",
        "cl_value",
        "so4_value",
        "no3_value",
        "well_hm_id",
        "f_value",
        "u_value",
      ],
      Piezometer: [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "key_no",
        "vendor",        
        "name",
        "session_start_date",
        "session_end_date",
        "assign_date",
        "createdat",
        "survey_depth",
        "protection_box_status",
        "type_of_lock",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "remarks",
        "sample_taken",
        "well_bp_id",
        "th_value",
        "ca_value",
        "mg_value",
        "na_value",
        "k_value",
        "co3_value",
        "hco3_value",
        "cl_value",
        "so4_value",
        "no3_value",
        "well_hm_id",
        "f_value",
        "u_value",
      ],
      "All Well Type": [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "curbing_depth",
        "use_of_well",
        "key_no",
        "vendor",
        "name",
        "session_end_date",
        "session_start_date",
        "assign_date",
        "createdat",
        "survey_depth",
        "survey_measuring_point",
        "survey_diameter",
        "survey_curbing_depth",
        "protection_box_status",
        "type_of_lock",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "survey_use_of_well",
        "remarks",
        "sample_taken",
        "well_bp_id",
        "th_value",
        "ca_value",
        "mg_value",
        "na_value",
        "k_value",
        "co3_value",
        "hco3_value",
        "cl_value",
        "so4_value",
        "no3_value",
        "well_hm_id",
        "f_value",
        "u_value",
      ],
    };


    const sortedFields = fixedOrder[selectedWellType].filter((field) =>
      fields.includes(field)
    );
   
    const formattedData = filterData.map((well, i) => {
      let formattedWell = { "No.": i + 1 };

    sortedFields.forEach((field) => {
            // Format date fields
            if (
              field === "session_start_date" ||
              field === "session_end_date" ||
              field === "assign_date" ||
              field === "completed_date" ||
              field === "createdat"
    
            ) {
              formattedWell[fieldNames[field] || field] = formatDateToShow(
                well[field.toLowerCase()]
              );
            } else if (field === "sample_taken") {
              formattedWell[fieldNames[field] || field] =
                well[field.toLowerCase()] === 1 ? "Taken" : "Not Taken";
            } else {
              formattedWell[fieldNames[field] || field] =
                well[field.toLowerCase()] || "-";
            }
          });

      return formattedWell;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Adjust column widths based on the maximum content length in each column
    worksheet["!cols"] = sortedFields.map((field) => {
      const maxLength = Math.max(
        fieldNames[field]?.length || field.length,
        ...formattedData.map(
          (row) => row[fieldNames[field] || field]?.toString().length || 0
        )
      );
      return { wpx: (maxLength + 2) * 8 };
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `${selectedWellType} Data`
    );

    // Save the workbook
    XLSX.writeFile(
      workbook,
      `${formatDateToShow(
        selectedSession?.session_start_date
      )}-${formatDateToShow(selectedSession?.session_end_date)}-data.xlsx`
    );  };

  const closeFieldSelectionModal = () => {
    setShowFieldSelectionModal(false);
  };

  const handleDownloadDugWellHistory = () => {
    if (!dugWellData) return;
    const data = dugWellData?.map((survey, i) => {
      return {
        "No.": i + 1,
        "Well Id": survey?.ex_well_id || "-",
        "Well Type": survey?.well_type || "-",
        Employee: survey?.name || "-",
        District: survey?.district_name || "-",
        Village: survey?.village || "-",
        Firka: survey?.firka || "-",
        "Location Details": survey?.location_detail || "-",
        "Survey By": survey?.name || "-",
        "Survey Assigned": formatDateToShow(survey?.assign_date) || "-",
        "Submitted Date": formatDateToShow(survey?.createdat) || "-",
        "Depth of Well (mbgl)": survey?.survey_depth || "-",
        "Diameter of Well (m)": survey?.survey_diameter || "-",
        "Measuring Point (magl)": survey?.survey_measuring_point || "-",
        "Curbing Depth": survey?.survey_curbing_depth || "-",
        "Water Level(mbmp)": survey?.water_level || "-",
        "Electrical Conductivity": survey?.electrical_conductivity || "-",
        "Temperature °C": survey?.temperature || "-",
        PH: survey?.ph_value || "-",
        "Use of Well": survey?.survey_use_of_well || "-",
        "Sample Status": survey?.sample_taken || "-",
        "Base Parameter": survey?.well_bp_id || "-",
        TH: survey?.th_value || "-",
        Ca: survey?.ca_value || "-",
        Mg: survey?.mg_value || "-",
        Na: survey?.na_value || "-",
        K: survey?.k_value || "-",
        CO3: survey?.co3_value || "-",
        HCO3: survey?.hco3_value || "-",
        CL: survey?.cl_value || "-",
        SO4: survey?.so4_value || "-",
        NO3: survey?.no3_value || "-",
        "Heavy Metal": survey?.well_hm_id || "-",
        F: survey?.f_value || "-",
        U: survey?.u_value || "-",
        Remarks: survey?.remarks || "-",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

    // Save the workbook
    XLSX.writeFile(
      workbook,
      `${formatDateToShow(
        selectedSession?.session_start_date
      )}-${formatDateToShow(
        selectedSession?.session_end_date
      )}-dugWell-data.xlsx`
    );
  };
  const handleDownloadPiezometerHistory = () => {
    if (!piezometerData) return;
    const data = piezometerData?.map((survey, i) => {
      return {
        "No.": i + 1,
        "Well Id": survey?.ex_well_id || "-",
        "Well Type": survey?.well_type || "-",
        Employee: survey?.name || "-",
        District: survey?.district_name || "-",
        Village: survey?.village || "-",
        Firka: survey?.firka || "-",
        "Location Details": survey?.location_detail || "-",
        "Survey By": survey?.name || "-",
        "Survey Assigned": formatDateToShow(survey?.assign_date) || "-",
        "Submitted Date": formatDateToShow(survey?.createdat) || "-",
        "Depth of Well (mbgl)": survey?.survey_depth || "-",
        "Diameter of Well (m)": survey?.survey_diameter || "-",
        "Measuring Point (magl)": survey?.survey_measuring_point || "-",
        "Protection Box": survey?.protection_box_status || "-",
        "Type Of Lock": survey?.type_of_lock || "-",
        "Water Level(mbmp)": survey?.water_level || "-",
        "Electrical Conductivity": survey?.electrical_conductivity || "-",
        "Temperature °C": survey?.temperature || "-",
        PH: survey?.ph_value || "-",
        "Key No": survey?.key_no || "-",
        Vendor: survey?.vendor || "-",
        "Sample Status": survey?.sample_taken || "-",
        "Base Parameter": survey?.well_bp_id || "-",
        TH: survey?.th_value || "-",
        Ca: survey?.ca_value || "-",
        Mg: survey?.mg_value || "-",
        Na: survey?.na_value || "-",
        K: survey?.k_value || "-",
        CO3: survey?.co3_value || "-",
        HCO3: survey?.hco3_value || "-",
        CL: survey?.cl_value || "-",
        SO4: survey?.so4_value || "-",
        NO3: survey?.no3_value || "-",
        "Heavy Metal": survey?.well_hm_id || "-",
        F: survey?.f_value || "-",
        U: survey?.u_value || "-",
        Remarks: survey?.remarks || "-",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

    // Save the workbook
    XLSX.writeFile(
      workbook,
      `${formatDateToShow(
        selectedSession?.session_start_date
      )}-${formatDateToShow(
        selectedSession?.session_end_date
      )}-piezometer-data.xlsx`
    );
  };

  const handleDownloadHistory = () => {
    if (!sessionHistoryData) return;
    const data = sessionHistoryData?.map((survey, i) => {
      return {
        "No.": i + 1,
        "Well Id": survey?.ex_well_id || "-",
        "Well Type": survey?.well_type || "-",
        Employee: survey?.name || "-",
        District: survey?.district_name || "-",
        Village: survey?.village || "-",
        Firka: survey?.firka || "-",
        "Location Details": survey?.location_detail || "-",
        "Survey By": survey?.name || "-",
        "Survey Assigned": formatDateToShow(survey?.assign_date) || "-",
        "Submitted Date": formatDateToShow(survey?.createdat) || "-",
        "Depth of Well (mbgl)": survey?.survey_depth || "-",
        "Diameter of Well (m)": survey?.survey_diameter || "-",
        "Measuring Point (magl)": survey?.survey_measuring_point || "-",
        "Curbing Depth": survey?.survey_curbing_depth || "-",
        "Water Level(mbmp)": survey?.water_level || "-",
        "Electrical Conductivity": survey?.electrical_conductivity || "-",
        "Protection Box": survey?.protection_box_status || "-",
        "Type Of Lock": survey?.type_of_lock || "-",
        "Temperature °C": survey?.temperature || "-",
        PH: survey?.ph_value || "-",
        "Key No": survey?.key_no || "-",
        Vendor: survey?.vendor || "-",
        "Use of Well": survey?.survey_use_of_well || "-",
        "Sample Status": survey?.sample_taken || "-",
        "Base Parameter": survey?.well_bp_id || "-",
        TH: survey?.th_value || "-",
        Ca: survey?.ca_value || "-",
        Mg: survey?.mg_value || "-",
        Na: survey?.na_value || "-",
        K: survey?.k_value || "-",
        CO3: survey?.co3_value || "-",
        HCO3: survey?.hco3_value || "-",
        CL: survey?.cl_value || "-",
        SO4: survey?.so4_value || "-",
        NO3: survey?.no3_value || "-",
        "Heavy Metal": survey?.well_hm_id || "-",
        F: survey?.f_value || "-",
        U: survey?.u_value || "-",
        Remark: survey?.remarks || "-",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

    // Save the workbook
    XLSX.writeFile(
      workbook,
      `${formatDateToShow(
        selectedSession?.session_start_date
      )}-${formatDateToShow(selectedSession?.session_end_date)}-data.xlsx`
    );
  };

  const totalSurveySampleCount = reportData?.reduce((sum, item) => {
    return sum + item?.survey_sample_count;
  }, 0);

  const handleRowClick = (
    district_name,
    district_id,
    emp_id,
    emp_name,
    date,
    emp_assigned_date,
    state
  ) => {
    dispatch(setState(state));
    navigate(`/survey-review/${district_name}/${emp_id}`, {
      state: { emp_name, date, emp_assigned_date },
    });
  };

  // const handleSelectDate = (date) => {
  //   setSelectedDate(formatDate(date));
  //   setCalendarDropDown("");
  //   setShowSessionEndModal(true);
  // };

  const handleCalendarDropDown = () => {
    completionStatus === false && calendarDropDown === ""
      ? setCalendarDropDown("alert")
      : setCalendarDropDown("");
    completionStatus === true && setShowSessionEndModal(true);
  };

  const handleCloseSessionEndModal = () => {
    setShowSessionEndModal("");
  };

  const handleSessionEnd = async () => {
    try {
      setProgress(true);
      const data = {
        start_date: selectedSession?.session_start_date,
        end_date: selectedDate,
      };
      const response = await axiosInstance.put(
        `/api/work/update/session/end/date`,
        { data }
      );
      setSelectedDate();
      setShowSessionEndModal("");
      setProgress(false);
      dispatch(clearSession());
      setUpdatedModal("end");
      checkSession();
    } catch (error) {
      console.log(error);
    }
  };

  const getSampleUploadStatus = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/well/check/sample/uploaded_or_not/${selectedSession?.session_start_date}/${selectedSession?.session_end_date}`
      );
      const data = response?.data?.Data;
      if (
        data &&
        data !== "The session samples are updated" &&
        data?.length > 0
      ) {
        setUploadSample(true);
      } else {
        setUploadSample(false);
      }
    } catch (error) {
      console.log("Failed to get upload sample status", error);
    }
  };

  const getSessionData = async () => {
    try {
      const response = await axiosInstance.get(
        `api/employee/session/data/${selectedSession?.session_start_date}/${selectedSession?.session_end_date}`
      );
      const data = response?.data?.Session_start_date;
      setSessionHistoryData(data);
      setOriginalSessionData(data);
    } catch (error) {
      console.log("Failed to get session data", error);
    }
  };

  const handlePreview = (type) => {
    setShowPreviewModal(type);
    if (type?.includes("dug well") || type?.includes("piezometer")) {
      const filteredData = originalSessionData?.filter((survey) =>
        survey?.well_type?.toLowerCase()?.includes(type)
      );
      setSessionHistoryData(filteredData);
    } else {
      setSessionHistoryData(originalSessionData);
    }
  };

  useEffect(() => {
    if (selectedSession) getSampleUploadStatus();
    if (selectedSession) getSessionData();
  }, [selectedSession]);

  useEffect(() => {
    setCalendarDropDown("");
    setSelectedDate(new Date());
  }, [completionStatus, reportData]);

  const getTableHeaders = () => {
    switch (showPreviewModal) {
      case "dug well":
        return tableHeadersDugWell;
      case "piezometer":
        return tableHeadersPiezometer;
      case "all type":
        return tableHeadersAllType;
    }
  };

  return (
    <>
      {showPreviewModal && (
        <SessionDataTableModal
          setShowPreviewModal={setShowPreviewModal}
          getTableHeaders={getTableHeaders}
          sessionHistoryData={sessionHistoryData}
        />
      )}
      <div className="bg-skyBlue p-4 rounded-t-lg mt-4 font-inter">
        <div className="flex items-center justify-center relative">
          <div className="font-bold text-lg">
            <span className="text-white">
              {formatDateToShow(selectedSession?.session_start_date)}
            </span>
            <span
              className={`
              ${
                selectedSession?.session_end_date
                  ? "text-errorRed"
                  : "text-black"
              }
            mx-4`}
            >
              To
            </span>
            <span
              className={
                selectedSession?.session_end_date
                  ? "text-white"
                  : "text-errorRed"
              }
            >
              {formatDateToShow(selectedSession?.session_end_date) ||
                "END DATE NOT ASSIGNED"}
            </span>
          </div>
          {!selectedSession?.session_end_date && reportData?.length > 0 && (
            <div className="absolute right-8 flex flex-col items-center gap-4">
              {/* <div className="flex gap-2">
                  <div
                    className={`cursor-pointer flex items-center gap-3 text-xs p-2 font-medium ${
                      completionStatus === true ? "text-black" : "text-textGrey"
                    }  bg-white rounded-md`}
                    onClick={handleCalendarDropDown}
                  >
                    {selectedDate
                      ? formatDateToShow(selectedDate)
                      : "End Session"}
                    <span className="text-black">
                      <FaChevronDown />
                    </span>
                  </div>
                </div> */}
              <div className="flex gap-2">
                <div
                  className={`text-xs p-2 font-medium ${
                    completionStatus === true
                      ? "text-activeGreen"
                      : "text-textGrey"
                  }  bg-white rounded-md`}
                >
                  {selectedDate && formatDateToShow(selectedDate)}
                </div>
                <button
                  // disabled={!selectedDate}
                  onClick={handleCalendarDropDown}
                  className={`text-xs px-4 py-2 font-medium ${
                    completionStatus === true
                      ? "bg-buttonPrimary text-white"
                      : "bg-white text-textGrey"
                  }  rounded-md`}
                >
                  END SESSION
                </button>
              </div>

              <div
                className={`absolute ${
                  calendarDropDown === "alert"
                    ? "-bottom-[30px] z-10 opacity-100"
                    : "-bottom-0 -z-10 opacity-0"
                } transition-all duration-300 right-0 bg-white p-1 rounded-md shadow-lg`}
              >
                <p className="w-[400px] text-sm text-errorRed text-center">
                  Complete and Approve all assigned surveys to end session.
                </p>
              </div>
            </div>
          )}
          {selectedSession?.session_end_date && uploadSample && (
            <div
              onClick={() => setShowUploadSampleModal(true)}
              className="bg-white cursor-pointer absolute right-8 transition-colors duration-300 flex items-center gap-1 p-2 rounded-lg shadow-lg"
            >
              <img className="w-4" src={upload_icon} alt="upload_icon" />
              <span className="font-roboto font-medium text-xs">
                Upload Survey Sample Lab Report
              </span>
            </div>
          )}
        </div>
        <div className="w-full h-[0.7px] my-4 bg-white"></div>

        <div className="flex items-center justify-between">
          <DownloadOrPreviewReport
            title="Preview Current Survey well data"
            dugWellFunc={() => handlePreview("dug well")}
            piezoFunc={() => handlePreview("piezometer")}
            AllType={() => handlePreview("all type")}
            img={previewImg}
          />
          <div className="w-[35%]">
            <div className="h-24 bg-[#3EB4E9] rounded-2xl font-poppins">
              <h1 className="font-medium py-2  text-center text-sm">
                Download Survey Well Data in Excel
              </h1>
              <div className="flex justify-center items-center gap-6 pt-2">
                <div
                  onClick={() => handleDownloadExcel("Dug Well")}
                  className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
                >
                  <img className="w-5" src={excel} alt="" />
                  <span>Dug Well</span>
                </div>
                <div
                  onClick={() => handleDownloadExcel("Piezometer")}
                  className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
                >
                  <img className="w-5" src={excel} alt="" />
                  <span>Piezometer</span>
                </div>
                <div
                  onClick={() => handleDownloadExcel("All Well Type")}
                  className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
                >
                  <img className="w-5" src={excel} alt="" />
                  <span>All Well Type</span>
                </div>
              </div>
            </div>
          </div>
          {/* <DownloadOrPreviewReport
              title="Download Survey Well Data in Excel"
              dugWellFunc={handleDownloadDugWellHistory}
              piezoFunc={handleDownloadPiezometerHistory}
              AllType={handleDownloadHistory}
              img={excelImg}
            /> */}
        </div>
      </div>

      <div className="overflow-auto h-[70vh] custom-scrollbar w-[100%] mx-auto mb-4  bg-[#FFFFFF] shadow-sm rounded-b-lg font-inter">
        <TableData
          handleRowClick={handleRowClick}
          progress={showProgress}
          data={reportData}
        />
      </div>
      <SurveyConfirmationModal
        showModal={showSessionEndModal}
        closeModal={handleCloseSessionEndModal}
        proceed={handleSessionEnd}
        title="Are you sure want to end the session?"
        progress={progress}
      />
      {updatedModal === "end" ? (
        <UpdateSuccessModal
          modal={updatedModal === "end" && true}
          onSuccessFunction={getData}
          closeModal={() => setUpdatedModal()}
          message={"Session ended successfully!"}
        />
      ) : null}
      {updatedModal === "sample uploaded" ? (
        <UpdateSuccessModal
          modal={updatedModal === "sample uploaded" && true}
          onSuccessFunction={getSampleUploadStatus}
          closeModal={() => setUpdatedModal()}
          message={"Sample Updated successfully!"}
        />
      ) : null}
      {showUploadSampleModal && (
        <UploadSampleModal
          sampleCount={totalSurveySampleCount}
          selectedSession={selectedSession}
          setShowUploadSampleModal={setShowUploadSampleModal}
          openSampleUploaded={() => setUpdatedModal("sample uploaded")}
        />
      )}
      {showFieldSelectionModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div className="bg-white rounded-md p-5 w-[94%] h-[90vh] overflow-auto custom-scrollbar">
            <h3 className="font-semibold mb-2">
              {fieldTitles[selectedWellType] || "Select Fields to Download"}
            </h3>

            {/* Well Profile Section */}
            <div className="">
              <h4 className="font-semibold mb-2">Well Profile</h4>
              <div className="max-h-70 overflow-auto grid grid-cols-5 gap-x-4">
                {availableFields[selectedWellType]?.["Well Profile"]?.map(
                  (field) => (
                    <div key={field} className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        id={field}
                        value={field}
                        onChange={(e) => {
                          const newFields = e.target.checked
                            ? [...selectedFields, field]
                            : selectedFields.filter((f) => f !== field);
                          setSelectedFields(newFields);
                        }}
                      />
                      <label
                        htmlFor={field}
                        className="ml-2"
                        style={{ fontSize: "13px" }}
                      >
                        {fieldNames[field] || field}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* Survey Details Section */}
            <div>
              <h4 className="font-semibold mb-2">Survey Details</h4>
              <div className="max-h-70 overflow-auto grid grid-cols-6">
                {availableFields[selectedWellType]?.["Survey Details"]?.map(
                  (field) => (
                    <div key={field} className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        id={field}
                        value={field}
                        onChange={(e) => {
                          const newFields = e.target.checked
                            ? [...selectedFields, field]
                            : selectedFields.filter((f) => f !== field);
                          setSelectedFields(newFields);
                        }}
                      />
                      <label htmlFor={field} style={{fontSize:"14px"}} className="ml-2">
                        {fieldNames[field] || field}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Ground Water Sample Section */}
            <div>
              <h4 className="font-semibold mb-2 mt-2">Ground Water Sample</h4>

              {/* Base Parameters */}
              <div className="mb-2">
                <h5 className="font-semibold mb-2">Base Parameters</h5>
                <div className="max-h-70 overflow-auto grid grid-cols-6">
                {availableFields[selectedWellType]?.[
                    "Ground Water Sample"
                  ]?.well_bp_id?.map((field) => (
                    <div key={field} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={field}
                        value={field}
                        onChange={(e) => {
                          const newFields = e.target.checked
                            ? [...selectedFields, field]
                            : selectedFields.filter((f) => f !== field);
                          setSelectedFields(newFields);
                        }}
                      />
                      <label htmlFor={field} style={{fontSize:"14px"}} className="ml-2">
                      {fieldNames[field] || field}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Heavy Metals */}
              <div>
                <h5 className="font-semibold mb-2">Heavy Metals</h5>
                <div className="max-h-70 overflow-auto grid grid-cols-6">
                {availableFields[selectedWellType]?.[
                    "Ground Water Sample"
                  ]?.well_hm_id?.map((field) => (
                    <div key={field} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={field}
                        value={field}
                        onChange={(e) => {
                          const newFields = e.target.checked
                            ? [...selectedFields, field]
                            : selectedFields.filter((f) => f !== field);
                          setSelectedFields(newFields);
                        }}
                      />
                      <label htmlFor={field} style={{fontSize:"14px"}} className="ml-2">
                      {fieldNames[field] || field}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-4">
              <button
                onClick={closeFieldSelectionModal}
                className="text-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleFieldSelection}
                className="bg-blue-500 text-white p-2 rounded-md"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableComponent;
