import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAccessToken,
  setAuthenticated,
  setRefreshToken,
  setUser,
} from "../redux/authSlice";
import jal from "../assets/logo.png";
import logo from "../assets/jal.png";
import zeal from "../assets/zeal_logo_png_set.png"
import siva from "../assets/Sivakumar.jpg"
import { IoCloseCircleOutline } from "react-icons/io5";
import axiosInstance from "../interceptor/axiosInstance";
import { CircularProgress } from "@mui/material";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showProgress, setShowProgress] = useState();
  const [loginErr, setLoginErr] = useState("");
  const [forgetPassword, setForgetPassword] = useState();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowProgress("login");
      const response = await axiosInstance.post(
        "/api/admin/login",
        credentials
      );

      const data = response.data;

      if (data.message !== "Login successfully") {
        setLoginErr(data.message);
      }

      if (data.message === "Body Data is Undefined") {
        setLoginErr("Email or Password is missing!");
      }
      if (data.message === "Login successfully") {
        setLoginErr("");
        const userData = data.Data;
        const accessToken = data.token;
        const refreshToken = data.encryptedToken;
        dispatch(setAuthenticated(true));
        dispatch(setUser(userData));
        dispatch(setAccessToken(accessToken));
        dispatch(setRefreshToken(refreshToken));
        navigate("/district-management");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowProgress("");
    }
  };

  return (
    <section className="flex h-screen font-poppins bg-white">
      <div className="w-[65%] overflow-hidden relative flex items-center justify-end flex-col ">
  

        <img
          style={{width:"auto",height:"400px"}}
          src={jal}
          alt="logo"
        />
<div className="h-[20%] mb-4 w-full relative">
  <div  style={{
      width: "300px",
      position:"absolute",
      bottom: "0",
      right: "0",
    }}>Design and Developed by
  <img
    style={{
      width: "200px",
    
    }}
    src={zeal}
    alt="logo"
  /></div>
</div>

        {forgetPassword === true && (
          <div className="absolute flex items-center justify-center w-full h-[100vh] bg-black bg-opacity-50">
            <div className="bg-white relative rounded-lg text-center font-roboto p-10">
              <div className="absolute top-3 right-3 cursor-pointer p-1 hover:bg-black hover:bg-opacity-20 rounded-full transition">
                <IoCloseCircleOutline
                  onClick={() => setForgetPassword()}
                  className="text-3xl"
                />
              </div>
              <h3 className="font-bold my-4">Oops, forgot your password ?</h3>
              <p className="text-[15px]">
                Don't worry! contact zeal zoft pvt ltd to reset your password.
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="w-[35%] p-16 flex flex-col justify-center">
        {/* <img className="" src={jal} alt="logo" /> */}
        <h2 className="font-semibold text-[#1A1A1A] my-6 text-lg">
          Admin Panel Login
        </h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <label className="text[#333333] text-xs">Login</label>
            <input
              onChange={(e) =>
                setCredentials((prev) => ({
                  ...prev,
                  email: e.target.value.toLowerCase(), // Convert email to lowercase
                }))
              }
              className="bg-softGrey p-2 rounded-md focus:outline-none text-blackSub"
              type="text"
              placeholder="Enter Email"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text[#333333] text-xs">Password</label>
            <div className="relative">
              <input
                onChange={(e) =>
                  setCredentials((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                className="bg-softGrey p-2 w-full rounded-md focus:outline-none text-blackSub"
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
              />
              {showPassword ? (
                <FaEyeSlash
                  onClick={() => setShowPassword(false)}
                  className="text-blackSub text-lg absolute right-3 top-3 cursor-pointer"
                />
              ) : (
                <FaEye
                  onClick={() => setShowPassword(true)}
                  className="text-blackSub text-lg absolute right-3 top-3 cursor-pointer"
                />
              )}
            </div>
          </div>
          <p
            onClick={() => setForgetPassword(true)}
            className="text-buttonPrimary cursor-pointer text-xs text-right"
          >
            Forgot password?
          </p>
          {loginErr && (
            <p className="text-errorRed font-medium text-sm">{loginErr}</p>
          )}
          <button
            className="bg-buttonPrimary text-white w-full mt-4 py-2 rounded-md"
            type="submit"
          >
            {showProgress === "login" ? (
              <CircularProgress color="default" size={17} />
            ) : (
              "Sign In"
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Login;
