// import React, { useContext, useEffect, useState } from "react";
// import logoImg from "../assets/small_logo.png";
// import profilepic from "../assets/profilepic.png";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { DistrictsContext } from "../context/DistrictsContext";
// import { useDispatch } from "react-redux";
// import { clearAssignment } from "../redux/assignmentSlice";
// import store from "../redux/store";
// import { clearAuth } from "../redux/authSlice";

// const navItems = [
//   {
//     name: "District Management",
//     path: "/district-management",
//   },
//   {
//     name: "Employee Management",
//     path: "/employee-management",
//   },
//   {
//     name: "Survey Assignments",
//     path: "/employee-assignments",
//   },
//   {
//     name: "Survey Reports & Analytics",
//     path: "/reports-and-analytics",
//   },
//   {
//     name: "Employee Analytics",
//     path: "/employee-analytics",
//   },
// ];

// const Navbar = () => {
//   const { currTab, setCurrTab } = useContext(DistrictsContext);
//   const [logoutModal, setLogoutModal] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const handleTab = (tab) => {
//     tab === "Employee Assignments" && dispatch(clearAssignment());
//   };
//   const { auth } = store.getState();

//   const userName = auth.user?.Name || "User"; // Default to "User" if no name
//   const firstLetter = userName.charAt(0).toUpperCase(); // First letter of the user's name

//   const handleLogout = () => {
//     dispatch(clearAuth());
//     navigate("/");
//   };

//   useEffect(() => {
//     if (
//       location.pathname.includes("district-management") ||
//       location.pathname.includes("selected-district") ||
//       location.pathname.includes("selected-well")
//     ) {
//       setCurrTab("District Management");
//     } else if (location.pathname.includes("employee-management")) {
//       setCurrTab("Employee Management");
//     } else if (
//       location.pathname.includes("employee-assignments") ||
//       location.pathname.includes("assign-work")
//     ) {
//       setCurrTab("Survey Assignments");
//     } else if (
//       location.pathname.includes("reports-and-analytics") ||
//       location.pathname.includes("survey-review") ||
//       location.pathname.includes("missed-survey")
//     ) {
//       setCurrTab("Survey Reports & Analytics");
//     } else if (location.pathname.includes("employee-analytics")) {
//       setCurrTab("Employee Analytics");
//     }
//     // Add more conditions as necessary for other tabs/pages
//   }, [location.pathname]);

//   return (
//     <>
//       {logoutModal ? (
//         <div className="fixed inset-0 z-20  flex items-center justify-center bg-black bg-opacity-50 font-roboto">
//           <div className="bg-white w-[30%] h-[30vh] rounded-xl flex flex-col gap-5 items-center justify-center">
//             <h2 className="text-blackSubDark text-lg font-medium">
//               Logout Confirmation
//             </h2>
//             <p>Do you want to logout?</p>
//             <div className="flex items-center justify-center gap-3">
//               <button
//                 onClick={() => setLogoutModal(false)}
//                 className="bg-buttonPrimary text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm"
//               >
//                 Close
//               </button>
//               <button
//                 onClick={handleLogout}
//                 className={`bg-textGrey text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm`}
//               >
//                 Logout
//               </button>
//             </div>
//           </div>
//         </div>
//       ) : null}
//       <section className="bg-white py-2 px-6 sticky top-0 z-10 flex items-center justify-between">
//         <img src={logoImg} alt="logo" className="w-48" />
//         <ul className="font-poppins font-bold text-blackSubDark text-[13px] 2xl:text-sm flex items-center gap-6 ">
//           {navItems.map((item, index) => (
//             <Link
//               onClick={() => {
//                 setCurrTab(item.name);
//                 handleTab(item.name);
//               }}
//               className="cursor-pointer transition-all group"
//               to={item.path}
//               key={index}
//             >
//               <li className="relative">
//                 {item?.name}
//                 <div
//                   className={`h-[2px] bg-skyBlue absolute left-0 -bottom-1 transition-all duration-300 ${
//                     currTab === item.name ? "w-full" : "w-0 group-hover:w-full"
//                   }`}
//                 ></div>
//               </li>
//             </Link>
//           ))}
//         </ul>
//         <div className="flex items-center gap-2">
//           <div className="text-right font-inter font-semibold">
//             <p className="text-sm text-blackSub">{userName}</p>
//             <p
//               onClick={() => setLogoutModal(true)}
//               className="text-xs text-skyBlue cursor-pointer"
//             >
//               Logout
//             </p>
//           </div>
//           <div className="w-10 h-10 bg-gray-300 text-white flex items-center justify-center rounded-full font-bold">
//             {firstLetter}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Navbar;

import React, { useContext, useEffect, useState } from "react";
import logoImg from "../assets/small_logo.png";
import profilepic from "../assets/profilepic.png";
import siva from "../assets/Sivakumar.jpg"; // Importing the Siva image
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DistrictsContext } from "../context/DistrictsContext";
import { useDispatch } from "react-redux";
import { clearAssignment } from "../redux/assignmentSlice";
import store from "../redux/store";
import { clearAuth } from "../redux/authSlice";

const navItems = [
  {
    name: "District Management",
    path: "/district-management",
  },
  {
    name: "Employee Management",
    path: "/employee-management",
  },
  {
    name: "Survey Assignments",
    path: "/employee-assignments",
  },
  {
    name: "Survey Reports & Analytics",
    path: "/reports-and-analytics",
  },
  {
    name: "Employee Analytics",
    path: "/employee-analytics",
  },
];

const Navbar = () => {
  const { currTab, setCurrTab } = useContext(DistrictsContext);
  const [logoutModal, setLogoutModal] = useState(false);
  const [storyModal, setStoryModal] = useState(false); // New state for the story modal
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleTab = (tab) => {
    tab === "Employee Assignments" && dispatch(clearAssignment());
  };
  const { auth } = store.getState();

  const userName = auth.user?.Name || "User"; // Default to "User" if no name
  const firstLetter = userName.charAt(0).toUpperCase(); // First letter of the user's name

  const handleLogout = () => {
    dispatch(clearAuth());
    navigate("/");
  };

  useEffect(() => {
    if (
      location.pathname.includes("district-management") ||
      location.pathname.includes("selected-district") ||
      location.pathname.includes("selected-well")
    ) {
      setCurrTab("District Management");
    } else if (location.pathname.includes("employee-management")) {
      setCurrTab("Employee Management");
    } else if (
      location.pathname.includes("employee-assignments") ||
      location.pathname.includes("assign-work")
    ) {
      setCurrTab("Survey Assignments");
    } else if (
      location.pathname.includes("reports-and-analytics") ||
      location.pathname.includes("survey-review") ||
      location.pathname.includes("missed-survey")
    ) {
      setCurrTab("Survey Reports & Analytics");
    } else if (location.pathname.includes("employee-analytics")) {
      setCurrTab("Employee Analytics");
    }
  }, [location.pathname]);

  return (
    <>
      {logoutModal && (
        <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50 font-roboto">
          <div className="bg-white w-[30%] h-[30vh] rounded-xl flex flex-col gap-5 items-center justify-center">
            <h2 className="text-blackSubDark text-lg font-medium">
              Logout Confirmation
            </h2>
            <p>Do you want to logout?</p>
            <div className="flex items-center justify-center gap-3">
              <button
                onClick={() => setLogoutModal(false)}
                className="bg-buttonPrimary text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm"
              >
                Close
              </button>
              <button
                onClick={handleLogout}
                className="bg-textGrey text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Story Modal */}
      {storyModal && (
        <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50 font-roboto">
          <div className="bg-white w-[60%] h-[80vh] overflow-y-auto rounded-xl p-6">
            <h2 className="text-blackSubDark text-xl font-bold mb-4">About the Project</h2>

            {/* Image of Shri M. SivaKumar */}
         

            <p className="text-blackSubDark mb-4 flex items-start">
  {/* Text content starts above the image */}
  <img
    src={siva}
    alt="Shri M. SivaKumar"
    className="w-48 h-48 object-cover rounded-full mr-4"
  />
  <span className="w-full text-justify">
  BhuNeer SApp  is an advanced solution designed to enhance the efficiency and accuracy of well management operations. Conceptualized and initiated by Shri. M. SivaKumar, the platform offers a structured, intuitive interface for seamless tracking, assignment, and surveying of wells. It aims to improve coordination between field personnel and administrators, ensuring the accurate collection of data and real-time management of well conditions.
  <p className="text-blackSubDark mb-4 mt-2">
  Developed by Zeal Zoft Pvt. Ltd., a leading provider of innovative software solutions, the application leverages cutting-edge technology, including real-time GPS location tracking and proximity-based survey validation, to streamline well management processes. With features like an interactive map, survey report generation, and robust administrative tools, this application sets a new standard for efficient and accurate well monitoring and survey management            </p></span>

  {/* Image on the left */}
 
</p>
    

            <div className="text-blackSubDark mb-4">
  <h2 className="text-lg font-semibold mb-4">Key Features of BhuNeer SApp : </h2>
  
  <ul className="list-disc pl-5">
    <li>
      <strong>Comprehensive Well Listing and Map Integration</strong>
      <ul className="list-inside list-decimal ml-4">
        <li>Detailed list of assigned wells categorized by district for quick access to relevant data.</li>
        <li>Interactive Map View to visually locate assigned wells with precision.</li>
        <li>Integrated navigation support for seamless on-site accessibility for field personnel.</li>
      </ul>
    </li>

    <li>
      <strong>Proximity-Based Survey Submission</strong>
      <ul className="list-inside list-decimal ml-4">
        <li>Proximity-based validation mechanism ensures surveys are only submitted when within a 100-meter radius of the assigned well.</li>
        <li>Includes image uploads to document well conditions accurately, improving data reliability and transparency.</li>
      </ul>
    </li>

    <li>
      <strong>New Well Registration with Real-Time GPS Coordination</strong>
      <ul className="list-inside list-decimal ml-4">
        <li>Register new wells using real-time GPS location tracking.</li>
        <li>Ensures accurate geotagging of newly added wells to prevent data discrepancies and improve well documentation.</li>
      </ul>
    </li>

    <li>
      <strong>Robust Administrative Panel for Centralized Management</strong>
      <ul className="list-inside list-decimal ml-4">
        <li>Full control for administrators over system operations.</li>
        <li>Employee Management: Add, modify, and manage employee details.</li>
        <li>Well Assignment & Management: Assign wells to employees, modify entries, and add new wells.</li>
        <li>Survey Oversight: Monitor survey progress, approve submitted surveys, and manage well-related data.</li>
        <li>Access to comprehensive survey history, including past surveys, employee-specific logs, and pending/completed surveys.</li>
      </ul>
    </li>

    <li>
      <strong>Survey Reports and Data Export Functionality</strong>
      <ul className="list-inside list-decimal ml-4">
        <li>Allows users to download reports with:</li>
        <ul className="ml-6 list-disc">
          <li>Historical survey data</li>
          <li>Completed survey records</li>
          <li>Pending survey details</li>
        </ul>
        <li>Ensures efficient record-keeping and supports informed decision-making and resource allocation.</li>
      </ul>
    </li>
  </ul>
</div>

            <div className="flex justify-center gap-3 mt-6">
              <button
                onClick={() => setStoryModal(false)}
                className="bg-buttonPrimary text-white px-8 py-[6px] font-poppins rounded-[5px] text-sm"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <section className="bg-white py-2 px-6 sticky top-0 z-10 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <img src={logoImg} alt="logo" className="w-48" />
          {/* Story button */}
          <button
            onClick={() => setStoryModal(true)}
            className="bg-skyBlue text-white px-6 py-2 rounded-full text-sm font-semibold"
          >
            Story
          </button>
        </div>

        <ul className="font-poppins font-bold text-blackSubDark text-[13px] 2xl:text-sm flex items-center gap-6 ">
          {navItems.map((item, index) => (
            <Link
              onClick={() => {
                setCurrTab(item.name);
                handleTab(item.name);
              }}
              className="cursor-pointer transition-all group"
              to={item.path}
              key={index}
            >
              <li className="relative">
                {item?.name}
                <div
                  className={`h-[2px] bg-skyBlue absolute left-0 -bottom-1 transition-all duration-300 ${
                    currTab === item.name ? "w-full" : "w-0 group-hover:w-full"
                  }`}
                ></div>
              </li>
            </Link>
          ))}
        </ul>

        <div className="flex items-center gap-2">
          <div className="text-right font-inter font-semibold">
            <p className="text-sm text-blackSub">{userName}</p>
            <p
              onClick={() => setLogoutModal(true)}
              className="text-xs text-skyBlue cursor-pointer"
            >
              Logout
            </p>
          </div>
          <div className="w-10 h-10 bg-gray-300 text-white flex items-center justify-center rounded-full font-bold">
            {firstLetter}
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
