import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DistrictsContext } from "../context/DistrictsContext";
import WellsListCard from "../components/WellsListCard";
import WellDetailsCard from "../components/WellDetailsCard";
import LocationPicker from "../components/LocationPicker";
import Back from "../components/Back";
import SelectedDistrictTable from "../components/SelectedDistricTable";
import EditFormModal from "../components/EditFormModal";
import UploadWell from "../components/UploadWell";
import WellType_btn from "../components/WellType_btn";
import Download_Type from "../components/Download_Type";
import UpdateSuccessModal from "../components/UpdateSuccessModal";
import { IoSearchOutline, IoArrowBack } from "react-icons/io5"; // Import IoArrowBack
import axiosInstance from "../interceptor/axiosInstance";
import { CircularProgress } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa6";

const SelectedDistrict = () => {
  const [showWellDetails, setShowWellDetails] = useState([]);
  const [showProgress, setShowProgress] = useState();
  const [selectedWell, setSelectedWell] = useState({});
  const [selectedType, setselectedType] = useState("Dug well");
  const { district_id } = useParams();
  const { districts, editFormModal, updatedModal, getDistricts } =
    useContext(DistrictsContext);
  const [searchTerms, setSearchTerms] = useState("");
  const [show, setshow] = useState(false);
  const navigate = useNavigate(); // Initialize navigate function

  const selectedDistrict = districts?.find(
    (item) => item.district_id == district_id
  );

  const filterData = showWellDetails?.filter((well) => {
    const matchSearchTerms =
      well?.ex_well_id?.toString().includes(searchTerms) ||
      well?.village?.toLowerCase()?.includes(searchTerms?.toLowerCase());
    const matchType = selectedType
      ? well?.well_type.toLowerCase().includes(selectedType.toLowerCase())
      : true;

    return matchSearchTerms && matchType;
  });

  const handleNewDetails = (well) => {
    setSelectedWell(well);
    setshow(true);
  };

  const fetchSelectedData = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `/api/district/well/detail/${district_id}`
      );

      const wellList = response?.data?.Data;
      setShowWellDetails(wellList);
    } catch (error) {
      console.error("error in selectedData", error);
    } finally {
      setShowProgress(false);
      setshow(false);
      setSelectedWell({});
    }
  };

  useEffect(() => {
    setshow(false);
    setSelectedWell();
  }, [selectedType]);

  useEffect(() => {
    fetchSelectedData();
  }, [district_id]);

  useEffect(() => {
    getDistricts();
  }, []);
  return (
    <>
      {editFormModal && <EditFormModal district_id={district_id} />}
      {updatedModal === true && (
        <UpdateSuccessModal
          onSuccessFunction={fetchSelectedData}
          message="Well details updated successfully!"
        />
      )}
      <div className="w-[90%] mx-auto">
        <div className="font-poppins">
          <div className="my-2 flex items-center justify-between">
            <h2 className="font-bold relative flex items-center text-blackSubDark text-lg">
              <div
                onClick={() => navigate("/district-management")}
                className="cursor-pointer w-fit px-2 py-1 rounded-full hover:bg-black hover:bg-opacity-10"
              >
                <FaArrowLeft className="inline-block text-skyBlue" />
              </div>
              Selected District Well Overview
            </h2>

            {/* <LocationPicker /> */}
          </div>
        </div>
        <div className="flex items-center gap-10">
          {selectedDistrict && (
            <SelectedDistrictTable selectedDistrict={selectedDistrict} />
          )}
          <UploadWell
            reload={fetchSelectedData}
            selectedDistrict={selectedDistrict?.district_name}
          />
        </div>

        <div className="flex items-center my-4 gap-4">
          <div className="w-[35%]">
            <WellType_btn
              selectedType={selectedType}
              setselectedType={setselectedType}
            />
          </div>
          <div className=" bg-white flex items-center w-[30%] py-2 border border-gray-300 rounded-lg px-4 justify-between font-roboto shadow-md">
            <IoSearchOutline className="text-textGrey mr-2" />{" "}
            <input
              type="text"
              placeholder="Search by well id, village"
              className="focus:outline-none w-full text-textGrey text-[14px]"
              onChange={(e) => setSearchTerms(e.target.value)}
            />
          </div>
          <div className="w-[35%]">
            {" "}
            <Download_Type type={selectedType} data={filterData} />
          </div>
        </div>

        <div className="flex items-start justify-between gap-6">
          <div className="w-[55%] flex flex-col gap-2 max-h-[83vh] overflow-y-auto custom-scrollbar">
            {showProgress ? (
              <div className="w-full mx-auto h-[30vh] flex items-center justify-center">
                <CircularProgress size={24} />
              </div>
            ) : (
              <>
                <p className="font-poppins font-medium">
                  {filterData?.length} Wells
                </p>
                {filterData.map((item, i) => (
                  <WellsListCard
                    selectedWell={selectedWell}
                    setShowWellDetails={setShowWellDetails}
                    setSelectedWell={handleNewDetails}
                    key={i}
                    item={item}
                    buttons="true"
                    checkbox="false"
                    cardNumber={i + 1} // Pass the card number (index + 1)
                  />
                ))}
              </>
            )}
          </div>
          <div className={`transition-opacity w-[45%] rounded-lg bg-white p-4`}>
            {!show ? (
              <div className="p-4">
                <p className="font-semibold text-center py-24 text-lg">
                  Select a well to view details.
                </p>
              </div>
            ) : (
              <WellDetailsCard details={selectedWell} type="details" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedDistrict;
