import React, { useEffect, useState } from "react";
import Back from "../components/Back";
import SurveyListCard from "../components/SurveyListCard";
import MissedSurveyDetailsCard from "../components/MissedSurveyDetailsCard";
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "../interceptor/axiosInstance";
import { CircularProgress } from "@mui/material";
import NewWellForm from "../components/NewWellForm";

const MissedSurvey = () => {
  const [showProgress, setShowProgress] = useState(false);
  const [missed, setMissed] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null); // state for selected survey
  const { district_id, emp_id, emp_name } = useParams();
  const location = useLocation();
  const { emp_assigned_date } = location.state;

  const MissedSurvey = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `/api/employee/get/assigned/wellDetails/${emp_id}/${district_id}/${emp_assigned_date}`
      );

      const result = response?.data?.data;

      setMissed(result);
    } catch (error) {
      console.error("error:", error);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    MissedSurvey();
  }, []);

  const handleSelectedSurvey = (survey) => {
    setSelectedSurvey(survey);
  };

  return (
    <div>
      <div className="w-[90%] mx-auto">
        <div className="my-2 font-poppins">
          <h2 className="font-bold relative flex items-center capitalize text-blackSubDark text-lg">
            <span className="">
              <Back />
            </span>{" "}
            {emp_name} Missed Survey
          </h2>
          {missed && missed.length >= 0 ? (
            <p className="font-poppins font-medium my-2">
              {missed.length} missed surveys
            </p>
          ) : null}
        </div>

        <div className="w-[100%] flex gap-10">
          <div className="w-[55%] flex flex-col gap-4 h-[70vh] overflow-y-auto custom-scrollbar rounded-lg">
            {showProgress ? (
              <div className="w-full mx-auto h-[90vh] flex items-center justify-center">
                <CircularProgress size={24} />
              </div>
            ) : (
              <>
                {missed?.map((survey, i) => (
                  <SurveyListCard
                    key={i}
                    item={survey}
                    cardNumber={i + 1}
                    selectedWell={selectedSurvey}
                    card={"missed"}
                    handleSelectSurvey={handleSelectedSurvey}
                  />
                ))}
              </>
            )}
          </div>

          <div className="w-[45%] p-6 h-[70vh] overflow-y-auto custom-scrollbar bg-white rounded-lg">
            {selectedSurvey ? (
              <MissedSurveyDetailsCard
                fetchSurvey={MissedSurvey}
                survey={selectedSurvey}
                setSelectedSurvey={setSelectedSurvey}
                empId={emp_id}
              />
            ) : (
              // <NewWellForm />
              <p className="font-semibold font-poppins w-full h-full flex items-center justify-center text-buttonSecondary text-center py-24 text-lg">
                Select a well to update details.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissedSurvey;
