import { createContext, useState } from "react";

const EmpAssignmentContext = createContext();

const EmpAssignmentProvider = ({ children }) => {
  const [showAssignmentsForEmp, setShowAssignmentsForEmp] = useState();

  return (
    <EmpAssignmentContext.Provider
      value={{ showAssignmentsForEmp, setShowAssignmentsForEmp }}
    >
      {children}
    </EmpAssignmentContext.Provider>
  );
};

export { EmpAssignmentContext, EmpAssignmentProvider };
