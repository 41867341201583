import React from "react";

const DownloadOrPreviewReport = ({
  title,
  dugWellFunc,
  piezoFunc,
  AllType,
  img,
}) => {
  return (
    <div className="w-[45%] p-2 bg-[#3EB4E9] rounded-2xl font-poppins">
      <h1 className="font-medium mb-2 text-center text-sm">{title}</h1>
      <div className="flex justify-center items-center gap-6 mt-2">
        <div
          onClick={dugWellFunc}
          className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-[10px]"
        >
          <img className="w-5" src={img} alt="icon" />
          <span>Dug Well</span>
        </div>
        <div
          onClick={piezoFunc}
          className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-[10px]"
        >
          <img className="w-5" src={img} alt="icon" />
          <span>Piezometer</span>
        </div>
        <div
          onClick={AllType}
          className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-[10px]"
        >
          <img className="w-5" src={img} alt="icon" />
          <span>All Well Type</span>
        </div>
      </div>
    </div>
  );
};

export default DownloadOrPreviewReport;
