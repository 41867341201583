import React, { useContext, useRef, useState } from "react";
import * as xlsx from "xlsx"; // Make sure to install the xlsx library
import cloudComputing from "../assets/upload_btn.png";
import axiosInstance from "../interceptor/axiosInstance";
import { DistrictsContext } from "../context/DistrictsContext";

const toSnakeCase = (str) => {
  return str
    .replace(/\s+/g, "_") // Replace spaces with underscores
    .replace(/([a-z])([A-Z])/g, "$1_$2") // Add underscore between lower and uppercase letters
    .toLowerCase(); // Convert to lowercase
};

function UploadDugWellSheet({ setOpenModal }) {
  const [msg, setMsg] = useState("");
  const [duplicateIdsFound, setDuplicateIdsFound] = useState();
  const { setUpdatedModal } = useContext(DistrictsContext);

  const requiredKeys = [
    "state",
    "district",
    "firka",
    "village",
    "ex_well_id",
    "well_type",
    "well_status",
    "location_detail",
    "latitude",
    "longitude",
    "dms_latitude",
    "dms_longitude",
    "depth",
    "measuring_point",
    "diameter",
    "curbing_depth",
    "use_of_well",
  ];

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  // const readUploadFile = async (e) => {
  //   e.preventDefault();
  //   setDuplicateIdsFound();
  //   if (e.target.files) {
  //     const file = e.target.files[0];
  //     if (!file) {
  //       setMsg("Please select a valid file.");
  //       return;
  //     }
  //     if (
  //       !file.type.includes("excel") &&
  //       !file.name.endsWith(".xls") &&
  //       !file.name.endsWith(".xlsx")
  //     ) {
  //       alert("Please upload a valid Excel file (.xls or .xlsx)");
  //       fileInputRef.current.value = "";
  //       return;
  //     }
  //     setMsg("Your file is being processed...");
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const data = new Uint8Array(e.target.result); // e.target.result;
  //       const workbook = xlsx.read(data, { type: "array" });
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];
  //       const json = xlsx.utils.sheet_to_json(worksheet, { header: 1 }); // Get rows as arrays
  //       const headers = json[0].map((header) =>
  //         toSnakeCase(header.toString().trim())
  //       ); // First row as headers

  //       // Check for missing required keys
  //       const missingKeys = requiredKeys.filter(
  //         (key) => !headers.includes(key)
  //       );
  //       if (missingKeys.length > 0) {
  //         setMsg(
  //           `The following required data are missing: ${missingKeys.join(", ")}`
  //         );
  //         fileInputRef.current.value = "";
  //         return; // Prevent further processing
  //       }

  //       // Check for unwanted keys
  //       const unwantedKeys = headers.filter(
  //         (header) => !requiredKeys.includes(header)
  //       );
  //       if (unwantedKeys.length > 0) {
  //         setMsg(
  //           `The following fields are unwanted and should be removed: ${unwantedKeys.join(
  //             ", "
  //           )}`
  //         );
  //         fileInputRef.current.value = "";
  //         return;
  //       }
  //       const result = json
  //         .slice(1)
  //         .filter((row) => {
  //           return row.some(
  //             (cell) => cell !== undefined && cell !== null && cell !== ""
  //           );
  //         })
  //         .map((row) => {
  //           const obj = {};
  //           headers.forEach((header, index) => {
  //             const trimmedValue = row[index]
  //               ? row[index].toString().trim()
  //               : null;
  //             obj[header] = trimmedValue === undefined ? null : trimmedValue; // Set to null if value is empty
  //           });
  //           return obj;
  //         });

  //       const invalidWellTypes = result?.filter(
  //         (row) => row.well_type && row.well_type !== "Dug Well"
  //       );
  //       if (invalidWellTypes.length > 0) {
  //         setMsg("All well_type values must be 'Dug Well'.");
  //         fileInputRef.current.value = "";
  //         return;
  //       }

  //       const postData = {
  //         Data: result,
  //       };
  //       try {
  //         setMsg("Your file is being uploaded...");
  //         const response = await axiosInstance.post(
  //           "/api/well/existing/well/add",
  //           postData
  //         );
  //         setMsg("Worksheet uploaded successfully!");
  //         setOpenModal("");
  //         setUpdatedModal(true);
  //       } catch (error) {
  //         if (error.response) {
  //           const data = error.response.data.duplicate_ex_well_ids;
  //           const errorMessage =
  //             error.response.data.message || "An unexpected error occurred.";
  //           setMsg(errorMessage);
  //           setDuplicateIdsFound(data);
  //         } else if (error.request) {
  //           setMsg(
  //             "No response from server or Check your internet connection."
  //           );
  //         } else {
  //           setMsg("Error in setting up the request.");
  //         }
  //         fileInputRef.current.value = "";
  //       }
  //     };
  //     reader.readAsArrayBuffer(e.target.files[0]);
  //   }
  // };

  const readUploadFile = async (e) => {
    e.preventDefault();
    setDuplicateIdsFound();
    if (e.target.files) {
      const file = e.target.files[0];
      console.log("File selected:", file); // Log the selected file
  
      if (!file) {
        setMsg("Please select a valid file.");
        return;
      }
      if (
        !file.type.includes("excel") &&
        !file.name.endsWith(".xls") &&
        !file.name.endsWith(".xlsx")
      ) {
        alert("Please upload a valid Excel file (.xls or .xlsx)");
        fileInputRef.current.value = "";
        return;
      }
  
      setMsg("Your file is being processed...");
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result); // Read file data
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { header: 1 }); // Convert to JSON array
  
        const headers = json[0].map((header) =>
          toSnakeCase(header.toString().trim())
        ); // Transform headers to snake_case
        
        console.log("Headers:", headers); // Log the headers array
  
        // Check for missing required keys
        const missingKeys = requiredKeys.filter(
          (key) => !headers.includes(key)
        );
        console.log("Missing Keys:", missingKeys); // Log missing keys
  
        if (missingKeys.length > 0) {
          setMsg(
            `The following required data are missing: ${missingKeys.join(", ")}`
          );
          fileInputRef.current.value = "";
          return; // Prevent further processing
        }
  
        // Check for unwanted keys
        const unwantedKeys = headers.filter(
          (header) => !requiredKeys.includes(header)
        );
        console.log("Unwanted Keys:", unwantedKeys); // Log unwanted keys
  
        if (unwantedKeys.length > 0) {
          setMsg(
            `The following fields are unwanted and should be removed: ${unwantedKeys.join(
              ", "
            )}`
          );
          fileInputRef.current.value = "";
          return;
        }
  
        const result = json
          .slice(1)
          .filter((row) => {
            return row.some(
              (cell) => cell !== undefined && cell !== null && cell !== ""
            );
          })
          .map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              const trimmedValue = row[index]
                ? row[index].toString().trim()
                : null;
              obj[header] = trimmedValue === undefined ? null : trimmedValue; // Set to null if value is empty
            });
            return obj;
          });
  
        console.log("Formatted Result Data:", result); // Log the result data to check formatting
  
        const invalidWellTypes = result?.filter(
          (row) => row.well_type && row.well_type !== "Dug Well"
        );
        if (invalidWellTypes.length > 0) {
          setMsg("All well_type values must be 'Dug Well'.");
          fileInputRef.current.value = "";
          return;
        }
  
        const postData = {
          Data: result,
        };
        console.log("Post Data Sent to API:", postData); // Log data sent to API
  
        try {
          setMsg("Your file is being uploaded...");
          const response = await axiosInstance.post(
            "/api/well/existing/well/add",
            postData
          );
          console.log("API Response:", response.data); // Log the API response
          setMsg("Worksheet uploaded successfully!");
          setOpenModal("");
          setUpdatedModal(true);
        } catch (error) {
          console.log("Error:", error); // Log the error if the request fails
  
          if (error.response) {
            const data = error.response.data.duplicate_ex_well_ids;
            const errorMessage =
              error.response.data.message || "An unexpected error occurred.";
            setMsg(errorMessage);
            setDuplicateIdsFound(data);
          } else if (error.request) {
            setMsg("No response from server or Check your internet connection.");
          } else {
            setMsg("Error in setting up the request.");
          }
          fileInputRef.current.value = "";
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  

  return (
    <div className="mb-4">
      <div onClick={handleUploadClick} className="flex justify-center">
        <button className="px-6 text-black flex items-center  text-md py-2 rounded-lg font-semibold transition bg-[#009DE3] duration-300 ease-in-out transform ">
          <img src={cloudComputing} alt="" className="w-6 mr-2" />
          Choose File
        </button>
      </div>

      <input
        ref={fileInputRef}
        className="text-blackSubDark font-roboto text-sm hidden"
        type="file"
        name="upload"
        id="upload"
        accept=".xls,.xlsx"
        onChange={readUploadFile}
      />

      {msg ? (
        <main>
          <p
            className={`font-roboto text-center p-3 ${
              msg.includes("successfully") ||
              msg.includes("being uploaded") ||
              msg.includes("being processed")
                ? "text-successGreen"
                : "text-dullRed"
            } font-semibold `}
          >
            {msg}
          </p>
        </main>
      ) : null}
      {duplicateIdsFound && (
        <p
          className={`font-roboto text-center p-3 text-dullRed font-semibold `}
        >
          (
          {duplicateIdsFound.map((dup, i) => (
            <span key={i}>
              {dup}
              {duplicateIdsFound.length - 1 === i ? "" : ", "}
            </span>
          ))}
          )
        </p>
      )}
    </div>
  );
}

export default UploadDugWellSheet;
