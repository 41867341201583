import { combineReducers, configureStore } from "@reduxjs/toolkit";
import assignmentReducer from "./assignmentSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userSliceReducer from "./usersSlice";
import authReducer from "./authSlice";
import stateReducer from "./stateSlice";
import sessionReducer from "./sessionSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["assignment", "users", "auth", "state", "session"],
};

const rootReducer = combineReducers({
  assignment: assignmentReducer,
  users: userSliceReducer,
  auth: authReducer,
  state: stateReducer,
  session: sessionReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;
