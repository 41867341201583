import React, { useContext, useEffect, useRef, useState } from "react";
import FilterComponents from "../components/FilterComponents";
import LocationPicker from "../components/LocationPicker";
import TableData from "../components/TableData";
import { EmpAnalyticsContext } from "../context/EmpAnalyticsContext";
import axiosInstace from "../interceptor/axiosInstance";
import { MdFilterAltOff } from "react-icons/md";

const EmployeeAnalytics = () => {
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [initialLoading, setInitialLoading] = useState();
  const [loading, setLoading] = useState();
  const [filtersApplied, setFiltersApplied] = useState(false);

  const { selectedFilters, setSelectedFilters } =
    useContext(EmpAnalyticsContext);

  const observerRef = useRef(null);
  const lastElementRef = useRef(null);

  const doesFiltersApplied = Object.values(selectedFilters).some(
    (value) => value != null || value != undefined
  );

  const handleApplyFilter = () => {
    if (!doesFiltersApplied) return;
    setFiltersApplied(true);
    setHasMore(true);
    setIndex(1);
    setData([]);
  };

  const handleClearFilter = () => {
    if (!doesFiltersApplied) return;
    setSelectedFilters((prev) => ({
      ...prev,
      district: null,
      employee: null,
      session: null,
    }));
    if (filtersApplied) {
      setFiltersApplied(false);
      setHasMore(true);
      setIndex(1);
      setData([]);
    }
  };

  const fetchData = async () => {
    if (!hasMore) return;
    index === 1 ? setInitialLoading(true) : setLoading(true);
    const apiUrl = filtersApplied
      ? `/api/employee/surveys/filter/data/get?page_index=${index}&limit=5&district=${selectedFilters?.district}&emp_id=${selectedFilters?.employee}&start_date=${selectedFilters?.session?.session_start_date}&end_date=${selectedFilters?.session?.session_end_date}`
      : `/api/employee/surveys/all/data/get?page_index=${index}&limit=5`;
    try {
      const response = await axiosInstace.get(apiUrl);
      const totalCount = response?.data?.TotalDataCount || 0;
      const newData = response?.data?.Data || [];

      setData((prev) => [...prev, ...newData]);
      setTotalDataCount(totalCount);

      if (newData?.length < 5 || data?.length + newData?.length >= totalCount) {
        setHasMore(false);
      }

      index === 1 ? setInitialLoading(false) : setLoading(false);
    } catch (error) {
      console.log(error);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setIndex((prev) => prev + 1);
        }
      },
      { rootMargin: "100px" }
    );

    if (lastElementRef.current) {
      observerRef.current.observe(lastElementRef.current);
    }

    return () => observerRef.current.disconnect();
  }, [hasMore, data]);

  useEffect(() => {
    fetchData();
  }, [index, hasMore]);

  return (
    <div className="w-[90%] mx-auto">
      <div className="font-poppins">
        <div className="my-2 flex items-center justify-between">
          <h2 className="font-bold text-blackSubDark text-lg">
            Employee Analytics
          </h2>
          <LocationPicker />
        </div>
      </div>
      <div className="bg-skyBlue p-4 rounded-t-lg mt-4 font-inter flex items-center justify-between">
        <div className="flex items-center gap-4">
          <FilterComponents />
          <button
            onClick={handleApplyFilter}
            className={`${
              doesFiltersApplied
                ? "bg-buttonPrimary text-white cursor-pointer"
                : "bg-white text-textGrey cursor-auto"
            }  focus:outline-none rounded-md px-4 py-2 text-xs font-medium transition-colors duration-300`}
          >
            Apply Filter
          </button>
          <button
            onClick={handleClearFilter}
            className={`${
              doesFiltersApplied
                ? "bg-buttonPrimary text-white cursor-pointer"
                : "bg-white text-textGrey cursor-auto"
            }  focus:outline-none rounded-md px-4 py-2 font-medium transition-colors duration-300`}
          >
            <MdFilterAltOff />
          </button>
        </div>
        <div className="text-white font-semibold">
          Total - {totalDataCount || "0"}
        </div>
      </div>
      <div className="overflow-auto h-[70vh] custom-scrollbar w-[100%] mx-auto mb-4  bg-[#FFFFFF] shadow-sm rounded-b-lg font-inter">
        <TableData
          lastElementObserver={lastElementRef}
          data={data}
          progress={initialLoading}
          handleRowClick=""
          intermediateLoading={loading}
        />
      </div>
    </div>
  );
};

export default EmployeeAnalytics;
