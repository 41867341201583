import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: null,
  sessionStartDate: null,
  sessionEndDate: null,
  selectedSession: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    startSession: (state, action) => {
      state.sessionStartDate = action.payload;
    },
    sessionStatus: (state, action) => {
      state.isActive = action.payload;
    },
    setSelectedSession: (state, action) => {
      state.selectedSession = action.payload;
    },
    clearSession: (state) => {
      state.isActive = false;
      state.sessionStartDate = null;
      state.sessionEndDate = null;
      state.selectedSession = null;
    },
  },
});

export const { startSession, sessionStatus, setSelectedSession, clearSession } =
  sessionSlice.actions;
export default sessionSlice.reducer;
