import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import AssigmentCard from "../components/AssigmentCard";
import EmpListToAssign from "../components/EmpListToAssign";
import LocationPicker from "../components/LocationPicker";
import SurveyConfirmationModal from "../components/SurveyConfirmationModal";
import { EmpAssignmentContext } from "../context/EmpAssignmentContext";
import axiosInstance from "../interceptor/axiosInstance";
import {
  sessionStatus,
  setSelectedSession,
  startSession,
} from "../redux/sessionSlice";
import { formatDateToShow } from "../utils/formatDate";
const EmployeeAssignments = () => {
  const [searchTerms, setsearchTerms] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [calendarDropDown, setCalendarDropDown] = useState();
  const [showSessionStartModal, setShowSessionStartModal] = useState();
  const { showAssignmentsForEmp } = useContext(EmpAssignmentContext);
  const [assignedDistricts, setAssignedDistricts] = useState();
  const [showProgress, setShowProgress] = useState();
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const today = dayjs();

  const checkSessionStatus = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/employee/get/session/status`
      );
      const data = response?.data;
      const isActive = data?.message?.includes("Session is Active");
      const startDate = data?.session_start_date;
      dispatch(sessionStatus(isActive));
      startDate && dispatch(startSession(startDate));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSessionStart = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.post(
        `/api/employee/session/add/start/date`,
        {
          start_date: selectedDate,
        }
      );
      const data = response?.data;
      dispatch(startSession(data?.start_date));
      dispatch(setSelectedSession(data?.start_date));
      setShowSessionStartModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setShowProgress();
    }
  };

  // const handleSelectDate = () => {
  //   setSelectedDate(formatDate(date));
  //   setCalendarDropDown("");
  // };

  const getEmpAssignment = async (emp) => {
    try {
      setShowProgress("assignment-card");
      const response = await axiosInstance.get(
        `/api/employee/work/assigned/status/${emp}`
      );

      const data = response.data?.Data;
      setAssignedDistricts(data);
      setShowProgress();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCloseSessionStartModal = () => {
    setShowSessionStartModal("");
  };

  useEffect(() => {
    checkSessionStatus();
    setSelectedDate(new Date());
  }, [session]);

  return (
    <>
      <div className="w-[90%] mx-auto">
        <div className="font-poppins">
          <div className="my-2 flex items-start justify-between">
            <div className="w-[30%]">
              <h2 className="font-bold text-blackSubDark text-lg">
                Survey Assignments
              </h2>
              <h3 className="text-blackSub text-[13px]">
                Assign and manage survey tasks across employees.
              </h3>
              {session?.isActive ? (
                <div className="mt-10 mb-6 bg-white flex items-center w-[400px] h-[40px] border border-gray-300 rounded-lg px-4 justify-start gap-5 font-roboto shadow-md">
                  <IoSearchOutline className="text-textGrey" />{" "}
                  <input
                    type="text"
                    placeholder="Search by Employee Name or Id"
                    className="focus:outline-none w-[290px] px-2 rounded-[4px] mr-2 text-textGrey text-[14px] font-roboto"
                    onChange={(e) => setsearchTerms(e.target.value)}
                  />
                </div>
              ) : null}
            </div>
            <div className="w-[45%] flex items-start justify-center gap-10 font-roboto bg-lightBlue px-7 py-3 rounded-2xl">
              <div className="relative flex flex-col items-center gap-4">
                <h2 className="text-sm font-semibold">
                  Survey Session Start Date
                </h2>
                {session?.sessionStartDate ? (
                  <div className="font-medium text-activeGreen">
                    {formatDateToShow(session?.sessionStartDate)}
                  </div>
                ) : (
                  <div className="flex gap-2">
                    <div
                      className={`text-sm p-2 font-medium ${
                        selectedDate ? "text-activeGreen" : "text-textGrey"
                      }  bg-white rounded-md`}
                    >
                      {selectedDate
                        ? formatDateToShow(selectedDate)
                        : "Press start to select date"}
                    </div>
                    <button
                      // disabled={!selectedDate}
                      onClick={() => {
                        setShowSessionStartModal(true);
                      }}
                      className="text-sm px-4 py-2 font-medium bg-buttonPrimary text-white rounded-md"
                    >
                      START SESSION
                    </button>
                  </div>
                )}
                {/* <div
                    className={`absolute ${
                      calendarDropDown === "start"
                        ? "-bottom-[350px] z-10 opacity-100"
                        : "-bottom-0 -z-10 opacity-0"
                    } transition-all duration-300 left-0 bg-white p-1 rounded-md shadow-md`}
                  >
                    <DateCalendar
                      sx={{ width: "270px", height: "330px" }}
                      minDate={today}
                      value={selectedDate}
                      onChange={(newDate) => handleSelectDate(newDate)}
                    />
                  </div> */}
              </div>
              <div className="relative flex flex-col items-center gap-4">
                <h2 className="text-sm font-semibold">
                  Survey Session End Date
                </h2>
                <p>{session?.sessionEndDate || "-"}</p>
              </div>
            </div>
            <div className="w-[15%]">
              <LocationPicker />
            </div>
          </div>
        </div>
      </div>
      {session?.isActive ? (
        <div className="flex bg-white rounded-2xl h-[75vh] items-start mb-6 gap-4 w-[90%] mx-auto px-2">
          <div className="w-1/2 overflow-y-auto h-full custom-scrollbar">
            <EmpListToAssign
              selectEmp={setAssignedDistricts}
              searchTerms={searchTerms}
              getEmpAssignment={getEmpAssignment}
            />
          </div>

          <div
            className={`flex ${
              assignedDistricts?.length > 0
                ? "items-start justify-start"
                : "items-center justify-center"
            } h-full  overflow-y-auto custom-scrollbar w-1/2 rounded-2xl gap-4 flex-wrap py-4`}
          >
            {assignedDistricts ? (
              <>
                {showProgress === "assignment-card" ? (
                  <div className="w-full h-full flex items-center justify-center">
                    <CircularProgress sx={{ margin: "24px" }} size={24} />
                  </div>
                ) : assignedDistricts?.length > 0 ? (
                  assignedDistricts?.map((item, i) => (
                    <AssigmentCard
                      key={i}
                      assignment={item}
                      emp_id={showAssignmentsForEmp}
                    />
                  ))
                ) : (
                  <h3 className="font-semibold text-[17px] text-buttonSecondary">
                    No Assigned Tasks
                  </h3>
                )}
              </>
            ) : (
              <p className="font-semibold text-[17px] text-buttonSecondary">
                Select an employee to monitor assigned tasks.
              </p>
            )}
          </div>
        </div>
      ) : null}
      <SurveyConfirmationModal
        showModal={showSessionStartModal}
        closeModal={handleCloseSessionStartModal}
        proceed={handleSessionStart}
        title="Are you sure want to create a survey session?"
        caption="(Once we create not able to delete)"
        progress={showProgress}
      />
    </>
  );
};

export default EmployeeAssignments;
