import React, { useContext, useEffect } from "react";
import { GiCancel } from "react-icons/gi";
import { DistrictsContext } from "../context/DistrictsContext";

const UpdateSuccessModal = ({
  message,
  handleNavigate,
  onSuccessFunction,
  closeModal,
  modal,
}) => {
  const { updatedModal, setUpdatedModal } = useContext(DistrictsContext);

  useEffect(() => {
    if (updatedModal === true || modal === true) {
      document.body.style.overflow = "hidden";
    }

    const timer = setTimeout(() => {
      handleClose();
    }, 2000);

    return () => {
      clearTimeout(timer);
      document.body.style.overflow = "auto";
    };
  }, [updatedModal]);

  const handleClose = () => {
    if (handleNavigate) {
      handleNavigate();
    }
    setUpdatedModal(false);
    closeModal && closeModal();
    onSuccessFunction && onSuccessFunction();
  };
  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-2xl flex items-center justify-center p-4 w-[30%] h-[35vh] font-bold text-buttonSecondary font-poppins">
        <div
          onClick={handleClose}
          className="cursor-pointer hover:bg-black hover:bg-opacity-10 rounded-full p-2 absolute right-5 top-3"
        >
          <GiCancel className="text-xl" />
        </div>
        <p className="text-center">{message}</p>
      </div>
    </div>
  );
};

export default UpdateSuccessModal;
