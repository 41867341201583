import React, { useEffect } from "react";
import UploadSurveySamples from "./UploadSurveySamples";
import { formatDateToShow } from "../utils/formatDate";
import { GiCancel } from "react-icons/gi";

const UploadSampleModal = ({
  setShowUploadSampleModal,
  openSampleUploaded,
  selectedSession,
  sampleCount,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed inset-0 bg-black z-10 bg-opacity-50 flex items-center justify-center font-roboto">
      <div className="relative bg-white rounded-3xl shadow-lg w-1/2 p-7 h-[65%] flex flex-col items-center justify-center gap-7">
        <h1 className="text-center font-semibold text-lg">
          Choose{" "}
          <span className="text-activeGreen">{`${formatDateToShow(
            selectedSession?.session_start_date
          )} - ${formatDateToShow(
            selectedSession?.session_end_date
          )}`}</span>{" "}
          Survey Session Lab Result (.XLS Format File)
        </h1>
        <div
          onClick={() => setShowUploadSampleModal(false)}
          className="cursor-pointer hover:bg-black hover:bg-opacity-10 transition-all duration-300 rounded-full p-2 absolute top-3 right-3"
        >
          <GiCancel className="text-xl" />
        </div>
        <div className="list-disc text-md mx-auto md:w-[70%] lg:w-[60%]">
          <li>Upload only Selected Session Lab Report</li>
          <li>Upload only (.xlsx) format</li>
        </div>
        <UploadSurveySamples
          close={() => setShowUploadSampleModal(false)}
          openSampleUploaded={openSampleUploaded}
          sampleCount={sampleCount}
          selectedSession={selectedSession}
        />
      </div>
    </div>
  );
};

export default UploadSampleModal;
