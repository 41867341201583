import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { GiCancel } from "react-icons/gi";
const SurveyConfirmationModal = ({
  showModal,
  closeModal,
  proceed,
  title,
  caption,
  progress,
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);
  return (
    <div>
      {showModal && (
        <div className="fixed inset-0 bg-black z-10 bg-opacity-50 flex items-center justify-center font-roboto">
          <div className="relative bg-white shadow rounded-lg w-[35%] h-1/2 flex items-center justify-center">
            <div className="flex flex-col items-center gap-7">
              <h3 className="text-lg font-bold">{title}</h3>
              {caption && (
                <p className="font-medium text-errorRed">{caption}</p>
              )}
              <div className="flex items-center gap-11">
                <button
                  onClick={proceed}
                  className="w-24 py-1 rounded-md shadow-md test-sm font-medium bg-skyBlue text-white"
                >
                  {progress ? (
                    <CircularProgress color="default" size={13} />
                  ) : (
                    "Yes"
                  )}
                </button>
                <button
                  onClick={closeModal}
                  className="w-24 py-1 rounded-md shadow-md test-sm font-medium bg-errorRed text-white"
                >
                  No
                </button>
              </div>
            </div>
            <div
              onClick={closeModal}
              className="cursor-pointer hover:bg-black hover:bg-opacity-10 transition-all duration-300 rounded-full p-2 absolute top-3 right-3"
            >
              <GiCancel className="text-xl" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyConfirmationModal;
