import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../interceptor/axiosInstance";
import downloadImg from "../assets/download_1.png";
import AssgWellListCard from "./AssgWellListCard";
import { useDispatch, useSelector } from "react-redux";
import UpdateSuccessModal from "./UpdateSuccessModal";
import { FaCheck } from "react-icons/fa6";
import {
  clearAssignment,
  clearWells,
  getSelectedEmp,
  getSelectedWell,
  selectAllWells,
  setSelectedEmp,
  toggleWell,
} from "../redux/assignmentSlice";
import { getUsers } from "../redux/usersSlice";
import { CircularProgress } from "@mui/material";
import { DistrictsContext } from "../context/DistrictsContext";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { socket } from "../socket";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const AssigmentCard = ({ assignment, emp_id }) => {
  const [showWells, setShowWells] = useState(false);
  const [showProgress, setShowProgress] = useState();
  const [notSelectedMsg, setNotSelectedMsg] = useState("");
  const users = useSelector(getUsers);
  const selectedEmp = useSelector(getSelectedEmp);
  const selectedWell = useSelector(getSelectedWell);
  const [showEmpsToReassign, setShowEmpsToReassign] = useState(false);
  const { updatedModal, setUpdatedModal } = useContext(DistrictsContext);
  const [availableEmps, setAvailableEmps] = useState(
    users?.filter(
      (user) =>
        user?.user_status === "Active" && user?.emp_id !== selectedEmp?.emp_id
    )
  );
  const { sessionStartDate } = useSelector((state) => state.session);
  const [assignedWells, setAssignedWells] = useState();
  const [dugWellsData, setDugWellsData] = useState();
  const [piezoWellsData, setPiezoWellsData] = useState();
  const dispatch = useDispatch();

  const handleDownloadPDF = (district, type) => {
    const doc = new jsPDF({ orientation: "landscape" });
    const pageWidth = doc.internal.pageSize.width;
    //HEADING
    doc.setFontSize(10);

    const heading = `District-${district} / Well Type-${type} / Emp Id-${emp_id}`;

    const headingWidth = doc.getTextWidth(heading);

    // Center position calculation
    const headingX = (pageWidth - headingWidth) / 2;

    // Add centered text for District and Well Type
    doc.text(heading, headingX, 7);

    const tableData = type.toLowerCase().includes("piezometer")
      ? piezoWellsData?.map((well, i) => [
          i + 1,
          well?.ex_well_id,
          well?.firka,
          well?.village,
          well?.location_detail,
          `${well?.latitude}\n${well?.latitude}`,
          well?.key_no,
          well?.vendor,
          well?.depth,
          well?.measuring_point,
          well?.diameter ? well?.diameter : "-",
          `${well?.well_bp_id}\n${well?.well_hm_id}`,
        ])
      : type.toLowerCase().includes("dug well")
      ? dugWellsData?.map((well, i) => [
          i + 1,
          well?.ex_well_id,
          well?.firka,
          well?.village,
          well?.location_detail,
          `${well?.latitude}\n${well?.latitude}`,
          `${well?.well_bp_id}\n${well?.well_hm_id}`,
          `${
            well?.sample_taken === 0
              ? "Not Taken"
              : well?.sample_taken === 1
              ? "Taken"
              : "-"
          }`,
          well?.depth,
          well?.measuring_point,
          well?.diameter ? well?.diameter : "-",
          well?.curbing_depth,
          well?.use_of_well,
        ])
      : [];

    // Set up table columns based on the well type
    const columns = type.toLowerCase().includes("piezometer")
      ? [
          "No.",
          "Well Id",
          "Firka",
          "Village",
          "Location Detail",
          "Latitude / Longitude",
          "Key No",
          "Vendor",
          "Depth",
          "MP",
          "Dia",
          "Base Parameter / Heavy Metal",
          "Sample Status",
          "Protection Box",
          "Type of Lock",
          "Water Level",
          "EC",
          "Temp ",
          "pH",
          "Remarks",
        ]
      : type.toLowerCase().includes("dug well")
      ? [
          "No.",
          "Well Id",
          "Firka",
          "Village",
          "Location Detail",
          "Latitude / Longitude",
          "Base Parameter / Heavy Metal",
          "Sample Status",
          "Depth",
          "MP",
          "Dia",
          "Curbing Depth",
          "Use Of Well",
          "Water Level",
          "EC",
          "Temp",
          "pH",
          "Remarks",
        ]
      : [];

    // Add table to PDF
    autoTable(doc, {
      head: [columns],
      body: tableData,
      startY: 10,
      theme: "grid",
      headStyles: {
        fillColor: [45, 128, 185],
        textColor: [255, 255, 255],
        lineColor: [255, 255, 255], // White border color
        lineWidth: 0.1, // Thickness of the border
        fontSize: 5,
        halign: "center",
        valign: "middle",
      }, // Optional: customize header color
      margin: { left: 3, right: 3, top: 10, bottom: 10 },
      tableWidth: "auto",
      bodyStyles: { fontSize: 5 }, // Reduce font size for table body
      columnStyles: type.toLowerCase().includes("piezometer")
        ? {
            0: { cellWidth: 7 },
            1: { cellWidth: 20 },
            2: { cellWidth: 17 },
            3: { cellWidth: 20 },
            4: { cellWidth: 35 },
            5: { cellWidth: 15 },
            6: { cellWidth: 10 },
            7: { cellWidth: 10 },
            8: { cellWidth: 9 },
            9: { cellWidth: 9 },
            10: { cellWidth: 9 },
            11: { cellWidth: 14 },
            12: { cellWidth: 12 },
            13: { cellWidth: 12 },
            14: { cellWidth: 12 },
            15: { cellWidth: 12 },
            16: { cellWidth: 12 },
            17: { cellWidth: 12 },
            18: { cellWidth: 12 },
            19: { cellWidth: 33 },
          }
        : type.toLowerCase().includes("dug well")
        ? {
            0: { cellWidth: 9 },
            1: { cellWidth: 20 },
            2: { cellWidth: 15 },
            3: { cellWidth: 15 },
            4: { cellWidth: 35 },
            5: { cellWidth: 15 },
            6: { cellWidth: 15 },
            7: { cellWidth: 14 },
            8: { cellWidth: 14 },
            9: { cellWidth: 14 },
            10: { cellWidth: 14 },
            11: { cellWidth: 14 },
            12: { cellWidth: 14 },
            13: { cellWidth: 14 },
            14: { cellWidth: 14 },
            15: { cellWidth: 14 },
            16: { cellWidth: 14 },
            17: { cellWidth: 45 },
          }
        : {},
      pageBreak: "auto",
      styles: { overflow: "linebreak" },
      didDrawPage: function (data) {
        // Re-add the heading to the top of each page
        doc.setFontSize(10);
        doc.text(heading, headingX, 7);

        // // Draw a line to separate the heading from the table (optional)
        // doc.setLineWidth(0.5);
        // doc.line(10, 8, pageWidth - 10, 8);
      },
    });

    // Save the PDF
    doc.save(`${emp_id}-${district}-${type}.pdf`);
  };
  const getAssignedWells = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/employee/get/assigned/wells/${emp_id}/${assignment?.district_id}`
      );

      const data = response?.data?.data;
      const pendingWells = data?.filter(
        (item) => item?.survey_status === "Pending"
      );
      const dugWells = data?.filter((item) =>
        item?.well_type.toLowerCase().includes("dug well")
      );
      const piezoWells = data?.filter((item) =>
        item?.well_type.toLowerCase().includes("piezometer")
      );
      setDugWellsData(dugWells);
      setPiezoWellsData(piezoWells);
      setAssignedWells(pendingWells);
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setShowWells(false);
    dispatch(clearAssignment());
  };
  const handleSelectedEmp = (emp) => {
    dispatch(setSelectedEmp(emp));
  };

  const handleReAssign = () => {
    if (selectedWell.length > 0) {
      setShowWells(false);
      setShowEmpsToReassign(true);
      setNotSelectedMsg("");
    } else {
      setNotSelectedMsg("Please select atleast one well");
    }
  };

  const isAllSelected = assignedWells?.every((well) =>
    selectedWell.includes(well.well_id)
  );

  const handleSelectAll = () => {
    isAllSelected
      ? dispatch(clearWells())
      : dispatch(selectAllWells(assignedWells?.map((well) => well?.well_id)));
  };

  const handleConfirm = async () => {
    if (
      selectedEmp === null ||
      selectedEmp?.emp_id ===
        availableEmps?.find((emp) => emp?.emp_id === selectedEmp?.emp_id)
    ) {
      setNotSelectedMsg("Please select an employee");
    } else {
      try {
        setShowProgress("confirm");
        const response = await axiosInstance.post(
          "/api/work/customBased/assign",
          {
            emp_id: selectedEmp?.emp_id,
            wellId: selectedWell,
            reassign: true,
            district_name: assignment?.district_name,
            session_start_date: sessionStartDate,
          }
        );
        socket.emit(
          "notification_send",
          selectedEmp?.emp_id,
          response?.data?.message
        );
        dispatch(clearAssignment());
        setShowEmpsToReassign(false);
        setShowWells(false);
        setShowProgress("");
        setUpdatedModal(true);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    handleClose();
    getAssignedWells();
  }, []);

  return (
    <>
      <div className="font-poppins w-[47%] p-4 border-[0.7px] border-lightGrey rounded-lg gap-4 h-[510px]">
        <div className="flex items-center justify-between">
          <span className="text-blackSub text-xs text-left tracking-tighter">
            Employee Assigment
          </span>
          <span className="text-blackSub text-xs font-bold">
            {assignment?.district_name}
          </span>
        </div>
        <div className="flex items-center my-2 gap-1 text-blackSubDark font-semibold">
          <span className="text-xl capitalize">{assignment?.name}</span>
          <span>|</span>
          <span className="text-sm">+{assignment?.mobile_no}</span>
        </div>
        <div className="bg-[#8E8E8E] h-[0.5px] w-full my-4"></div>
        <h3 className="text-blackSub font-medium">Survey Progress</h3>
        <div className={`w-full h-[15px] my-4 flex items-center`}>
          <div
            style={{
              width: `${
                (assignment?.survey_completed / assignment?.Total_assign) * 100
              }%`,
            }}
            className={`${
              assignment?.survey_completed == assignment?.Total_assign &&
              "rounded-full"
            } h-full bg-barGreen rounded-l-full`}
          ></div>
          <div
            style={{
              width: `${
                (assignment?.survey_pending / assignment?.Total_assign) * 100
              }%`,
            }}
            className={`${
              assignment?.survey_pending == assignment?.Total_assign &&
              "rounded-full"
            } h-full bg-barYellow rounded-r-full`}
          ></div>
        </div>
        <div className="flex items-center justify-between">
          <h2 className="text-blackSubDark font-bold text-[33px]">
            {Math.round(
              (assignment?.survey_completed / assignment?.Total_assign) * 100
            )}
            %
          </h2>
          <div className="bg-[#8E8E8E] w-[0.5px] h-[45px]"></div>
          <div>
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 bg-barGreen rounded-full"></div>
              <span className="text-blackSub text-[13px] font-semibold">
                Completed
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 bg-barYellow rounded-full"></div>
              <span className="text-blackSub text-[13px] font-semibold">
                Pending
              </span>
            </div>
          </div>
        </div>
        <div className="bg-[#8E8E8E] h-[0.5px] w-full my-4"></div>
        <table className="border-separate border-spacing-y-[2px]">
          <tbody className="text-blackSub text-xs font-medium">
            <tr>
              <td>Total Assign</td>
              <td className="px-3">:</td>
              <td>{assignment?.Total_assign}</td>
            </tr>
            <tr>
              <td>Pending</td>
              <td className="px-3">:</td>
              <td>{assignment?.survey_pending}</td>
            </tr>
            <tr>
              <td>Completed</td>
              <td className="px-3">:</td>
              <td>{assignment?.survey_completed}</td>
            </tr>
            <tr>
              <td>Assigned Date</td>
              <td className="px-3">:</td>
              <td>{assignment?.assign_date}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td className="px-3">:</td>
              <td className="text-activeGreen font-medium">
                {assignment?.user_status}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-6 flex flex-col items-center justify-center">
          <h3 className="text-xs text-dullRed font-semibold">
            Download Survey Sheet as Pdf
          </h3>
          <div className="flex items-center justify-center gap-5 mt-2">
            <div
              onClick={() =>
                handleDownloadPDF(assignment?.district_name, "Piezometer")
              }
              className="flex items-center gap-2 border-[1px] border-textGrey rounded-lg py-1 px-2"
            >
              <button className="text-xs">Piezometer</button>
              <img className="w-3" src={downloadImg} alt="download-img" />
            </div>
            <div
              onClick={() =>
                handleDownloadPDF(assignment?.district_name, "Dug well")
              }
              className="flex items-center gap-2 border-[1px] border-textGrey rounded-lg py-1 px-2"
            >
              <button className="text-xs">Dug Well</button>
              <img className="w-3" src={downloadImg} alt="download-img" />
            </div>
          </div>
          <div className="h-[0.5px] w-full px-4 mt-2 bg-textGrey"></div>
          {assignment?.survey_pending === 0 ? null : (
            <button
              onClick={() => setShowWells(true)}
              className={`bg-skyBlue mt-2 text-white ${
                showProgress === "reassign" ? "px-[49px]" : "px-8"
              } py-[6px] font-poppins rounded-[5px] text-[10px]`}
            >
              {showProgress === "reassign" ? (
                <CircularProgress color="default" size={11} />
              ) : (
                "Reassign"
              )}
            </button>
          )}
        </div>
      </div>
      {showWells === true ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 font-roboto">
          <div className="relative bg-white p-7 w-1/2 rounded-[16px] h-[80vh]">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold">
                Assigned Wells for {assignment?.name} ({emp_id})
              </h2>
              <div
                className={`${
                  isAllSelected ? "bg-skyBlue text-white" : "bg-softGrey"
                }  hover:bg-skyBlue hover:text-white transition-colors duration-300 cursor-pointer shadow-md text-sm px-3 py-1 rounded-full flex items-center gap-1`}
                onClick={handleSelectAll}
              >
                <span>Select All</span>
                <IoMdCheckmarkCircleOutline />
              </div>
            </div>
            <div className="h-[80%] overflow-y-scroll custom-scrollbar border-[0.3px] my-4 border-lightGrey rounded-lg shadow-md">
              {assignedWells?.map((item, i) => (
                <AssgWellListCard
                  cardNumber={i + 1}
                  key={i}
                  checkbox="true"
                  item={item}
                />
              ))}
            </div>
            {notSelectedMsg === "Please select atleast one well" ? (
              <div className="text-errorRed text-center absolute left-56 bottom-3 font-medium text-sm">
                {notSelectedMsg}
              </div>
            ) : null}
            <div className="flex items-center justify-end gap-4">
              <button
                onClick={handleClose}
                className="bg-softGrey px-8 py-2 font-poppins rounded-[5px] text-xs"
              >
                Close
              </button>
              <button
                disabled={selectedWell?.length <= 0}
                onClick={handleReAssign}
                className={`${
                  selectedWell?.length <= 0
                    ? "bg-lightGrey text-black cursor-not-allowed"
                    : "bg-skyBlue text-white"
                } px-8 py-2 font-poppins rounded-[5px] text-xs transition-colors`}
              >
                Reassign
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showEmpsToReassign === true ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 font-roboto">
          <div className="bg-white p-7 w-[30%] rounded-[16px] h-[70vh]">
            <h2 className="text-center font-poppins font-semibold">
              Reassign Employee for {assignment?.district_name}
            </h2>
            <p className="text-center text-xs">
              Select a new employee to manage the surveys
            </p>
            <div className="h-[65%] flex flex-col overflow-y-scroll my-4 py-2 rounded-lg custom-scrollbar">
              {availableEmps?.map((item, i) => (
                <div
                  onClick={() => handleSelectedEmp(item)}
                  className={`${
                    selectedEmp?.emp_id === item?.emp_id
                      ? "bg-softGrey hover:bg-softGrey"
                      : "hover:bg-softGrey"
                  } flex items-center justify-between cursor-pointer gap-4 px-4 py-2 my-[2px] rounded-md`}
                  key={i}
                >
                  <div className="w-[50%] flex items-center gap-2">
                    <p>{i + 1}.</p>
                    <p className="capitalize">{item?.name}</p>
                  </div>
                  <p className="capitalize text-activeGreen">
                    {item?.user_status}
                  </p>
                  <div
                    className={`w-[18px] h-[18px] border-2 ${
                      selectedEmp?.emp_id === item?.emp_id
                        ? "border-[#000AFF] border-opacity-100"
                        : "border-textGrey border-opacity-50"
                    }   rounded-[5px]`}
                  >
                    <FaCheck
                      className={`text-sm transition-opacity duration-300 text-[#000AFF] ${
                        selectedEmp?.emp_id === item?.emp_id
                          ? "opacity-100"
                          : "opacity-0"
                      }`}
                    />
                  </div>
                </div>
              ))}
            </div>
            {notSelectedMsg === "Please select an employee" ? (
              <div className="text-errorRed text-center my-2 font-medium text-xs">
                Please select an employee
              </div>
            ) : null}
            <div className="flex items-center justify-center gap-4 mt-8">
              <button
                onClick={() => {
                  setShowEmpsToReassign(false);
                  handleClose();
                }}
                className="bg-softGrey px-8 py-2 font-poppins rounded-[5px] text-xs"
              >
                Close
              </button>
              <button
                disabled={!selectedEmp}
                onClick={handleConfirm}
                className={`${
                  !selectedEmp
                    ? "bg-lightGrey text-black cursor-not-allowed"
                    : "bg-skyBlue text-white"
                } ${
                  showProgress === "confirm" ? "px-[49px]" : "px-8"
                } py-2 font-poppins rounded-[5px] text-xs transition-colors`}
              >
                {showProgress === "confirm" ? (
                  <CircularProgress color="default" size={13} />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/* {updatedModal === true ? (
        <UpdateSuccessModal message={"Work reassigned successfully!"} />
      ) : null} */}
    </>
  );
};

export default AssigmentCard;
