import React, { useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa6";
import { GiCancel } from "react-icons/gi";
import { IoAddCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import NewWellForm from "../components/NewWellForm";
import Table from "../components/Table";
import UpdateSuccessModal from "../components/UpdateSuccessModal";
import axiosInstance from "../interceptor/axiosInstance";
import { clearWellsForApproval } from "../redux/assignmentSlice";
import { setSelectedSession } from "../redux/sessionSlice";
import { formatDateToShow } from "../utils/formatDate";
const ReportsAndAnalytics = () => {
  const [showProgress, setShowProgress] = useState(false);
  const [reportData, setreportData] = useState([]);
  const [dateDropDown, setDateDropDown] = useState();
  const [showNewWellForm, setShowNewWellForm] = useState();
  const [updatedModal, setUpdatedModal] = useState();
  const [sessions, setSessions] = useState();
  const [surveyCompletionStatus, setSurveyCompletionStatus] = useState();
  const stateName = useSelector((state) => state.state.stateName);
  const selectedSession = useSelector((state) => state.session.selectedSession);
  const isActive = useSelector((state) => state.session.isActive);
  const dispatch = useDispatch();

  const checkSurveyCompletionStatus = (data) => {
    let totalSurveyAssigned = 0;
    let totalSurveyCompleted = 0;

    if (data && data?.length > 0) {
      data?.forEach((item) => {
        totalSurveyAssigned += item?.survey_assigned;
        totalSurveyCompleted += item?.survey_completed;
      });
      const isAllApproved = data?.every(
        (item) => item?.sessionstatus === "Approved"
      );
      setSurveyCompletionStatus(
        totalSurveyAssigned === totalSurveyCompleted && isAllApproved
      );
    } else {
      setSurveyCompletionStatus("No surveys found in this session.");
    }
  };

  const checkSessionStatus = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/employee/get/session/status`
      );
      const data = response?.data?.Data;
      setSessions(data);
      if (data && data?.length > 0) {
        const currentSession = data?.find(
          (session) => session?.session_end_date === null || ""
        );
        if (!selectedSession?.session_end_date) {
          if (currentSession) {
            dispatch(setSelectedSession(currentSession));
          } else {
            dispatch(setSelectedSession(data[0]));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const analyticsData = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `api/district/analysis/data/${stateName}/${selectedSession?.session_start_date}/${selectedSession?.session_end_date}`
      );

      const result = response?.data?.Data;
      setreportData(result || []);
      checkSurveyCompletionStatus(result);
    } catch (error) {
      console.error("error", error);
    } finally {
      setShowProgress(false);
    }
  };

  const handleSelectSession = (session) => {
    dispatch(setSelectedSession(session));
    setDateDropDown("");
  };

  useEffect(() => {
    if (selectedSession) {
      analyticsData();
    }
  }, [selectedSession, stateName]);

  useEffect(() => {
    checkSessionStatus();
    dispatch(clearWellsForApproval());
  }, []);

  useEffect(() => {
    if (showNewWellForm) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showNewWellForm]);
  console.log(selectedSession?.session_end_date);

  return (
    <>
      <div className="w-[90%] mx-auto bg-softGrey">
        <div className="font-poppins">
          <div className="my-2 flex items-center justify-between">
            <h2 className="font-bold relative flex items-center text-blackSubDark text-lg">
              Survey Report And analytics
            </h2>
          </div>
        </div>
        <div className="relative">
          <div className="flex items-center justify-between mt-4 gap-10">
            <div className="bg-white w-[350px] shadow-md relative flex flex-col items-center rounded-lg font-roboto text-sm px-4 py-2">
              <p className="text-activeGreen font-medium">
                Choose Survey Session
              </p>
              <div className="p-1 w-fit border-b border-b-lightGrey">
                <div
                  onClick={() =>
                    setDateDropDown(dateDropDown === "session" ? "" : "session")
                  }
                  className="flex items-center justify-center cursor-pointer gap-3"
                >
                  <div>
                    {selectedSession ? (
                      <>
                        <span>
                          {formatDateToShow(
                            selectedSession?.session_start_date
                          )}
                        </span>
                        <span className="mx-2">-</span>
                        <span
                          className={
                            !selectedSession?.session_end_date && "text-dullRed"
                          }
                        >
                          {selectedSession?.session_end_date
                            ? formatDateToShow(
                                selectedSession?.session_end_date
                              )
                            : "END DATE NOT ASSIGNED"}
                        </span>
                      </>
                    ) : (
                      "Choose Survey Session"
                    )}
                  </div>
                  <span>
                    <FaCaretDown />
                  </span>
                </div>
                <div
                  className={`${
                    dateDropDown === "session"
                      ? "inline-block z-10 top-[70px] opacity-100"
                      : "-top-10 -z-50 opacity-0"
                  } absolute bg-white left-0 rounded-lg w-[100%] shadow-xl transition-all duration-500`}
                >
                  <ul>
                    {sessions?.length > 0 ? (
                      sessions?.map((item, i) => (
                        <li
                          onClick={() => handleSelectSession(item)}
                          key={i}
                          className={`${
                            i === 0
                              ? "rounded-t-lg"
                              : i === sessions.length - 1
                              ? "rounded-b-lg"
                              : ""
                          } ${
                            selectedSession?.session_start_date ===
                              item?.session_start_date &&
                            selectedSession?.session_end_date ===
                              item?.session_end_date
                              ? "bg-buttonPrimary text-white"
                              : ""
                          } p-1 cursor-pointer text-center hover:bg-buttonPrimary hover:text-white`}
                        >
                          <span>
                            {formatDateToShow(item?.session_start_date)}
                          </span>
                          <span className="mx-3">-</span>
                          <span>
                            {item?.session_end_date
                              ? formatDateToShow(item?.session_end_date)
                              : "END DATE NOT ASSIGNED"}
                          </span>
                        </li>
                      ))
                    ) : (
                      <li className="p-1 cursor-pointer text-center">
                        No Session Found!
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {!selectedSession?.session_end_date && (
              <div
                onClick={() => setShowNewWellForm(true)}
                className="bg-white hover:text-skyBlue cursor-pointer transition-colors duration-300 flex items-center gap-1 p-2 rounded-lg shadow-lg"
              >
                <IoAddCircleSharp className="text-2xl" />
                <span className="font-roboto font-medium uppercase text-sm">
                  Add New Well
                </span>
              </div>
            )}
          </div>
        </div>
        <div>
          <Table
            checkSession={checkSessionStatus}
            getData={analyticsData}
            showProgress={showProgress}
            reportData={reportData}
            selectedSession={selectedSession}
            completionStatus={surveyCompletionStatus}
          />
        </div>
      </div>
      {showNewWellForm ? (
        <div className="fixed inset-0 bg-black z-10 bg-opacity-50 flex items-center justify-center font-roboto">
          <div className="relative bg-white shadow rounded-lg w-1/2 h-3/4 overflow-y-auto custom-scrollbar ">
            <div className="w-3/4 mx-auto my-4">
              <h2 className="uppercase text-lg font-medium font-poppins text-center mb-4 text-buttonSecondary">
                Add New Well
              </h2>
              <NewWellForm
                selectedSession={selectedSession}
                openSuccessModal={() => setUpdatedModal(true)}
                closeModal={() => setShowNewWellForm(false)}
              />
            </div>
            <div
              onClick={() => setShowNewWellForm(false)}
              className="cursor-pointer hover:bg-black hover:bg-opacity-10 transition-all duration-300 rounded-full p-2 absolute top-3 right-3"
            >
              <GiCancel className="text-xl" />
            </div>
          </div>
        </div>
      ) : null}
      {updatedModal === true ? (
        <UpdateSuccessModal
          modal={updatedModal}
          onSuccessFunction={analyticsData}
          message={"New well created and survey assigned successfully!"}
          closeModal={() => setUpdatedModal(false)}
        />
      ) : null}
    </>
  );
};

export default ReportsAndAnalytics;
