import { createContext, useState } from "react";
import axiosInstance from "../interceptor/axiosInstance";
import { setUsers } from "../redux/usersSlice";
import { useDispatch } from "react-redux";

const EmpManagementContext = createContext();

const EmpManagementProvider = ({ children }) => {
  const [createUser, setCreateUser] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState();
  const [empModal, setEmpModal] = useState();
  const dispatch = useDispatch();
  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get(`api/employee/all/users/data`);
      const users = response?.data?.Data;
      users && dispatch(setUsers(users));
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <EmpManagementContext.Provider
      value={{
        createUser,
        setCreateUser,
        editUserDetails,
        setEditUserDetails,
        empModal,
        setEmpModal,
        fetchUsers,
      }}
    >
      {children}
    </EmpManagementContext.Provider>
  );
};

export { EmpManagementContext, EmpManagementProvider };
