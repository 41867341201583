import React, { useContext, useEffect, useState } from "react";
import TextInput from "./TextInput";
import axiosInstance from "../interceptor/axiosInstance";
import { DistrictsContext } from "../context/DistrictsContext";
import { GiCancel } from "react-icons/gi";

function EditForm({ well, handleCloseImageModal, setSelectedWell }) {
  const [enableUpdateBtn, setEnableBtn] = useState(false);
  const [initialValuesofSamples, setInitialValuesOfSamples] = useState({
    th_value: well?.th_value,
    ca_value: well?.ca_value,
    mg_value: well?.mg_value,
    na_value: well?.na_value,
    k_value: well?.k_value,
    co3_value: well?.co3_value,
    hco3_value: well?.hco3_value,
    cl_value: well?.cl_value,
    so4_value: well?.so4_value,
    no3_value: well?.no3_value,
    f_value: well?.f_value,
    u_value: well?.u_value,
  });
  const [formData, setFormData] = useState({
    survey_id: well?.survey_id,
    th_value: well?.th_value,
    ca_value: well?.ca_value,
    mg_value: well?.mg_value,
    na_value: well?.na_value,
    k_value: well?.k_value,
    co3_value: well?.co3_value,
    hco3_value: well?.hco3_value,
    cl_value: well?.cl_value,
    so4_value: well?.so4_value,
    no3_value: well?.no3_value,
    f_value: well?.f_value,
    u_value: well?.u_value,
  });
  const { setUpdatedModal } = useContext(DistrictsContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(initialValuesofSamples[name]);
    initialValuesofSamples[name] !== value
      ? setEnableBtn(true)
      : setEnableBtn(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    const body = formData;
    try {
      const response = axiosInstance.put(`/api/well/edit/survey/sample`, body);
      handleCloseImageModal();
      setUpdatedModal("sample updated");
      setSelectedWell();
    } catch (e) {
      console.log("ERROR", e);
    }
  }

  return (
    <>
      <div className="flex items-center relative mt-2 justify-center text-base font-bold text-buttonSecondary font-poppins">
        <h2 className="text-center">Water Sample Details</h2>
        <GiCancel
          onClick={handleCloseImageModal}
          className="cursor-pointer absolute text-2xl right-3"
        />
      </div>
      <div className="h-[85%] mt-4 custom-scrollbar overflow-y-scroll font-roboto">
        <form className="w-full mx-auto max-w-lg" onSubmit={handleSubmit}>
          <h3 className="font-semibold text-sm text-textGrey">
            Ground Water Level Sample:
          </h3>
          <h4 className="font-semibold text-sm text-textGrey my-2">
            Base Paramter -{" "}
            <span className="text-[15px] text-errorRed">
              ({well?.well_bp_id})
            </span>
          </h4>
          <div className="grid grid-cols-2 items-center gap-x-4">
            <TextInput
              label={"TH"}
              name={"th_value"}
              value={formData.th_value}
              onChange={handleChange}
              placeholder=""
            />
            <TextInput
              label={"Ca"}
              name={"ca_value"}
              value={formData.ca_value}
              onChange={handleChange}
              placeholder=""
            />
            <TextInput
              label={"Mg"}
              name={"mg_value"}
              value={formData.mg_value}
              onChange={handleChange}
              placeholder=""
            />

            <TextInput
              label={"Na"}
              name={"na_value"}
              value={formData?.na_value}
              onChange={handleChange}
              placeholder=""
            />

            <TextInput
              label={"k"}
              name={"k_value"}
              value={formData.k_value}
              onChange={handleChange}
              placeholder=""
            />

            <TextInput
              label={"CO3"}
              name={"co3_value"}
              value={formData.co3_value}
              onChange={handleChange}
              placeholder=""
            />
            <TextInput
              label={"HCO3"}
              name={"hco3_value"}
              value={formData.hco3_value}
              onChange={handleChange}
              placeholder=""
            />

            <TextInput
              label={"CL"}
              name={"cl_value"}
              value={formData.cl_value}
              onChange={handleChange}
              placeholder=""
            />

            <TextInput
              label={"SO4"}
              name={"so4_value"}
              value={formData.so4_value}
              onChange={handleChange}
              placeholder=""
            />

            <TextInput
              label={"NO3"}
              name={"no3_value"}
              value={formData.no3_value}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <h4 className="font-semibold text-sm text-textGrey my-2">
            Heavy Metal -{" "}
            <span className="text-[15px] text-errorRed">
              ({well?.well_hm_id})
            </span>
          </h4>
          <div className="grid grid-cols-2 items-center gap-x-4">
            <TextInput
              label={"F"}
              name={"f_value"}
              value={formData.f_value}
              onChange={handleChange}
              placeholder=""
            />

            <TextInput
              label={"U"}
              name={"u_value"}
              value={formData.u_value}
              onChange={handleChange}
              placeholder=""
            />
          </div>

          <div className="w-fit mx-auto mt-4">
            <button
              disabled={!enableUpdateBtn}
              type="submit"
              className={`transition-colors duration-300 ${
                enableUpdateBtn ? "bg-skyBlue text-white" : "bg-softGrey"
              }  py-2 px-6 rounded-md text-sm mt-4 font-semibold`}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditForm;
