import { createContext, useState } from "react";

const EmpAnalyticsContext = createContext();

const EmpAnalyticsProvider = ({ children }) => {
  const [filterDropDown, setFilterDropDown] = useState();
  const [districtData, setDistrictData] = useState();
  const [empData, setEmpData] = useState();
  const [sessionData, setSessionData] = useState();
  const [selectedFilters, setSelectedFilters] = useState({
    district: null,
    employee: null,
    session: null,
  });

  return (
    <EmpAnalyticsContext.Provider
      value={{
        setDistrictData,
        setEmpData,
        setSessionData,
        setFilterDropDown,
        filterDropDown,
        districtData,
        empData,
        sessionData,
        setSelectedFilters,
        selectedFilters,
      }}
    >
      {children}
    </EmpAnalyticsContext.Provider>
  );
};

export { EmpAnalyticsContext, EmpAnalyticsProvider };
