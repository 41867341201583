// import React, { useState } from "react";
// import download from "../assets/download.png";
// import download_1 from "../assets/download_1.png";
// import jsPDF from "jspdf";
// import autoTable from "jspdf-autotable";
// import * as XLSX from "xlsx";
// import DownloadModal from "./DownloadModal";

// const Download_Type = ({ type, data }) => {
//   const [downloadModal, setDownloadModal] = useState();
//   const handleDownloadPDF = () => {
//     const doc = new jsPDF({ orientation: "landscape" });
//     const pageWidth = doc.internal.pageSize.width;

//     doc.setFontSize(10);

//     const heading = `District-${data[0]?.district_name} / Well Type-${data[0]?.well_type}`;

//     const headingWidth = doc.getTextWidth(heading);

//     // Center position calculation
//     const headingX = (pageWidth - headingWidth) / 2;

//     // Add centered text for District and Well Type
//     doc.text(heading, headingX, 7);
//     const tableData = data?.map((well, i) => {
//       if (well?.well_type?.toLowerCase().includes("dug well")) {
//         return [
//           i + 1,
//           well?.state || "-",
//           well?.district_name || "-",
//           well?.ex_well_id || "-",
//           well?.well_type || "-",
//           well?.village || "-",
//           well?.status || "-",
//           well?.location_detail || "-",
//           well?.latitude || "-",
//           well?.longitude || "-",
//           well?.dms_latitude || "-",
//           well?.dms_longitude || "-",
//           well?.depth || "-",
//           well?.measuring_point || "-",
//           well?.diameter || "-",
//           well?.curbing_depth || "-",
//           well?.use_of_well || "-",
//         ];
//       } else if (well?.well_type.toLowerCase().includes("piezometer")) {
//         return [
//           i + 1,
//           well?.state || "-",
//           well?.district_name || "-",
//           well?.ex_well_id || "-",
//           well?.well_type || "-",
//           well?.village || "-",
//           well?.status || "-",
//           well?.location_detail || "-",
//           well?.latitude || "-",
//           well?.longitude || "-",
//           well?.dms_latitude || "-",
//           well?.dms_longitude || "-",
//           well?.key_no || "-",
//           well?.depth || "-",
//           well?.diameter || "-",
//           well?.measuring_point || "-",
//           well?.vendor || "-",
//         ];
//       }
//       return [];
//     });

//     // Set up table columns based on the well type
//     const columns = data[0]?.well_type.toLowerCase().includes("dug well")
//       ? [
//           "No.",
//           "State",
//           "District",
//           "Well ID",
//           "Well Type",
//           "Village",
//           "Well Status",
//           "Location",
//           "Latitude",
//           "Longitude",
//           "DMS Latitude",
//           "DMS Longitude",
//           "Depth of Well",
//           "Measuring Point",
//           "Diameter",
//           "Curbing Depth",
//           "Use of Well",
//         ]
//       : [
//           "No.",
//           "State",
//           "District",
//           "Well ID",
//           "Well Type",
//           "Village",
//           "Well Status",
//           "Location",
//           "Latitude",
//           "Longitude",
//           "DMS Latitude",
//           "DMS Longitude",
//           "Key No",
//           "Depth (m)",
//           "Diameter (m)",
//           "Measuring Point (magl)",
//           "Vendor",
//         ];

//     // Add table to PDF
//     autoTable(doc, {
//       head: [columns],
//       body: tableData,
//       startY: 10,
//       theme: "grid",
//       headStyles: {
//         textColor: [255, 255, 255],
//         lineColor: [255, 255, 255], // White border color
//         lineWidth: 0.1, // Thickness of the border
//         fontSize: 5,
//         halign: "center",
//         valign: "middle",
//         fillColor: [45, 128, 185],
//       }, // Optional: customize header color
//       margin: { top: 10 },
//       pageBreak: "auto",
//       tableWidth: "auto",
//       bodyStyles: { fontSize: 5 }, // Reduce font size for table body
//     });

//     // Save the PDF
//     doc.save(
//       `${data[0]?.district_name?.toLowerCase()}-${data[0]?.well_type.toLowerCase()}-data.pdf`
//     );
//   };

//   const handleDownloadExcel = () => {
//     // Format data based on well_type
//     const formattedData = data.map((well, i) => {
//       if (well?.well_type?.toLowerCase()?.includes("dug well")) {
//         return {
//           "No.": i + 1,
//           State: well?.state || "-",
//           District: well?.district_name || "-",
//           "Well Type": well?.well_type || "-",
//           "Well ID": well?.ex_well_id || "-",
//           Village: well?.village || "-",
//           "Well Status": well?.status || "-",
//           Location: well?.location_detail || "-",
//           Latitude: well?.latitude || "-",
//           Longitude: well?.longitude || "-",
//           "DMS Latitude": well?.dms_latitude || "-",
//           "DMS Longitude": well?.dms_longitude || "-",
//           "Depth Of Well": well?.depth || "-",
//           "Measuring Point": well?.measuring_point || "-",
//           Diameter: well?.diameter || "-",
//           "Curbing Depth": well?.curbing_depth || "-",
//           "Use of Well": well?.use_of_well || "-",
//         };
//       } else if (well?.well_type?.toLowerCase()?.includes("piezometer")) {
//         return {
//           "No.": i + 1,
//           State: well?.state || "-",
//           District: well?.district_name || "-",
//           "Well Type": well?.well_type || "-",
//           "Well ID": well?.ex_well_id || "-",
//           Village: well?.village || "-",
//           "Well Status": well?.status || "-",
//           Location: well?.location_detail || "-",
//           Latitude: well?.latitude || "-",
//           Longitude: well?.longitude || "-",
//           "DMS Latitude": well?.dms_latitude || "-",
//           "DMS Longitude": well?.dms_longitude || "-",
//           "Key No": well?.key_no || "-",
//           "Depth(m)": well?.depth || "-",
//           "Diameter(m)": well?.diameter || "-",
//           "Measuring Point(magl)": well?.measuring_point || "-",
//           Vendor: well?.vendor || "-",
//         };
//       }
//       return {};
//     });
//     const worksheet = XLSX.utils.json_to_sheet(formattedData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

//     // Save the workbook
//     XLSX.writeFile(
//       workbook,
//       `${data[0].district_name}-${data[0].well_type}-well-data.xlsx`
//     );
//   };

//   return (
//     <>
//       <div className="flex items-center justify-center gap-10">
//         <div
//           onClick={() => setDownloadModal("pdf")}
//           className="cursor-pointer flex items-center justify-center gap-2"
//         >
//           <h1 className="text-[13px] font-bold text-buttonPrimary">
//             Download as PDF
//           </h1>
//           <img className="w-3" src={download} alt="" />
//         </div>
//         <div
//           onClick={() => setDownloadModal("excel")}
//           className="cursor-pointer flex items-center justify-center gap-2"
//         >
//           <h1 className="text-[13px] font-bold text-dullRed">
//             Download as Excel
//           </h1>
//           <img className="w-3 text-dullRed" src={download_1} alt="" />
//         </div>
//       </div>
//       {downloadModal && (
//         <DownloadModal
//           type={type}
//           downloadModal={downloadModal}
//           setDownloadModal={setDownloadModal}
//           downloadPdf={handleDownloadPDF}
//           downloadExcel={handleDownloadExcel}
//         />
//       )}
//     </>
//   );
// };

// export default Download_Type;


import React, { useState, useEffect } from "react";
import download from "../assets/download.png";
import download_1 from "../assets/download_1.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";

const fieldNames = {
  state: "State",
  district_name: "District Name",
  firka: "Firka",
  village: "Village",
  ex_well_id: "Well ID",
  well_type: "Well Type", 
  status: "Well Status",
  location_detail: "Location Detail",
  latitude: "Latitude",
  longitude: "Longitude",
  dms_latitude: "DMS Latitude",
  dms_longitude: "DMS Longitude",
  key_no: "Key Number",
  vendor: "Vendor",
  depth: "Depth of Well (mbgl)",
  measuring_point: "Measuring Point (magl)",
  diameter: "Diameter of well (m)",
  curbing_depth: "Curbing Depth (mbgl)",
  use_of_well: "Use of Well",
  
};

const fieldsDugWell = [
  "state",
  "district_name", 
  "firka",
  "village",
  "ex_well_id",
  "well_type",
  "status",
  "location_detail",
  "latitude",
  "longitude",
  "dms_latitude",
  "dms_longitude",
  "depth",
  "diameter",
  "measuring_point",
  "curbing_depth",
  "use_of_well",
];

const fieldsPiezometer = [
  "state",
  "district_name", 
  "firka",
  "village",
  "ex_well_id",
  "well_type",
  "status",
  "location_detail",
  "latitude",
  "longitude",
  "dms_latitude",
  "dms_longitude",
  "key_no",
  "vendor",
  "depth",
  "measuring_point",
  "diameter",
];

const Download_Type = ({ type, data }) => {
  const [downloadModal, setDownloadModal] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);

  useEffect(() => {
    if (type?.toLowerCase() === "dug well") {
      setAvailableFields(fieldsDugWell);
      setSelectedFields(fieldsDugWell);
    } else if (type?.toLowerCase() === "piezometer") {
      setAvailableFields(fieldsPiezometer);
      setSelectedFields(fieldsPiezometer);
    }
  }, [type]);

  const handleFieldToggle = (field) => {
    setSelectedFields((prev) =>
      prev.includes(field) ? prev.filter((f) => f !== field) : [...prev, field]
    );
  };

  // Sort selectedFields based on the fieldNames order
  const sortedSelectedFields = selectedFields.sort((a, b) => {
    return Object.keys(fieldNames).indexOf(a) - Object.keys(fieldNames).indexOf(b);
  });

  const handleDownloadPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const pageWidth = doc.internal.pageSize.width;
    doc.setFontSize(10);
    const heading = `District-${data[0]?.district_name} / Well Type-${data[0]?.well_type}`;
    const headingWidth = doc.getTextWidth(heading);
    const headingX = (pageWidth - headingWidth) / 2;
    doc.text(heading, headingX, 7);
    const tableHead = ["No.", ...sortedSelectedFields.map((field) => fieldNames[field] || field)];
    const tableData = data?.map((well, index) => [
      index + 1, // Serial Number
      ...sortedSelectedFields.map((field) => well[field] || "-"),
    ]);
    autoTable(doc, {
      head: [tableHead],
      body: tableData,
      startY: 10,
      theme: "grid",
      headStyles: { textColor: [255, 255, 255], fontSize: 6, halign: "center", fillColor: [45, 128, 185] },
      bodyStyles: { fontSize: 5 },
    });
    doc.save(`${data[0]?.district_name?.toLowerCase()}-${data[0]?.well_type.toLowerCase()}-data.pdf`);
    setDownloadModal(null);
  };

// const handleDownloadExcel = () => {
//   const formattedData = data.map((well, i) => {
//     let filteredData = { "No.": i + 1 };
//     sortedSelectedFields.forEach((field) => {
//       filteredData[fieldNames[field] || field] = well[field] || "-";
//     });
//     return filteredData;
//   });

//   // Calculate the maximum length for each column and adjust the width
//   const columnWidths = sortedSelectedFields.map((field) => {
//     const maxLength = Math.max(
//       (fieldNames[field]?.length || field.length), // Length of the field name
//       ...formattedData.map((row) => (row[fieldNames[field] || field]?.toString().length || 0)) // Length of the values
//     );
//     return { wpx: (maxLength + 2) * 8 }; // Add some padding and multiply by 8px per character
//   });

//   const worksheet = XLSX.utils.json_to_sheet(formattedData);
//   worksheet['!cols'] = columnWidths; // Apply the column width adjustment

//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");
//   XLSX.writeFile(workbook, `${data[0].district_name}-${data[0].well_type}-Well Profile-data.xlsx`);
//   setDownloadModal(null);
// };

const handleDownloadExcel = () => {
  if (!data || data.length === 0) {
    alert("No data available for download.");
    return;
  }

  const formattedData = data.map((well, i) => {
    let filteredData = { "No.": i + 1 };
    sortedSelectedFields.forEach((field) => {
      filteredData[fieldNames[field] || field] = well[field] || "-";
    });
    return filteredData;
  });

  // Calculate the maximum length for each column and adjust the width
  const columnWidths = sortedSelectedFields.map((field) => {
    const maxLength = Math.max(
      (fieldNames[field]?.length || field.length), // Length of the field name
      ...formattedData.map((row) => (row[fieldNames[field] || field]?.toString().length || 0)) // Length of the values
    );
    return { wpx: (maxLength + 2) * 8 }; // Add some padding and multiply by 8px per character
  });

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  worksheet['!cols'] = columnWidths; // Apply the column width adjustment

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");
  XLSX.writeFile(workbook, `${data[0]?.district_name || "Unknown District"}-${data[0]?.well_type || "Unknown Well Type"}-Well Profile-data.xlsx`);
  setDownloadModal(null);
};
 

return (
    <>
      <div className="flex items-center justify-center gap-10">
        <div onClick={() => setDownloadModal("pdf")} className="cursor-pointer flex items-center gap-2">
          <h1 className="text-[13px] font-bold text-buttonPrimary">Download as PDF</h1>
          <img className="w-3" src={download} alt="Download PDF" />
        </div>
        <div onClick={() => setDownloadModal("excel")} className="cursor-pointer flex items-center gap-2">
          <h1 className="text-[13px] font-bold text-dullRed">Download as Excel</h1>
          <img className="w-3" src={download_1} alt="Download Excel" />
        </div>
      </div>

      {downloadModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[35%] h-[80vh]">
            <h2 className="text-lg font-bold mb-2">
              Select Fields for Download ({downloadModal === "pdf" ? "PDF" : "Excel"})
            </h2>
            <h1 className="text-lg font-bold mb-4">Well Type: {type}</h1>
            <div style={{ maxWidth: "600px" }} className="max-h-70 overflow-auto grid grid-cols-2 gap-x-4">
              {availableFields.map((field) => (
                <label key={field} className="flex items-center gap-2 mb-2">
                  <input
                    type="checkbox"
                    checked={selectedFields.includes(field)}
                    onChange={() => handleFieldToggle(field)}
                  />
                  {fieldNames[field] || field}
                </label>
              ))}
            </div>

            <div className="flex justify-end gap-4 mt-4">
              <button onClick={() => setDownloadModal(null)} className="px-4 py-2 bg-gray-300 rounded">Cancel</button>
              <button
                onClick={downloadModal === "pdf" ? handleDownloadPDF : handleDownloadExcel}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Download_Type;
