import React, { useContext, useState } from "react";
import EmployeeList from "../components/EmployeeList";
import { IoAddOutline } from "react-icons/io5";
import CreateUser from "../components/CreateUser";
import { EmpManagementContext } from "../context/EmpManagementContext";
import { IoSearchOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { getUsers } from "../redux/usersSlice";
import UpdateSuccessModal from "../components/UpdateSuccessModal";

const EmployeeManagement = () => {
  const { createUser, setCreateUser, setEditUserDetails, fetchUsers } =
    useContext(EmpManagementContext);
  const [open, setOpen] = useState();
  const users = useSelector(getUsers);

  const [searchTerms, setsearchTerms] = useState("");
  const empStats = [
    {
      label: "Total Employees",
      value: users?.length,
    },
    {
      label: "Active Employees",
      value: users?.filter((item) => item?.user_status === "Active")?.length,
    },
    {
      label: "Inactive Employees",
      value: users?.filter((item) => item?.user_status === "Inactive")?.length,
    },
  ];

  return (
    <>
      <div className="w-[90%] mx-auto">
        <div className="font-poppins">
          <h2 className="my-2 font-bold text-blackSubDark text-lg">
            Employee management
          </h2>
          <h3 className="text-blackSub text-[13px]">
            Manage employee details.
          </h3>
        </div>
        {createUser && (
          <div className="">
            <CreateUser setOpen={setOpen} />
          </div>
        )}
        <div className="flex items-center my-4  justify-between">
          <div className="my-3 bg-white flex items-center w-[380px] h-[40px] border border-gray-300 rounded-lg px-4 justify-start gap-5 font-roboto shadow-md">
            <IoSearchOutline className="text-textGrey" />
            <input
              type="text"
              placeholder="Search by Employee Name or Id"
              className="focus:outline-none w-[290px] px-2 rounded-[4px] mr-2  text-textGrey text-[14px] font-roboto"
              onChange={(e) => setsearchTerms(e.target.value)}
            />
          </div>
          <div className="bg-white w-[50%] font-poppins rounded-lg shadow-md p-3 flex items-center justify-between">
            {empStats?.map((item, i) => (
              <div className="flex flex-col items-center gap-2" key={i}>
                <h2 className="font-medium">{item?.label}</h2>
                <p className="text-sm">{item?.value}</p>
              </div>
            ))}
          </div>
          <div className="font-roboto flex items-center gap-2 text-blackSub font-semibold">
            Create User{" "}
            <span
              onClick={() => {
                setCreateUser(true);
                setEditUserDetails(null);
              }}
              className="text-[#23A55A] bg-white text-2xl p-[6px] rounded-lg shadow-md cursor-pointer"
            >
              <IoAddOutline />
            </span>
          </div>
        </div>
        <EmployeeList open={open} setOpen={setOpen} searchTerms={searchTerms} />
      </div>
      {open === "User Created" && (
        <UpdateSuccessModal
          modal={open === "User Created" && true}
          onSuccessFunction={fetchUsers}
          message={"User created successfully."}
          closeModal={() => setOpen()}
        />
      )}
      {open === "Update Details" && (
        <UpdateSuccessModal
          modal={open === "Update Details" && true}
          onSuccessFunction={fetchUsers}
          message={"User details updated successfully."}
          closeModal={() => setOpen()}
        />
      )}
    </>
  );
};

export default EmployeeManagement;
