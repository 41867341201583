import React, { useRef, useState } from "react";
import cloudIcon from "../assets/upload.svg";
import * as xlsx from "xlsx";
import axiosInstance from "../interceptor/axiosInstance";

const toSnakeCase = (str) => {
  return str
    .replace(/\s+/g, "_") // Replace spaces with underscores
    .replace(/([a-z])([A-Z])/g, "$1_$2") // Add underscore between lower and uppercase letters
    .toLowerCase(); // Convert to lowercase
};

const UploadSurveySamples = ({
  selectedSession,
  sampleCount,
  openSampleUploaded,
  close,
}) => {
  const [msg, setMsg] = useState("");
  const requiredKeys = [
    "th_value",
    "ca_value",
    "mg_value",
    "na_value",
    "k_value",
    "hco3_value",
    "cl_value",
    "so4_value",
    "no3_value",
    "f_value",
    "u_value",
    "co3_value",
    "ex_well_id",
  ];
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const readUploadFile = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      if (
        !file.type.includes("excel") &&
        !file.name.endsWith(".xls") &&
        !file.name.endsWith(".xlsx")
      ) {
        alert("Please upload a valid Excel file (.xls or .xlsx)");
        return;
      }
      setMsg("Your file is being processed...");
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result); // e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { header: 1 }); // Get rows as arrays
        const headers = json[0].map((header) =>
          toSnakeCase(header.toString().trim())
        ); // First row as headers

        const rowCount = json?.length - 1;

        if (rowCount !== sampleCount) {
          setMsg(
            `The file should contain exactly ${sampleCount} samples data, but it contains ${rowCount}.`
          );
          fileInputRef.current.value = "";
          return;
        }

        // Check for missing required keys
        const missingKeys = requiredKeys.filter(
          (key) => !headers.includes(key)
        );
        if (missingKeys.length > 0) {
          setMsg(
            `The following required data are missing: ${missingKeys.join(", ")}`
          );
          fileInputRef.current.value = "";
          return; // Prevent further processing
        }

        // Check for unwanted keys
        // const unwantedKeys = headers.filter(
        //   (header) => !requiredKeys.includes(header)
        // );
        // if (unwantedKeys.length > 0) {
        //   setMsg(
        //     `The following fields are unwanted and should be removed: ${unwantedKeys.join(
        //       ", "
        //     )}`
        //   );
        //   fileInputRef.current.value = "";
        //   return; // Prevent further processing
        // }
        const result = json
          .slice(1)
          .filter((row) => {
            return row.some(
              (cell) => cell !== undefined && cell !== null && cell !== ""
            );
          })
          .map((row) => {
            const obj = {};
            requiredKeys.forEach((key) => {
              const headerIndex = headers.indexOf(key);
              if (headerIndex !== -1 && row[headerIndex] !== undefined) {
                const trimmedValue = row[headerIndex]
                  ? row[headerIndex].toString().trim()
                  : null;
                obj[key] = trimmedValue === undefined ? null : trimmedValue;
              } else {
                obj[key] = null;
              }
            });
            return obj;
          });

        const postData = {
          sample_data: result,
          start_date: selectedSession.session_start_date,
          end_date: selectedSession.session_end_date,
        };

        try {
          setMsg("Your file is being uploaded...");
          const response = await axiosInstance.put(
            "/api/well/sample/update",
            postData
          );
          setMsg("Worksheet uploaded successfully!");
          close();
          openSampleUploaded();
        } catch (error) {
          setMsg("Server Error ", error.response.data.message);
          fileInputRef.current.value = "";
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const fileInputRef = useRef(null);
  return (
    <div>
      <div onClick={handleUploadClick} className="flex justify-center">
        <button className="px-6 text-black flex items-center  text-md py-2 rounded-lg font-semibold transition bg-[#009DE3] duration-300 ease-in-out transform ">
          <img src={cloudIcon} alt="upload_icon" className="w-6 mr-2" />
          Choose File
        </button>
      </div>

      <input
        ref={fileInputRef}
        className="text-blackSubDark font-roboto text-sm hidden"
        type="file"
        name="upload"
        id="upload"
        accept=".xls,.xlsx"
        onChange={readUploadFile}
      />
      {msg ? (
        <main>
          <p
            className={`font-roboto text-center p-3 ${
              msg.includes("successfully") ||
              msg.includes("being uploaded") ||
              msg.includes("being processed")
                ? "text-successGreen"
                : "text-dullRed"
            } font-semibold `}
          >
            {msg}
          </p>
        </main>
      ) : null}
    </div>
  );
};

export default UploadSurveySamples;
