// export const formatDateToShow = (date) => {
//   if (!date) return;
//   const formattedDate = new Date(date)?.toLocaleDateString("en-GB", {
//     day: "2-digit",
//     month: "short",
//     year: "numeric",
//   });
//   return formattedDate;
// };

// export const formatDate = (date) => {
//   if (!date) return;
//   const formattedDate = new Date(date);
//   return formattedDate;
// };
export const formatDateToShow = (date) => {
  if (!date) return;  // If no date, return "-" to avoid empty values
  
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0'); // Ensure two-digit day (e.g., "01", "13")
  const month = d.toLocaleString('default', { month: 'short' }); // Get the month abbreviation (e.g., "Jan", "Feb")
  const year = d.getFullYear(); // Get the year (e.g., 2025)

  return `${day}-${month}-${year}`; // Format as "day-month-year" (e.g., "13-Feb-2025")
};
