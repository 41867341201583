import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getSelectedWell, toggleWell } from "../redux/assignmentSlice";

const AssgWellListCard = ({
  item,
  setShowWellDetail,
  setselectedWell,
  showWellDetail,
  buttons,
  checkbox,
  cardNumber
}) => {
  const { districtName } = useParams();
  const selectedWells = useSelector(getSelectedWell);
  const location = useLocation();
  const dispatch = useDispatch();
  const handleCheckboxChange = () => {
    dispatch(toggleWell(item?.well_id));
  };
  const isEmployeeAssignmentsRoute = location.pathname.includes(
    "employee-assignments"
  );
  return (
    <div
      onClick={
        isEmployeeAssignmentsRoute ? null : () => setShowWellDetail(item)
      }
      className={`${
        showWellDetail?.well_id === item?.well_id ? "bg-lightBlue" : "bg-white"
      } flex items-center rounded-lg py-4 cursor-pointer`}
    >
      <div className="w-[4%]  flex-col  mx-1 text-center ">
        <span className="font-bold text-xs">{cardNumber}</span>
      </div>
      <div className="w-[1px] bg-textGrey h-16"></div>
      <div className="w-[55%] px-4">
        <h4 className="text-blackSubDark font-poppins font-semibold text-[14px]">
          Village : {item?.village}
        </h4><h4 className="text-blackSubDark font-poppins font-semibold text-[14px]">
          Well Id : {item?.ex_well_id} <span className="mx-2">|</span>
          <span
            className={`${
              item?.status === "Active" ? "text-successGreen" : "text-errorRed"
            } font-normal`}
          >
            {item?.status}
          </span>
        </h4>
        
        <p className="text-textGrey font-roboto text-[12px]">
          {item?.location_detail}
        </p>
      </div>
      <div className="w-[1px] bg-textGrey h-16"></div>
      <div className="w-[20%] px-4 text-xs">
        <p className="font-roboto text-textGrey">Well Type</p>
        <p className="font-poppins text-blackSubDark font-bold">
          {item?.well_type}
        </p>
      </div>
      <div className="w-[1px] bg-textGrey h-16"></div>
      {/* {buttons === "true" && (
        <div className="w-[20%] px-5 flex flex-col justify-center gap-2">
          <button
            onClick={() => setselectedWell(item)}
            className="bg-buttonPrimary text-white py-1 font-poppins font-medium rounded-[6px] text-[10px]"
          >
            View Details
          </button>
          <Link
            className="bg-buttonSecondary text-white py-1 font-poppins font-medium rounded-[6px] text-[10px] text-center"
            to={`/selected-well/${item?.district_id}/${item?.well_id}`}
          >
            <button>View History</button>
          </Link>
        </div>
      )} */}
      {checkbox === "true" && (
        <div className="w-[20%] px-5 flex flex-col justify-center items-center gap-2">
          <input
            checked={selectedWells.includes(item?.well_id)}
            onChange={handleCheckboxChange}
            type="checkbox"
            className="w-5 h-5"
          />
        </div>
      )}
    </div>
  );
};

export default AssgWellListCard;
