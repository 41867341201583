
import React, { useState } from "react";
import chevronDown from "../assets/chevronDown.png";
import { FaChevronDown } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../redux/stateSlice";

const states = ["Tamil Nadu", "Puducherry"];

const LocationPicker = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const stateName = useSelector((state) => state.state.stateName);
  const dispatch = useDispatch();

  const handleStateChange = (state) => {
    dispatch(setState(state));
    setShowDropDown(false);
  };

  return (
    <div className="relative">
      <h2
        onClick={() => setShowDropDown(!showDropDown)}
        className="font-bold text-blackSubDark text-lg cursor-pointer"
      >
        <span className="text-sm inline-block mr-2">
          <FaChevronDown />
        </span>
        {stateName}
      </h2>

      <div style={{borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px"}}
        className={`${
          showDropDown ? "top-7 z-20" : "-top-32 -z-20"
        } absolute transition-all duration-300  w-full bg-white text-sm flex flex-col items-center text-center `}
      >
        {states?.map((state, i) => (
          <div style={{borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px"}}
            onClick={() => handleStateChange(state)}
            className={`${
              stateName === state ? "bg-skyBlue text-white" : ""
            } cursor-pointer w-full transition-all hover:bg-lightGrey hover:text-white`}
            key={i}
          >
            {state}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocationPicker;
