import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { RiAiGenerate } from "react-icons/ri";
import Resizer from "react-image-file-resizer";
import plus from "../assets/plus.png";
import axiosInstance from "../interceptor/axiosInstance";
import { dataURLtoBlob } from "../utils/dataUrlToBlob";
import { toDMS } from "../utils/generateId";

const NewWellForm = ({ openSuccessModal, closeModal, selectedSession }) => {
  const [formData, setFormData] = useState({
    well_status: "Active",
    well_type: "",
    state: "",
    district: "",
    employee: "",
    ex_well_id: "",
    emp_id: "",
    firka: "",
    village: "",
    location_detail: "",
    latitude: "",
    longitude: "",
    depth_of_well: "",
    measuring_point: "",
    diameter: "",
    use_of_well: "",
    curbing_depth: "",
    vendor: "",
    key_no: "",
    well_image: [],
    dms_latitude: "",
    dms_longitude: "",
  });
  const [dropDown, setDropDown] = useState();
  const [emps, setEmps] = useState();
  const [empsData, setEmpsData] = useState();
  const [dists, setDists] = useState();
  const [distsData, setDistsData] = useState();
  const [showAlertOnGenerate, setShowAlertOnGenerate] = useState(false);
  const [othersInput, setOthersInput] = useState([]);
  const [showProgress, setShowProgress] = useState();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [updatedModal, setUpdatedModal] = useState(false);

  const getDistricts = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/district/get/data/${formData?.state}`
      );
      const dists = response?.data?.Data;
      setDists(dists);
      setDistsData(dists);
    } catch (err) {
      console.log(err);
    }
  };

  const getEmployees = async () => {
    const response = await axiosInstance.get(`api/employee/all/users/data`);
    const users = response?.data?.Data;
    const activeUsers = users?.filter((user) => user?.user_status === "Active");
    setEmps(activeUsers);
    setEmpsData(activeUsers);
  };

  useEffect(() => {
    if (formData?.state) {
      getDistricts();
    }
    setFormData((prev) => ({
      ...prev,
      district: "",
    }));
  }, [formData?.state]);

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      vendor: "",
      key_no: "",
      curbing_depth: "",
    }));
  }, [formData.well_type]);

  const commonFormItems = [
    {
      label: "Well Type",
      type: "dropdown",
      options: ["Dug Well", "Piezometer"],
      key: "well_type",
    },
    {
      label: "State",
      type: "dropdown",
      options: ["Tamil Nadu", "Puducherry"], // Ideally, populate these dynamically
      key: "state",
    },
    {
      label: "District",
      type: "dropdown",
      options: distsData, // Populate based on state selection
      key: "district",
    },
    {
      label: "Employee",
      type: "dropdown",
      options: empsData, // Populate with employee list
      key: "employee",
    },
    {
      label: "Firka",
      type: "text",
      key: "firka",
    },
    {
      label: "Village",
      type: "text",
      key: "village",
    },
    {
      label: "Location Detail",
      type: "text",
      key: "location_detail",
    },
    {
      label: "Latitude",
      type: "number",
      key: "latitude",
    },
    {
      label: "Longitude",
      type: "number",
      key: "longitude",
    },
    {
      label: "Well Id",
      type: "text",
      key: "ex_well_id",
    },
    {
      label: "DMS Latitude",
      type: "text",
      key: "dms_latitude",
      disable: true,
    },
    {
      label: "DMS Longitude",
      type: "text",
      key: "dms_longitude",
      disable: true,
    },
    {
      label: "Depth of Well (mbgl)",
      type: "number",
      key: "depth_of_well",
    },
    {
      label: "Measuring Point (magl)",
      type: "number",
      key: "measuring_point",
    },
    {
      label: "Diameter of Well (m)",
      type: "number",
      key: "diameter",
    },
  ];

  const dugWellFormItems = [
    {
      label: "Curbing Depth (mbgl)",
      type: "number",
      key: "curbing_depth",
    },
    {
      label: "Use of Well",
      type: "dropdown",
      options: ["Domestic", "Irrigation", "Industrial", "Others", "Not in use"],
      key: "use_of_well",
    },
  ];

  const piezoFormItems = [
    {
      label: "Vendor",
      type: "dropdown",
      options: ["Aaxis", "Encardia", "Swan", "Others"],
      key: "vendor",
    },
    {
      label: "Key No",
      type: "text",
      key: "key_no",
    },
  ];

  const currentDist = dists?.find(
    (dt) => dt?.district_name === formData?.district
  );

  const generateWellId = async (lat, long) => {
    if (
      !formData?.latitude ||
      !formData?.longitude ||
      !formData?.district ||
      formData?.ex_well_id
    ) {
      return;
    }

    const { fullCompactDMS, latitudeDMS, longitudeDMS } = toDMS(lat, long);
    try {
      const response = await axiosInstance.post(
        "/api/well/check/wellId/exist",
        {
          ex_well_id: fullCompactDMS,
        }
      );
      const actual_well_id = response?.data?.Data;
      setFormData((prev) => ({
        ...prev,
        ex_well_id: actual_well_id,
        dms_latitude: latitudeDMS,
        dms_longitude: longitudeDMS,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ex_well_id: "",
    }));
  }, [formData?.latitude, formData?.longitude]);

  const handleMouseEnterOnGenerateWellId = () => {
    if (!formData?.latitude || !formData?.longitude || !formData?.district) {
      setShowAlertOnGenerate(true);
    }
  };
  const handleMouseLeaveOnGenerateWellId = () => {
    if (!formData?.latitude || !formData?.longitude || !formData?.district) {
      setShowAlertOnGenerate(false);
    }
  };

  const handleDropDownChange = (key, option) => {
    if (option === "Others") {
      !othersInput.includes(key) && setOthersInput((prev) => [...prev, key]);
    } else {
      setOthersInput((prev) => prev.filter((item) => item != key));
    }
    setFormData((prev) => ({
      ...prev,
      [key]: option,
    }));
    setDropDown("");
  };

  useEffect(() => {
    setEmpsData(emps);
    setDistsData(dists);
    setSearchInputValue("");
  }, [dropDown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOthersInputChange = (e, key) => {
    setFormData((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const handleImageChange = async (e) => {
    const { files } = e.target;

    const fileList = files ? [...files] : [];
    if (fileList.length > 0 && fileList.length <= 2) {
      const resizedFiles = [];
      for (const file of fileList) {
        try {
          const resizedFile = await new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
              file,
              800,
              800,
              "JPEG",
              80,
              0,
              (uri) => {
                const resizedBlob = dataURLtoBlob(uri);
                const resizedFile = new File([resizedBlob], file.name, {
                  type: "image/jpeg",
                });
                resolve(resizedFile);
              },
              (err) => reject(err)
            );
          });

          resizedFiles.push(resizedFile);
        } catch (error) {
          console.error("Error resizing image:", error);
        }
      }

      if (resizedFiles.length > 0) {
        const formDataToUpload = new FormData();
        resizedFiles.forEach((file) => formDataToUpload.append("image", file));

        try {
          setShowProgress("image upload");
          const response = await axiosInstance.post(
            "/api/employee/imageUpload",
            formDataToUpload
          );

          if (response.status === 200) {
            setFormData((prev) => ({
              ...prev,
              well_image: [...prev.well_image, ...response.data],
            }));
          } else {
            console.error("Error uploading images:", response.data.message);
          }
        } catch (error) {
          console.error("Error uploading images:", error);
        } finally {
          setShowProgress("");
        }
      }
    }
  };

  const handleRemoveImg = (url) => {
    setFormData((prev) => ({
      ...prev,
      well_image: prev?.well_image?.filter((imgUrl) => imgUrl !== url),
    }));
  };

  const handleDropDownSearch = (item, e) => {
    const value = e.target.value;
    setSearchInputValue(value);
    if (item?.label === "Employee") {
      const filteredEmps = emps?.filter((emp) =>
        emp?.name?.toLowerCase()?.includes(value)
      );
      setEmpsData(filteredEmps);
    }
    if (item?.label === "District") {
      const filteredDists = dists?.filter((dt) =>
        dt?.district_name?.toLowerCase()?.includes(value)
      );
      setDistsData(filteredDists);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      Data: [
        {
          ...formData,
          session_start_date: selectedSession?.session_start_date,
        },
      ],
    };

    try {
      const response = await axiosInstance.post("/api/well/creation", body);
      console.log(response?.data);
      closeModal();
      openSuccessModal();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {commonFormItems.map((item, i) => (
        <div key={i}>
          <p className="text-[13px] text-blackSubDark font-medium mt-2 mb-1">
            {item.label}
          </p>
          {item.type === "dropdown" ? (
            <div className="relative">
              <div
                onClick={() =>
                  !dropDown ? setDropDown(item?.label) : setDropDown("")
                }
                className="w-full bg-softGrey py-2 px-4 rounded-lg mt-2 outline-none cursor-pointer text-sm flex items-center justify-between"
              >
                {dropDown === item.label ? (
                  <div onClick={(e) => e.stopPropagation()}>
                    <input
                      placeholder={`Search ${item?.label}`}
                      value={searchInputValue}
                      onChange={(e) => handleDropDownSearch(item, e)}
                      className="focus:outline-none bg-inherit w-full"
                    />
                  </div>
                ) : (
                  <span
                    className={`${
                      !formData[item?.key] && "text-textGrey"
                    } capitalize`}
                  >
                    {formData[item?.key]
                      ? formData[item?.key]
                      : `Choose ${item?.label}`}
                  </span>
                )}
                <IoIosArrowDown />
              </div>
              {othersInput.includes(item?.key) && (
                <input
                  onChange={(e) => handleOthersInputChange(e, item?.key)}
                  type="text"
                  className="w-full border shadow-sm  border-softGrey outline-none my-2 py-2 px-4 text-sm rounded-lg"
                />
              )}

              {dropDown === item.label && (
                <div className="flex flex-col items-start border border-softGrey bg-white shadow-lg rounded-lg absolute top-10 z-20 w-full cursor-pointer text-sm max-h-60 overflow-y-auto custom-scrollbar">
                  {item?.label === "District"
                    ? item?.options?.map((option, i) => (
                        <div
                          onClick={() => {
                            setFormData((prev) => ({
                              ...prev,
                              [item?.key]: option?.district_name,
                            }));
                            setDropDown("");
                          }}
                          className={`${
                            formData[item?.key] === option?.district_name
                              ? "bg-buttonPrimary text-white hover:bg-buttonPrimary"
                              : "hover:bg-lightBlue"
                          } w-full py-1 px-3`}
                          key={i}
                        >
                          {option?.district_name}
                        </div>
                      ))
                    : item?.label === "Employee"
                    ? item?.options?.map((option, i) => (
                        <div
                          onClick={() => {
                            setFormData((prev) => ({
                              ...prev,
                              [item?.key]: option?.name,
                              emp_id: option?.emp_id,
                            }));
                            setDropDown("");
                          }}
                          className={`${
                            formData[item?.key] === option?.name
                              ? "bg-buttonPrimary text-white hover:bg-buttonPrimary"
                              : "hover:bg-lightBlue"
                          } w-full py-1 px-3 capitalize`}
                          key={i}
                        >
                          {option?.name} -{" "}
                          <span className="text-xs">({option?.emp_id})</span>
                        </div>
                      ))
                    : item?.options?.map((option, i) => (
                        <div
                          className={`${
                            formData[item?.key] === option
                              ? "bg-buttonPrimary text-white hover:bg-buttonPrimary"
                              : "hover:bg-lightBlue"
                          }  w-full py-1 px-3`}
                          key={i}
                          onClick={() => {
                            handleDropDownChange(item?.key, option);
                          }}
                        >
                          {option}
                        </div>
                      ))}
                </div>
              )}
            </div>
          ) : item?.label === "Well Id" ? (
            <div className="flex items-center relative justify-between w-full bg-softGrey py-1 px-4 rounded-lg mt-2 text-sm">
              <p className={!formData?.ex_well_id && "text-textGrey"}>
                {formData?.ex_well_id
                  ? formData?.ex_well_id
                  : "Generate Well Id"}
              </p>
              <div
                onMouseEnter={handleMouseEnterOnGenerateWellId}
                onMouseLeave={handleMouseLeaveOnGenerateWellId}
                onClick={() =>
                  generateWellId(formData?.latitude, formData?.longitude)
                }
                className={`text-base p-2 ${
                  !formData?.latitude ||
                  !formData?.longitude ||
                  !formData?.district
                    ? "cursor-default text-textGrey hover:bg-white"
                    : formData?.ex_well_id
                    ? "cursor-default text-textGrey"
                    : "cursor-pointer text-activeGreen bg-white "
                }  rounded-full  transition-colors duration-300`}
              >
                <RiAiGenerate />
              </div>
              <div
                className={`${
                  showAlertOnGenerate ? "opacity-100" : "opacity-0"
                } transition-opacity duration-300 bg-white rounded-md p-1 text-xs shadow-md absolute -bottom-7 text-errorRed right-0`}
              >
                Provide Lat, Long and District to generate well id.
              </div>
            </div>
          ) : (
            <input
              type={item.type}
              name={item.key}
              value={formData[item?.key]}
              onChange={handleChange}
              disabled={item?.disable && item?.disable === true}
              className="w-full bg-softGrey py-2 px-4 rounded-lg mt-2 outline-none text-sm"
              required
            />
          )}
        </div>
      ))}

      {/* Conditional rendering based on Well Type */}
      {formData.well_type === "Dug Well" &&
        dugWellFormItems.map((item, i) => (
          <div key={i}>
            <p className="text-[13px] text-blackSubDark font-medium mt-2 mb-1">
              {item.label}
            </p>
            {item.type === "dropdown" ? (
              <div className="relative">
                <div
                  onClick={() =>
                    !dropDown ? setDropDown(item?.label) : setDropDown("")
                  }
                  className="w-full bg-softGrey py-2 px-4 rounded-lg mt-2 outline-none cursor-pointer text-sm flex items-center justify-between"
                >
                  <span
                    className={`${!formData[item?.key] && "text-textGrey"}`}
                  >
                    {formData[item?.key]
                      ? formData[item?.key]
                      : `Choose ${item?.label}`}
                  </span>
                  <IoIosArrowDown />
                </div>
                {othersInput.includes(item?.key) && (
                  <input
                    onChange={(e) => handleOthersInputChange(e, item?.key)}
                    type="text"
                    className="w-full border shadow-sm  border-softGrey outline-none my-2 py-2 px-4 text-sm rounded-lg"
                  />
                )}
                {dropDown === item.label && (
                  <div className="flex flex-col items-start border border-softGrey bg-white shadow-lg rounded-lg absolute top-10 z-20 w-full cursor-pointer text-sm max-h-60 overflow-y-auto custom-scrollbar">
                    {item?.options?.map((option, i) => (
                      <div
                        className={`${
                          formData[item?.key] === option
                            ? "bg-buttonPrimary text-white hover:bg-buttonPrimary"
                            : "hover:bg-lightBlue"
                        }  w-full py-1 px-3`}
                        key={i}
                        onClick={() => {
                          handleDropDownChange(item?.key, option);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <input
                type={item.type}
                name={item.key}
                value={formData[item.key]}
                onChange={handleChange}
                className="w-full bg-softGrey py-2 px-4 rounded-lg mt-2 outline-none text-sm"
              />
            )}
          </div>
        ))}

      {formData.well_type === "Piezometer" &&
        piezoFormItems.map((item, i) => (
          <div key={i}>
            <p className="text-[13px] text-blackSubDark font-medium mt-2 mb-1">
              {item.label}
            </p>

            {item.type === "dropdown" ? (
              <div className="relative">
                <div
                  onClick={() =>
                    !dropDown ? setDropDown(item?.label) : setDropDown("")
                  }
                  className="w-full bg-softGrey py-2 px-4 rounded-lg mt-2 outline-none cursor-pointer text-sm flex items-center justify-between"
                >
                  <span
                    className={`${!formData[item?.key] && "text-textGrey"}`}
                  >
                    {formData[item?.key]
                      ? formData[item?.key]
                      : `Choose ${item?.label}`}
                  </span>
                  <IoIosArrowDown />
                </div>
                {othersInput.includes(item?.key) && (
                  <input
                    onChange={(e) => handleOthersInputChange(e, item?.key)}
                    type="text"
                    className="w-full border shadow-sm  border-softGrey outline-none my-2 py-2 px-4 text-sm rounded-lg"
                  />
                )}
                {dropDown === item.label && (
                  <div className="flex flex-col items-start border border-softGrey bg-white shadow-lg rounded-lg absolute top-10 z-20 w-full cursor-pointer text-sm max-h-60 overflow-y-auto custom-scrollbar">
                    {item?.options?.map((option, i) => (
                      <div
                        className={`${
                          formData[item?.key] === option
                            ? "bg-buttonPrimary text-white hover:bg-buttonPrimary"
                            : "hover:bg-lightBlue"
                        }  w-full py-1 px-3`}
                        key={i}
                        onClick={() => {
                          handleDropDownChange(item?.key, option);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <input
                type={item.type}
                name={item.key}
                value={formData[item.key]}
                onChange={handleChange}
                className="w-full bg-softGrey py-2 px-4 rounded-lg mt-2 outline-none text-sm"
              />
            )}
          </div>
        ))}

      <div>
        <p className="text-[13px] text-blackSubDark font-medium mt-2 mb-1">
          Well Photos
        </p>
        <div className="flex flex-col w-fit gap-2">
          <input
            id="upload_image"
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            className="hidden"
          />
          {formData?.well_image?.length < 2 ? (
            <label
              htmlFor="upload_image"
              className="bg-softGrey border-[0.7px] bg-[#009DE326] border-dotted border-black focus:border-blackSub rounded-lg px-5 py-3 cursor-pointer text-xs"
            >
              <div className="flex items-center gap-2">
                <img src={plus} alt="plus icon" />
                <div>
                  <p className="text-[10px] font-medium">
                    Take Photo or Choose from Gallery
                  </p>
                  <p className="font-light text-[10px]">
                    Supports: PNG, JPG, JPEG
                  </p>
                  <p className="font-medium text-dullRed text-[10px]">
                    Upload maximum of two images
                  </p>
                </div>
              </div>
            </label>
          ) : null}

          <div className="mt-2 flex flex-wrap items-center gap-2">
            {formData["well_image"]?.length > 0 && (
              <div className="flex gap-2 flex-wrap items-center">
                {formData["well_image"]?.map((fileUrl, idx) => (
                  <div
                    key={idx}
                    className="flex flex-col items-center relative"
                  >
                    <img
                      src={fileUrl}
                      alt={`Preview ${idx + 1}`}
                      className="w-32 h-32 object-cover rounded-md mb-2"
                    />
                    <IoClose
                      onClick={() => handleRemoveImg(fileUrl)}
                      className="absolute top-[3px] right-[3px] bg-white bg-opacity-30 rounded-full text-dullRed text-lg cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            )}
            {showProgress === "image upload" && (
              <div className="p-4">
                <CircularProgress size={20} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4">
        <button
          type="submit"
          className="bg-blue-500 text-white px-6 py-2 rounded-lg"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default NewWellForm;
