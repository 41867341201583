import React, { useContext, useState, useEffect } from "react";
import { GiCancel } from "react-icons/gi";
import { DistrictsContext } from "../context/DistrictsContext";
import axiosInstance from "../interceptor/axiosInstance";
import { CircularProgress, IconButton } from "@mui/material";
import { RiAiGenerate } from "react-icons/ri";
import { toDMS } from "../utils/generateId";
const EditFormModal = ({ district_id }) => {
  const { editFormModal, setEditFormModal, setUpdatedModal } =
    useContext(DistrictsContext);
  const [showOthersInput, setShowOthersInput] = useState(false);
  const [circularProgress, setCircularProgress] = useState();
  const [showAlertOnGenerate, setShowAlertOnGenerate] = useState(false);
  const [updatedDetails, setUpdatedDetails] = useState(
    editFormModal?.well_type.toLowerCase().includes("dug well")
      ? {
          well_type: 0,
          well_id: editFormModal?.well_id,
          ex_well_id: editFormModal?.ex_well_id,
          firka: editFormModal?.firka,
          village: editFormModal?.village,
          location_detail: editFormModal?.location_detail,
          latitude: editFormModal?.latitude,
          longitude: editFormModal?.longitude,
          dms_latitude: editFormModal?.dms_latitude,
          dms_longitude: editFormModal?.dms_longitude,
          depth: editFormModal?.depth,
          diameter: editFormModal?.diameter,
          mp: editFormModal?.measuring_point,
          use_of_well: editFormModal?.use_of_well,
          curbing_depth: editFormModal?.curbing_depth,
        }
      : {
          well_type: 1,
          well_id: editFormModal?.well_id,
          ex_well_id: editFormModal?.ex_well_id,
          firka: editFormModal?.firka,
          village: editFormModal?.village,
          location_detail: editFormModal?.location_detail,
          latitude: editFormModal?.latitude,
          longitude: editFormModal?.longitude,
          dms_latitude: editFormModal?.dms_latitude,
          dms_longitude: editFormModal?.dms_longitude,
          depth: editFormModal?.depth,
          diameter: editFormModal?.diameter,
          mp: editFormModal?.measuring_point,
          key_no: editFormModal?.key_no,
          vendor: editFormModal?.vendor,
        }
  );

  const dugWellFormItems = [
    {
      label: "Well Id",
      type: "text",
      disable: true,
      value: updatedDetails?.ex_well_id,
    },
    { label: "Village", type: "text", value: updatedDetails?.village },
    { label: "Firka", type: "text", value: updatedDetails?.firka },
    {
      label: "Location Detail",
      type: "text",
      value: updatedDetails?.location_detail,
    },
    { label: "Latitude", type: "number", value: updatedDetails?.latitude },
    { label: "Longitude", type: "number", value: updatedDetails?.longitude },
    {
      label: "DMS Latitude",
      type: "text",
      disable: true,
      value: updatedDetails?.dms_latitude,
    },
    {
      label: "DMS Longitude",
      type: "text",
      disable: true,
      value: updatedDetails?.dms_longitude,
    },
    {
      label: "Depth of Well (mbgl)",
      type: "number",
      value: updatedDetails?.depth,
    },
    {
      label: "Measuring Point (magl)",
      type: "number",
      value: updatedDetails?.mp,
    },
    {
      label: "Diameter of Well (m)",
      type: "number",
      value: updatedDetails?.diameter,
    },
    {
      label: "Curbing Depth (mbgl)",
      type: "number",
      value: updatedDetails?.curbing_depth,
    },
    {
      label: "Use of Well",
      type: "select",
      options: ["Domestic", "Irrigation", "Industrial", "Others", "Not in use"],
    },
  ];

  const piezometerFormItems = [
    {
      label: "Well Id",
      type: "text",
      disable: true,
      value: updatedDetails?.ex_well_id,
    },
    { label: "Village", type: "text", value: updatedDetails?.village },
    { label: "Firka", type: "text", value: updatedDetails?.firka },
    {
      label: "Location Detail",
      type: "text",
      value: updatedDetails?.location_detail,
    },
    { label: "Latitude", type: "number", value: updatedDetails?.latitude },
    { label: "Longitude", type: "number", value: updatedDetails?.longitude },
    {
      label: "DMS Latitude",
      type: "text",
      disable: true,
      value: updatedDetails?.dms_latitude,
    },
    {
      label: "DMS Longitude",
      type: "text",
      disable: true,
      value: updatedDetails?.dms_longitude,
    },
    { label: "Depth", type: "number", value: updatedDetails?.depth },
    {
      label: "Measuring Point (magl)",
      type: "number",
      value: updatedDetails?.mp || "",
    },
    {
      label: "Diameter of Well (m)",
      type: "number",
      value: updatedDetails?.diameter,
    },
    { label: "Key No", type: "number", value: updatedDetails?.key_no },
    {
      label: "Vendor",
      type: "select",
      options: ["Aaxis", "Encardia", "Swan", "Others"],
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "latitude" || name === "longitude") {
      setUpdatedDetails((prev) => ({
        ...prev,
        ex_well_id: "",
        dms_latitude: "",
        dms_longitude: "",
      }));
    }
    // setUpdatedDetails((prev) => ({
    //   ...prev,
    //   [name === "well_id"
    //     ? "ex_well_id"
    //     : name === "depth_of_well"
    //     ? "depth"
    //     : name === "diameter_of_well"
    //     ? "diameter"
    //     : name]: value,
    // }));
    setUpdatedDetails((prev) => ({
      ...prev,
      [name === "well_id"
        ? "ex_well_id"
        : name === "measuring_point_(magl)"
        ? "mp"
        : name === "depth_of_well_(mbgl)"
        ? "depth"
        : name === "diameter_of_well_(m)"
        ? "diameter"
        : name === "curbing_depth_(mbgl)"
        ? "curbing_depth"
        : name]: value,
    }));
  };

  const handleWellStatusUpdate = async () => {
    setCircularProgress("status update");

    try {
      const { well_id, status } = editFormModal;

      if (!well_id || !status) {
        throw new Error("Missing well_id or status");
      }

      const newStatus = status === "Active" ? "Closed" : "Active";
      const updateStatus = await axiosInstance.put("/api/well/status/update", {
        well_id: well_id,
        status: newStatus,
      });
    } catch (error) {
      console.error("Error updating well status:", error);
    } finally {
      setCircularProgress(null);
      setEditFormModal();
      setUpdatedModal(true);
    }
  };

  const handleUpdate = async () => {
    setCircularProgress("update");
    try {
      if (!updatedDetails) {
        throw new Error("Missing details");
      }
      const response = await axiosInstance.put(
        "/api/well/update/survey/detail",
        updatedDetails
      );
    } catch (error) {
      console.error("Error updating well details:", error);
    } finally {
      setCircularProgress(null);
      setEditFormModal();
      setUpdatedModal(true);
    }
  };

  const handleSelect = (key, option) => {
    const objectKey = key.toLowerCase().replace(/\s+/g, "_");
    option === "Others" && setShowOthersInput(true);
    setUpdatedDetails((prev) => ({
      ...prev,
      [objectKey]: option,
    }));
    option !== "Others" && setShowOthersInput(false);
  };

  useEffect(() => {
    if (editFormModal) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [editFormModal]);

  const convertToSnakeCase = (string) => {
    return string.toLowerCase().replace(/\s+/g, "_");
  };

  const generateWellId = async (lat, long) => {
    if (
      !updatedDetails?.latitude ||
      !updatedDetails?.longitude ||
      updatedDetails?.ex_well_id
    ) {
      return;
    }

    const { fullCompactDMS, latitudeDMS, longitudeDMS } = toDMS(lat, long);
    try {
      const response = await axiosInstance.post(
        "/api/well/check/wellId/exist",
        {
          ex_well_id: fullCompactDMS,
        }
      );
      const actual_well_id = response?.data?.Data;
      setUpdatedDetails((prev) => ({
        ...prev,
        ex_well_id: actual_well_id,
        dms_latitude: latitudeDMS,
        dms_longitude: longitudeDMS,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleMouseLeaveOnGenerateWellId = () => {
    if (!updatedDetails?.latitude || !updatedDetails?.longitude) {
      setShowAlertOnGenerate(false);
    }
  };

  const handleMouseEnterOnGenerateWellId = () => {
    if (!updatedDetails?.latitude || !updatedDetails?.longitude) {
      setShowAlertOnGenerate(true);
    }
  };

  useEffect(() => {
    const { use_of_well, vendor } = editFormModal;
    if (editFormModal?.well_type?.toLowerCase()?.trim()?.includes("dug well")) {
      if (
        ![
          "Domestic",
          "Irrigation",
          "Industrial",
          "Not in use",
          "Others",
        ].includes(use_of_well)
      ) {
        setShowOthersInput(true);
      } else {
        setShowOthersInput(false);
      }
    } else if (
      editFormModal?.well_type?.toLowerCase()?.trim()?.includes("piezometer")
    ) {
      if (!["Aaxis", "Encardia", "Swan", "Others"].includes(vendor)) {
        setShowOthersInput(true);
      } else {
        setShowOthersInput(false);
      }
    }
  }, [editFormModal]);

  return (
    <div
      className="fixed z-20 inset-0 element {
        box-shadow: rgba(0, 0, 0, 0.48) 6px 2px 16px 0px, rgba(0, 0, 0, 0.8) -6px -2px 16px 0px;
        }
        bg-black bg-opacity-50 flex items-center justify-center font-roboto"
    >
      <div className="bg-white w-[30%] relative h-[90vh] overflow-y-auto hide-scrollbar rounded-[16px] px-10 py-5 text-[13px]">
        <div className="flex items-center  mt-5 justify-center text-base font-bold text-buttonSecondary font-poppins">
          <h2 className="text-center">
            Edit Well ({editFormModal?.well_type}) Details
          </h2>

          <div
            onClick={() => setEditFormModal()}
            className="cursor-pointer hover:bg-black hover:bg-opacity-10 rounded-full p-2 absolute top-3 right-3"
          >
            <GiCancel className="text-xl" />
          </div>
        </div>
        <button
          onClick={handleWellStatusUpdate}
          className="px-6 py-2 my-4 rounded-lg bg-softGrey border-[0.7px] border-[#009DE3] w-fit text-left font-bold text-errorRed"
        >
          {circularProgress === "status update" ? (
            <CircularProgress color="error" size={17} />
          ) : editFormModal?.status === "Active" ? (
            "Deactivate"
          ) : (
            "Activate"
          )}
        </button>

        <div className="flex flex-col gap-1">
          {editFormModal?.well_type.toLowerCase().includes("dug well")
            ? dugWellFormItems.map((item, index) => (
                <div key={index}>
                  <p className="text-[11px] opacity-70 pt-2 ">{item.label}</p>
                  {item?.type === "select" ? (
                    <div className="flex items-center justify-start flex-wrap gap-3 cursor-pointer mt-2">
                      {item?.options?.map((option, i) => (
                        <div
                          onClick={() => handleSelect(item?.label, option)}
                          className={`${
                            updatedDetails?.use_of_well === option
                              ? "bg-buttonSecondary text-white"
                              : "bg-softGrey"
                          }  p-2 rounded-[10px] transition-colors duration-300`}
                          key={i}
                        >
                          {option}
                        </div>
                      ))}
                      {showOthersInput && (
                        <input
                          value={updatedDetails?.use_of_well || ""}
                          onChange={handleInputChange}
                          name={convertToSnakeCase(item?.label)}
                          className="focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full"
                        />
                      )}
                    </div>
                  ) : item?.label === "Well Id" ? (
                    <div className="flex items-center relative justify-between w-full bg-softGrey py-1 px-4 rounded-lg mt-2 text-sm">
                      <input
                        disabled={item?.disable}
                        onChange={handleInputChange}
                        name={convertToSnakeCase(item?.label)}
                        value={item?.value || ""}
                        type={item?.type}
                        className={`focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full`}
                      />
                      <div
                        onMouseEnter={handleMouseEnterOnGenerateWellId}
                        onMouseLeave={handleMouseLeaveOnGenerateWellId}
                        onClick={() =>
                          generateWellId(
                            updatedDetails?.latitude,
                            updatedDetails?.longitude
                          )
                        }
                        className={`text-base p-2 ${
                          !updatedDetails?.latitude ||
                          !updatedDetails?.longitude ||
                          updatedDetails?.ex_well_id
                            ? "cursor-default text-textGrey"
                            : "cursor-pointer hover:bg-white hover:text-buttonPrimary"
                        }  rounded-full  transition-colors duration-300`}
                      >
                        <RiAiGenerate />
                      </div>
                      <div
                        className={`${
                          showAlertOnGenerate ? "opacity-100" : "opacity-0"
                        } transition-opacity duration-300 bg-white rounded-md p-1 text-xs shadow-md absolute -bottom-7 text-errorRed right-0`}
                      >
                        Provide Lat and Long to generate well id.
                      </div>
                    </div>
                  ) : (
                    <input
                      disabled={item?.disable}
                      onChange={handleInputChange}
                      name={convertToSnakeCase(item?.label)}
                      value={item?.value || ""}
                      type={item?.type}
                      className={`focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full`}
                    />
                  )}
                </div>
              ))
            : piezometerFormItems.map((item, index) => (
                <div key={index}>
                  <p className="text-[11px] opacity-70 pt-2 ">{item.label}</p>
                  {item?.type === "select" ? (
                    <div className="flex items-center justify-start flex-wrap gap-3 cursor-pointer mt-2">
                      {item?.options?.map((option, i) => (
                        <div
                          onClick={() => handleSelect(item?.label, option)}
                          className={`${
                            updatedDetails?.vendor === option
                              ? "bg-buttonSecondary text-white"
                              : "bg-softGrey"
                          }  p-2 rounded-[10px] transition-colors duration-300`}
                          key={i}
                        >
                          {option}
                        </div>
                      ))}
                      {showOthersInput && (
                        <input
                          value={updatedDetails?.vendor || ""}
                          onChange={handleInputChange}
                          name={convertToSnakeCase(item?.label)}
                          className="focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full"
                        />
                      )}
                    </div>
                  ) : item?.label === "Well Id" ? (
                    <div className="flex items-center relative justify-between w-full bg-softGrey py-1 px-4 rounded-lg mt-2 text-sm">
                      <input
                        disabled={item?.disable}
                        onChange={handleInputChange}
                        name={convertToSnakeCase(item?.label)}
                        value={item?.value || ""}
                        type={item?.type}
                        className={`focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full`}
                      />
                      <div
                        onMouseEnter={handleMouseEnterOnGenerateWellId}
                        onMouseLeave={handleMouseLeaveOnGenerateWellId}
                        onClick={() =>
                          generateWellId(
                            updatedDetails?.latitude,
                            updatedDetails?.longitude
                          )
                        }
                        className={`text-base p-2 ${
                          !updatedDetails?.latitude ||
                          !updatedDetails?.longitude ||
                          updatedDetails?.ex_well_id
                            ? "cursor-default hover:bg-white hover:text-textGrey"
                            : "cursor-pointer hover:bg-white hover:text-buttonPrimary"
                        }  rounded-full  transition-colors duration-300`}
                      >
                        <RiAiGenerate />
                      </div>
                      <div
                        className={`${
                          showAlertOnGenerate ? "opacity-100" : "opacity-0"
                        } transition-opacity duration-300 bg-white rounded-md p-1 text-xs shadow-md absolute -bottom-7 text-errorRed right-0`}
                      >
                        Provide Lat and Long to generate well id.
                      </div>
                    </div>
                  ) : (
                    <input
                      disabled={item?.disable}
                      onChange={handleInputChange}
                      name={convertToSnakeCase(item?.label)}
                      value={item?.value || ""}
                      type={item?.type}
                      className="focus:outline-none bg-softGrey  focus:border-blackSub rounded-[10px] p-2 w-full"
                    />
                  )}
                </div>
              ))}
        </div>
        <div className="flex justify-end items-center">
          <button
            onClick={handleUpdate}
            className="my-5 bg-buttonPrimary text-white py-2 px-10 rounded-lg font-bold font-poppins text-xs w-fit"
          >
            {circularProgress === "update" ? (
              <CircularProgress color="default" size={17} />
            ) : (
              "Update"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFormModal;
