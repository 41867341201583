// import React, { useContext, useEffect, useState } from "react";
// import LocationPicker from "../components/LocationPicker";
// import Back from "../components/Back";
// import { DistrictsContext } from "../context/DistrictsContext";
// import { useParams } from "react-router-dom";
// import SelectedDistrictTable from "../components/SelectedDistricTable";
// import WellHistoryListCard from "../components/WellHistoryListCard";
// import WellDetailsCard from "../components/WellDetailsCard"; // Import WellDetailsCard
// import { FaCaretDown } from "react-icons/fa";
// import download_1 from "../assets/download_1.png";
// import SurveyDetailsCard from "../components/SurveyDetailsCard";
// import WellHistoryDetailsCard from "../components/WellHistoryDetailsCard";
// import axiosInstance from "../interceptor/axiosInstance";
// import * as XLSX from "xlsx";
// import { CircularProgress } from "@mui/material";

// const chooseYear = [
//   "2017",
//   "2018",
//   "2019",
//   "2020",
//   "2021",
//   "2022",
//   "2023",
//   "2024",
//   "2025",
//   "2026",
//   "2027",
//   "2028",
//   "2029",
//   "2030",
// ];

// const chooseMonth = [
//   {
//     start: "Jan",
//     end: "Mar",
//   },
//   {
//     start: "Apr",
//     end: "Jun",
//   },
//   {
//     start: "Jul",
//     end: "Sep",
//   },
//   {
//     start: "Oct",
//     end: "Dec",
//   },
// ];
// const SelectedWellHistory = () => {
//   const [dateDropDown, setDateDropDown] = useState();
//   const [wellHistoryData, setwellHistoryData] = useState([]);
//   const [originalHistoryData, setOriginalHistData] = useState([]);
//   const [selectedWellDetails, setSelectedWellDetails] = useState(null); // State to hold selected well details
//   const [selectedFilter, setSelectedFilter] = useState({
//     year: null,
//     start: null,
//     end: null,
//   });
//   const [showProgress, setShowProgress] = useState();

//   const { districts } = useContext(DistrictsContext);
//   const { district_id, exWellId, wellId } = useParams();

//   const handleChooseMonth = (start, end) => {
//     setDateDropDown();
//     setSelectedFilter((prev) => ({
//       ...prev,
//       start: start,
//       end: end,
//     }));
//   };

//   const handleChooseYear = (year) => {
//     setDateDropDown();
//     setSelectedFilter((prev) => ({
//       ...prev,
//       year: year,
//     }));
//   };

//   const handleSearch = () => {
//     const from = new Date(
//       `01-${selectedFilter?.start}-${selectedFilter?.year}`
//     );
//     const till = new Date(`31-${selectedFilter?.end}-${selectedFilter?.year}`);

//     const filterData = originalHistoryData?.filter((item) => {
//       const itemDate = new Date(item?.date);
//       return itemDate >= from && itemDate <= till;
//     });

//     setwellHistoryData(filterData);
//   };

//   const selectedDistrict = districts?.find(
//     (item) => item.district_id == district_id
//   );

//   const fetchHistoryData = async () => {
//     try {
//       setShowProgress(true);
//       const response = await axiosInstance.get(
//         `/api/district/well/history/${wellId}`
//       );

//       const historyFinal = response?.data;
//       setwellHistoryData(historyFinal?.Data);
//       setOriginalHistData(historyFinal?.Data);
//     } catch (error) {
//       console.error("Error fetching well history data", error);
//     } finally {
//       setShowProgress(false);
//     }
//   };

//   useEffect(() => {
//     fetchHistoryData();
//   }, [wellId]);
//   const handleShowDetails = (item) => {
//     setSelectedWellDetails(item); // Set the selected well details to display
//   };

//   const handleDownloadExcel = () => {
//     // Format data based on well_type
//     const formattedData = wellHistoryData?.map((well, i) => {
//       if (well?.well_type.toLowerCase().includes("dug well")) {
//         return {
//           "No.": i + 1,
//           State: well?.state,
//           District: selectedDistrict?.district_name,
//           "Well ID": well?.ex_well_id || "-",
//           "Well Type": well?.well_type || "-",
//           Village: well?.village || "-",
//           Latitude: well?.latitude || "-",
//           Longitude: well?.longitude || "-",
//           "DMS Latitude": well?.dms_latitude || "-",
//           "DMS Longitude": well?.dms_longitude || "-",
//           "Survey Date": well?.createdat || "-",
//           "Survey By": well?.name || "-",
//           "Depth(m)": well?.survey_depth || "-",
//           "Diameter(m)": well?.survey_diameter || "-",
//           MP: well?.survey_measuring_point || "-",
//           "Curbing Depth": well?.curbing_depth || "-",
//           "Use of Well": well?.use_of_well || "-",
//           EC: well?.electrical_conductivity || "-",
//           Temp: well?.temperature || "-",
//           "Water level": well?.water_level || "-",
//           PH: well?.ph_value || "-",
//           TH: well?.th_value || "-",
//           CA: well?.ca_value || "-",
//           MG: well?.mg_value || "-",
//           NA: well?.na_value || "-",
//           K: well?.k_value || "-",
//           CO3: well?.co3_value || "-",
//           HCO3: well?.hco3_value || "-",
//           CL: well?.cl_value || "-",
//           SO4: well?.so4_value || "-",
//           NO3: well?.no3_value || "-",
//           F: well?.f_value || "-",
//           U: well?.u_value || "-",
//           Remarks: well?.remarks || "-",
//         };
//       } else if (well?.well_type.includes("Piezometer")) {
//         return {
//           "No.": i + 1,
//           State: well?.state,
//           District: selectedDistrict?.district_name,
//           "Well ID": well?.ex_well_id || "-",
//           "Well Type": well?.well_type || "-",
//           Village: well?.village || "-",
//           Latitude: well?.latitude || "-",
//           Longitude: well?.longitude || "-",
//           "DMS Latitude": well?.dms_latitude || "-",
//           "DMS Longitude": well?.dms_longitude || "-",
//           "Survey Date": well?.createdat || "-",
//           "Survey By": well?.name || "-",
//           "Protection Box Status": well?.protection_box_status || "-",
//           "Type of lock": well?.type_of_lock || "-",
//           "Water level": well?.water_level || "-",
//           EC: well?.electrical_conductivity || "-",
//           Temp: well?.temperature || "-",
//           PH: well?.ph_value || "-",
//           TH: well?.th_value || "-",
//           CA: well?.ca_value || "-",
//           MG: well?.mg_value || "-",
//           NA: well?.na_value || "-",
//           K: well?.k_value || "-",
//           CO3: well?.co3_value || "-",
//           HCO3: well?.hco3_value || "-",
//           CL: well?.cl_value || "-",
//           SO4: well?.so4_value || "-",
//           NO3: well?.no3_value || "-",
//           F: well?.f_value || "-",
//           U: well?.u_value || "-",
//           Remarks: well?.remarks || "-",
//           Vendor: well?.vendor || "-",
//         };
//       }
//       return {};
//     });
//     const worksheet = XLSX.utils.json_to_sheet(formattedData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

//     // Save the workbook
//     XLSX.writeFile(
//       workbook,
//       `${selectedDistrict?.district_name.toLowerCase()}-${exWellId.toLowerCase()}-well-history.xlsx`
//     );
//   };

//   return (
//     <>
//       <div className="w-[90%] mx-auto pb-4 bg-softGrey">
//         <div className="font-poppins">
//           <div className="my-2 flex items-center justify-between">
//             <h2 className="font-bold relative flex items-center text-blackSubDark text-lg">
//               <span className="">
//                 <Back />
//               </span>{" "}
//               Selected Well({exWellId}) - History
//             </h2>
//             {/* <LocationPicker /> */}
//           </div>
//         </div>
//         <div className="flex items-start gap-5">
//           <div className="w-[55%]">
//             <SelectedDistrictTable selectedDistrict={selectedDistrict} />
//             <div className="relative">
//               <div className="flex items-center justify-between px-2 mt-4 gap-10">
//                 {/* <div className="bg-white flex items-center gap-4 rounded-lg font-roboto text-sm px-4 py-2">
//                   <div className="p-1 relative w-[120px] border border-lightGrey rounded-lg">
//                     <p
//                       onClick={() =>
//                         setDateDropDown(dateDropDown === "month" ? "" : "month")
//                       }
//                       className="flex items-center justify-between cursor-pointer"
//                     >
//                       {selectedFilter?.start && selectedFilter?.end
//                         ? selectedFilter.start + "-" + selectedFilter?.end
//                         : "Choose Month"}
//                       <span>
//                         <FaCaretDown />
//                       </span>
//                     </p>
//                     <div
//                       className={`${
//                         dateDropDown === "month"
//                           ? "inline-block z-10 top-10"
//                           : "-top-10 -z-50"
//                       } absolute bg-white left-0 rounded-lg w-[100%] shadow-xl transition-all duration-300`}
//                     >
//                       <ul>
//                         {chooseMonth?.map((item, i) => (
//                           <li
//                             onClick={() =>
//                               handleChooseMonth(item?.start, item?.end)
//                             }
//                             key={i}
//                             className={`${
//                               i === 0
//                                 ? "rounded-t-lg"
//                                 : i === chooseMonth.length - 1
//                                 ? "rounded-b-lg"
//                                 : ""
//                             } ${
//                               selectedFilter?.start === item?.start &&
//                               selectedFilter?.end === item?.end
//                                 ? "bg-buttonPrimary text-white"
//                                 : ""
//                             } p-1 cursor-pointer text-center hover:bg-buttonPrimary hover:text-white `}
//                           >
//                             {item?.start}-{item?.end}
//                           </li>
//                         ))}
//                       </ul>
//                     </div>
//                   </div>
//                   <div className="p-1 relative w-[120px] border border-lightGrey rounded-lg">
//                     <p
//                       onClick={() =>
//                         setDateDropDown(dateDropDown === "year" ? "" : "year")
//                       }
//                       className="flex items-center justify-between cursor-pointer"
//                     >
//                       {selectedFilter?.year
//                         ? selectedFilter?.year
//                         : "Choose Year"}
//                       <span>
//                         <FaCaretDown />
//                       </span>
//                     </p>
//                     <div
//                       className={`${
//                         dateDropDown === "year"
//                           ? "inline-block z-10 top-10"
//                           : "-top-10 -z-50"
//                       } absolute bg-white rounded-lg left-0 w-[130%] shadow-xl transition-all duration-300`}
//                     >
//                       <ul className="grid grid-cols-2">
//                         {chooseYear?.map((item, i) => (
//                           <li
//                             onClick={() => handleChooseYear(item)}
//                             key={i}
//                             className={`${
//                               i === 0
//                                 ? "rounded-tl-lg"
//                                 : i === 1
//                                 ? "rounded-tr-lg"
//                                 : i === chooseYear.length - 1
//                                 ? "rounded-br-lg"
//                                 : i === chooseYear.length - 2
//                                 ? "rounded-bl-lg"
//                                 : ""
//                             } ${
//                               selectedFilter?.year === item
//                                 ? "bg-buttonPrimary text-white"
//                                 : ""
//                             } p-1 text-center cursor-pointer hover:bg-buttonPrimary hover:text-white`}
//                           >
//                             {item}
//                           </li>
//                         ))}
//                       </ul>
//                     </div>
//                   </div>
//                   <button
//                     onClick={handleSearch}
//                     title={
//                       selectedFilter?.year === null ||
//                       undefined ||
//                       selectedFilter?.start === null ||
//                       undefined ||
//                       selectedFilter?.end === null ||
//                       undefined
//                         ? "Please select both month and year to enable search"
//                         : ""
//                     }
//                     disabled={
//                       selectedFilter?.year === null ||
//                       undefined ||
//                       selectedFilter?.start === null ||
//                       undefined ||
//                       selectedFilter?.end === null ||
//                       undefined
//                     }
//                     className={`${
//                       selectedFilter?.year === null ||
//                       undefined ||
//                       selectedFilter?.start === null ||
//                       undefined ||
//                       selectedFilter?.end === null ||
//                       undefined
//                         ? "bg-lightGrey text-black"
//                         : "bg-buttonPrimary text-white"
//                     } text-sm transition-colors duration-300 rounded-lg px-3 py-1`}
//                   >
//                     Search
//                   </button>
//                 </div> */}
//                 <p className="font-poppins font-medium">
//                   {wellHistoryData?.length} well{" "}
//                   {wellHistoryData?.length > 1 ? "histories" : "history"}
//                 </p>
//                 <div onClick={handleDownloadExcel} className="cursor-pointer">
//                   <h1 className="flex text-[#F7004B] items-center gap-1 font-bold text-sm">
//                     Download as Excel{" "}
//                     <img className="w-4 h-4" src={download_1} alt="" />
//                   </h1>
//                 </div>
//               </div>
//             </div>

//             <div className="my-4 w-[100%] flex flex-col gap-5 h-[50vh] overflow-y-auto custom-scrollbar">
//               {showProgress ? (
//                 <div className="w-full mx-auto h-[30vh] flex items-center justify-center">
//                   <CircularProgress size={24} />
//                 </div>
//               ) : (
//                 <>
//                   {/* <p className="font-poppins font-medium">
//                     {wellHistoryData?.length} well{" "}
//                     {wellHistoryData?.length > 1 ? "histories" : "history"}
//                   </p> */}
//                   {wellHistoryData.map((item, i) => (
//                     <WellHistoryListCard
//                       cardNumber={i + 1}
//                       selectedWellDetails={selectedWellDetails}
//                       key={item.id}
//                       item={item}
//                       setwellHistoryData={setwellHistoryData}
//                       handleShowDetails={handleShowDetails}
//                     />
//                   ))}
//                 </>
//               )}
//             </div>
//           </div>
//           <div className="w-[45%] bg-white rounded-lg">
//             {selectedWellDetails ? (
//               <WellHistoryDetailsCard
//                 wellId={exWellId}
//                 well={selectedWellDetails}
//               />
//             ) : (
//               <div className="font-poppins font-semibold h-[70vh] flex items-center justify-center text-buttonSecondary">
//                 Select a survey to view details
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SelectedWellHistory;

import React, { useContext, useEffect, useState } from "react";
import LocationPicker from "../components/LocationPicker";
import Back from "../components/Back";
import { DistrictsContext } from "../context/DistrictsContext";
import { useParams } from "react-router-dom";
import SelectedDistrictTable from "../components/SelectedDistricTable";
import WellHistoryListCard from "../components/WellHistoryListCard";
import WellDetailsCard from "../components/WellDetailsCard"; // Import WellDetailsCard
import { FaCaretDown } from "react-icons/fa";
import download_1 from "../assets/download_1.png";
import SurveyDetailsCard from "../components/SurveyDetailsCard";
import WellHistoryDetailsCard from "../components/WellHistoryDetailsCard";
import axiosInstance from "../interceptor/axiosInstance";
import * as XLSX from "xlsx";
import { CircularProgress } from "@mui/material";
import { formatDateToShow } from "../utils/formatDate";

const chooseYear = [
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
];

const chooseMonth = [
  {
    start: "Jan",
    end: "Mar",
  },
  {
    start: "Apr",
    end: "Jun",
  },
  {
    start: "Jul",
    end: "Sep",
  },
  {
    start: "Oct",
    end: "Dec",
  },
];
const SelectedWellHistory = () => {
  const [dateDropDown, setDateDropDown] = useState();
  const [wellHistoryData, setwellHistoryData] = useState([]);
  const [originalHistoryData, setOriginalHistData] = useState([]);
  const [selectedWellDetails, setSelectedWellDetails] = useState(null); // State to hold selected well details
  const [selectedFilter, setSelectedFilter] = useState({
    year: null,
    start: null,
    end: null,
  });
  const [showProgress, setShowProgress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const { districts } = useContext(DistrictsContext);
  const { district_id, exWellId, wellId } = useParams();
  const [wellType, setWellType] = useState(null); // State to store the well_type

  const handleChooseMonth = (start, end) => {
    setDateDropDown();
    setSelectedFilter((prev) => ({
      ...prev,
      start: start,
      end: end,
    }));
  };

  const handleChooseYear = (year) => {
    setDateDropDown();
    setSelectedFilter((prev) => ({
      ...prev,
      year: year,
    }));
  };

  const handleSearch = () => {
    const from = new Date(
      `01-${selectedFilter?.start}-${selectedFilter?.year}`
    );
    const till = new Date(`31-${selectedFilter?.end}-${selectedFilter?.year}`);

    const filterData = originalHistoryData?.filter((item) => {
      const itemDate = new Date(item?.date);
      return itemDate >= from && itemDate <= till;
    });

    setwellHistoryData(filterData);
  };

  const selectedDistrict = districts?.find(
    (item) => item.district_id == district_id
  );

  const fetchHistoryData = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get(
        `/api/district/well/history/${wellId}`
      );

      const historyFinal = response?.data;
      setwellHistoryData(historyFinal?.Data);
      setOriginalHistData(historyFinal?.Data);
      // Extract well_type from response
      if (historyFinal?.Data && historyFinal?.Data.length > 0) {
        setWellType(historyFinal?.Data[0]?.well_type); // Assuming well_type is consistent across data
      }
    } catch (error) {
      console.error("Error fetching well history data", error);
    } finally {
      setShowProgress(false);
    }
  };

  // const fetchHistoryData = async () => {
  //   try {
  //     setShowProgress(true);
  //     const response = await axiosInstance.get(`/api/district/well/history/${wellId}`);
  //     const historyData = response?.data;
  //     setwellHistoryData(historyData?.Data);

  //     // Extract well_type from response
  //     if (historyData?.Data && historyData?.Data.length > 0) {
  //       setWellType(historyData?.Data[0]?.well_type); // Assuming well_type is consistent across data
  //     }
  //   } catch (error) {
  //     console.error("Error fetching well history data", error);
  //   } finally {
  //     setShowProgress(false);
  //   }
  // };
  useEffect(() => {
    fetchHistoryData();
  }, [wellId]);
  const handleShowDetails = (item) => {
    setSelectedWellDetails(item); // Set the selected well details to display
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFieldChange = (field) => {
    setSelectedFields((prev) =>
      prev.includes(field) ? prev.filter((f) => f !== field) : [...prev, field]
    );
  };

  const handleDownloadExcel = () => {
    handleOpenModal();
  };
  const handleDownload = () => {
    const formattedData = wellHistoryData?.map((well, i) => {
      // Helper function to format the date as "day-month-year" (e.g., "13-Feb-2025")
      // const formatDate = (date) => {
      //   if (!date) return "-"; // Return "-" if date is not available
      //   const d = new Date(date);
      //   const day = d.getDate().toString().padStart(2, '0'); // Ensure 2-digit day (e.g., "01", "13")
      //   const month = d.toLocaleString('default', { month: 'short' }); // Get the 3-letter month (e.g., "Jan", "Feb")
      //   const year = d.getFullYear(); // Get the full year (e.g., "2025")
      //   return `${day}-${month}-${year}`;
      // };

      // Helper function to format sample_taken (0 -> "Not Taken", 1 -> "Taken")
      const formatSampleStatus = (status) => {
        return status === 1 ? "Taken" : "Not Taken";
      };
      const data = {
        // Dug Well fields (Well Profile)

        State: well?.state || "-",
        District: selectedDistrict?.district_name || "-",
        Firka: well?.firka || "-",
        Village: well?.village || "-",
        "Well ID": well?.ex_well_id || "-",
        "Well Type": well?.well_type || "-",
        "Well Status": well?.status || "-",
        "Location Details": well?.location_detail || "-",
        Latitude: well?.latitude || "-",
        Longitude: well?.longitude || "-",
        "DMS Latitude": well?.dms_latitude || "-",
        "DMS Longitude": well?.dms_longitude || "-",
        "Depth of well (mbgl)": well?.depth || "-",
        "Measuring point (magl)": well?.measuring_point || "-",
        "Diameter of well (m)": well?.diameter || "-",
        "Curbing depth (mbgl)": well?.curbing_depth || "-",
        "Use of well": well?.use_of_well || "-",
        "Key Number": well?.key_no || "-",
        Vendor: well?.vendor || "-",

        "Survey By": well?.name || "-",
        "Session Start Date": formatDateToShow(well?.session_start_date),
        "Session End Date": formatDateToShow(well?.session_end_date),
        "Assign Date": formatDateToShow(well?.assign_date),
        "Survey Submit": formatDateToShow(well?.completed_date),
        "Protection Box Status": well?.protection_box_status || "-",
        "Type of lock": well?.type_of_lock || "-",
        "Depth of Well (mbgl)": well?.survey_depth || "-",
        "Measuring Point (magl)": well?.survey_measuring_point || "-",
        "Diameter of Well (m)": well?.survey_diameter || "-",
        "Curbing Depth (mbgl)": well?.survey_curbing_depth || "-",
        "Water Level(mbmp)": well?.water_level || "-",
        "Electrical Conductivity (ms/cm)": well?.electrical_conductivity || "-",
        Temperature: well?.temperature || "-",
        "pH Value": well?.ph_value || "-",

        // Added missed fields from the fieldNames mapping
        "Use of Well": well?.survey_use_of_well || "-",
        Remarks: well?.remarks || "-",

        "Sample Collect Status": formatSampleStatus(well?.sample_taken), // Format sample status
        "Base Parameter sample Number": well?.well_bp_id || "-",
        TH: well?.th_value || "-",
        Ca: well?.ca_value || "-",
        Mg: well?.mg_value || "-",
        Na: well?.na_value || "-",
        K: well?.k_value || "-",
        CO3: well?.co3_value || "-",
        HCO3: well?.hco3_value || "-",
        Cl: well?.cl_value || "-",
        SO4: well?.so4_value || "-",
        NO3: well?.no3_value || "-",
        "Heavy Metals Sample number": well?.well_hm_id || "-",
        F: well?.f_value || "-",
        U: well?.u_value || "-",
        // Piezometer fields (Well Profile)
      };

      return {
        "No.": i + 1, // This is the serial number
        ...Object.fromEntries(
          Object.entries(data).filter(([key]) => selectedFields.includes(key))
        ),
      };
    });
    console.log("Formatted Excel Data:", formattedData);
    // Create the worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Calculate column widths dynamically
    const columnWidths = Object.keys(worksheet).map((key) => {
      if (key[0] !== "!") {
        // Get the longest value between header and content
        const maxLength = Math.max(
          key.length, // Length of the header (field name)
          ...formattedData.map((row) => row[key]?.toString().length || 0)
        );
        // Set the column width with some padding (e.g., maxLength + 2 for padding)
        return { wpx: (maxLength + 2) * 28 }; // Adjust width by multiplying by 8px per character
      }
      return { wpx: 50 }; // Default width for non-data columns (like `!ref`)
    });

    // Apply the column widths to the worksheet
    worksheet["!cols"] = columnWidths;

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

    // Trigger download of the Excel file
    XLSX.writeFile(
      workbook,
      `${selectedDistrict?.district_name.charAt(0).toUpperCase() + selectedDistrict?.district_name.slice(1).toLowerCase()}-${exWellId.toLowerCase()}-well-history.xlsx`
    );

    // Close the modal (if this is part of the UI process)
    handleCloseModal();
  };

  const fieldsDugWell = [
    {
      subheading: "Well Profile",
      fields: [
        "State",
        "District",
        "Firka",
        "Village",
        "Well ID",
        "Well Type",
        "Well Status",
        "Location Details",
        "Latitude",
        "Longitude",
        "DMS Latitude",
        "DMS Longitude",
        "Depth of well (mbgl)",
        "Measuring point (magl)",
        "Diameter of well (m)",
        "Curbing depth (mbgl)",
        "Use of well",
      ],
    },
    {
      subheading: "Survey Fields",
      fields: [
        "Survey By",
        "Session Start Date",
        "Session End Date",
        "Assign Date",
        "Survey Submit",
        "Depth of Well (mbgl)",
        "Measuring Point (magl)",
        "Diameter of Well (m)",
        "Curbing Depth (mbgl)",
        "Water Level(mbmp)",
        "Electrical Conductivity (ms/cm)",
        "Temperature",
        "pH Value",
        "Use of Well",
        "Remarks",
        "Sample Collect Status",
      ],
    },
    {
      subheading: "Base Parameters",
      fields: [
        "Base Parameter sample Number", // Base Parameter sample Number added
        "TH",
        "Ca",
        "Mg",
        "Na",
        "K",
        "CO3",
        "HCO3",
        "Cl",
        "SO4",
        "NO3",
      ],
    },
    {
      subheading: "Heavy Metals",
      fields: ["Heavy Metals Sample number", "F", "U"],
    },
  ];
  const fieldsPiezometer = [
    {
      subheading: "Well Profile",
      fields: [
        "State",
        "District",
        "Firka",
        "Village",
        "Well ID",
        "Well Type",
        "Well Status",
        "Location Details",
        "Latitude",
        "Longitude",
        "DMS Latitude",
        "DMS Longitude",
        "Depth of well (mbgl)",
        "Measuring point (magl)",
        "Diameter of well (m)",
        "Key Number",
        "Vendor",
      ],
    },
    {
      subheading: "Survey Fields",
      fields: [
        "Survey By",
        "Session Start Date",
        "Session End Date",
        "Assign Date",
        "Survey Submit",
        "Protection Box Status",
        "Type of Lock",
        "Water Level(mbmp)",
        "Electrical Conductivity (ms/cm)",
        "Temperature",
        "pH Value",
        "Remarks",
        "Sample Collect Status",
      ],
    },
    {
      subheading: "Base Parameters",
      fields: [
        "Base Parameter sample Number", // Base Parameter sample Number added
        "TH",
        "Ca",
        "Mg",
        "Na",
        "K",
        "CO3",
        "HCO3",
        "Cl",
        "SO4",
        "NO3",
      ],
    },
    {
      subheading: "Heavy Metals",
      fields: ["Heavy Metals Sample number", "F", "U"],
    },
  ];

  return (
    <>
      <div className="w-[90%] mx-auto pb-4 bg-softGrey">
        <div className="font-poppins">
          <div className="my-2 flex items-center justify-between">
            <h2 className="font-bold relative flex items-center text-blackSubDark text-lg">
              <span className="">
                <Back />
              </span>{" "}
              Selected Well({exWellId}) - History
            </h2>
            {/* <LocationPicker /> */}
          </div>
        </div>
        <div className="flex items-start gap-5">
          <div className="w-[55%]">
            <SelectedDistrictTable selectedDistrict={selectedDistrict} />
            <div className="relative">
              <div className="flex items-center justify-between px-2 mt-4 gap-10">
                {/* <div className="bg-white flex items-center gap-4 rounded-lg font-roboto text-sm px-4 py-2">
                  <div className="p-1 relative w-[120px] border border-lightGrey rounded-lg">
                    <p
                      onClick={() =>
                        setDateDropDown(dateDropDown === "month" ? "" : "month")
                      }
                      className="flex items-center justify-between cursor-pointer"
                    >
                      {selectedFilter?.start && selectedFilter?.end
                        ? selectedFilter.start + "-" + selectedFilter?.end
                        : "Choose Month"}
                      <span>
                        <FaCaretDown />
                      </span>
                    </p>
                    <div
                      className={`${
                        dateDropDown === "month"
                          ? "inline-block z-10 top-10"
                          : "-top-10 -z-50"
                      } absolute bg-white left-0 rounded-lg w-[100%] shadow-xl transition-all duration-300`}
                    >
                      <ul>
                        {chooseMonth?.map((item, i) => (
                          <li
                            onClick={() =>
                              handleChooseMonth(item?.start, item?.end)
                            }
                            key={i}
                            className={`${
                              i === 0
                                ? "rounded-t-lg"
                                : i === chooseMonth.length - 1
                                ? "rounded-b-lg"
                                : ""
                            } ${
                              selectedFilter?.start === item?.start &&
                              selectedFilter?.end === item?.end
                                ? "bg-buttonPrimary text-white"
                                : ""
                            } p-1 cursor-pointer text-center hover:bg-buttonPrimary hover:text-white `}
                          >
                            {item?.start}-{item?.end}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="p-1 relative w-[120px] border border-lightGrey rounded-lg">
                    <p
                      onClick={() =>
                        setDateDropDown(dateDropDown === "year" ? "" : "year")
                      }
                      className="flex items-center justify-between cursor-pointer"
                    >
                      {selectedFilter?.year
                        ? selectedFilter?.year
                        : "Choose Year"}
                      <span>
                        <FaCaretDown />
                      </span>
                    </p>
                    <div
                      className={`${
                        dateDropDown === "year"
                          ? "inline-block z-10 top-10"
                          : "-top-10 -z-50"
                      } absolute bg-white rounded-lg left-0 w-[130%] shadow-xl transition-all duration-300`}
                    >
                      <ul className="grid grid-cols-2">
                        {chooseYear?.map((item, i) => (
                          <li
                            onClick={() => handleChooseYear(item)}
                            key={i}
                            className={`${
                              i === 0
                                ? "rounded-tl-lg"
                                : i === 1
                                ? "rounded-tr-lg"
                                : i === chooseYear.length - 1
                                ? "rounded-br-lg"
                                : i === chooseYear.length - 2
                                ? "rounded-bl-lg"
                                : ""
                            } ${
                              selectedFilter?.year === item
                                ? "bg-buttonPrimary text-white"
                                : ""
                            } p-1 text-center cursor-pointer hover:bg-buttonPrimary hover:text-white`}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <button
                    onClick={handleSearch}
                    title={
                      selectedFilter?.year === null ||
                      undefined ||
                      selectedFilter?.start === null ||
                      undefined ||
                      selectedFilter?.end === null ||
                      undefined
                        ? "Please select both month and year to enable search"
                        : ""
                    }
                    disabled={
                      selectedFilter?.year === null ||
                      undefined ||
                      selectedFilter?.start === null ||
                      undefined ||
                      selectedFilter?.end === null ||
                      undefined
                    }
                    className={`${
                      selectedFilter?.year === null ||
                      undefined ||
                      selectedFilter?.start === null ||
                      undefined ||
                      selectedFilter?.end === null ||
                      undefined
                        ? "bg-lightGrey text-black"
                        : "bg-buttonPrimary text-white"
                    } text-sm transition-colors duration-300 rounded-lg px-3 py-1`}
                  >
                    Search
                  </button>
                </div> */}
                <p className="font-poppins font-medium">
                  {wellHistoryData?.length} well{" "}
                  {wellHistoryData?.length > 1 ? "histories" : "history"}
                </p>
                <div onClick={handleDownloadExcel} className="cursor-pointer">
                  <h1 className="flex text-[#F7004B] items-center gap-1 font-bold text-sm">
                    Download as Excel{" "}
                    <img className="w-4 h-4" src={download_1} alt="" />
                  </h1>
                </div>
              </div>
            </div>

            <div className="my-4 w-[100%] flex flex-col gap-5 h-[50vh] overflow-y-auto custom-scrollbar">
              {showProgress ? (
                <div className="w-full mx-auto h-[30vh] flex items-center justify-center">
                  <CircularProgress size={24} />
                </div>
              ) : (
                <>
                  {/* <p className="font-poppins font-medium">
                    {wellHistoryData?.length} well{" "}
                    {wellHistoryData?.length > 1 ? "histories" : "history"}
                  </p> */}
                  {wellHistoryData.map((item, i) => (
                    <WellHistoryListCard
                      cardNumber={i + 1}
                      selectedWellDetails={selectedWellDetails}
                      key={item.id}
                      item={item}
                      setwellHistoryData={setwellHistoryData}
                      handleShowDetails={handleShowDetails}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="w-[45%] bg-white rounded-lg">
            {selectedWellDetails ? (
              <WellHistoryDetailsCard
                wellId={exWellId}
                well={selectedWellDetails}
              />
            ) : (
              <div className="font-poppins font-semibold h-[70vh] flex items-center justify-center text-buttonSecondary">
                Select a survey to view details
              </div>
            )}
          </div>
        </div>
      </div>
      {openModal && (
        <div
          className="z-40"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleCloseModal}
        >
          <div
          className="custom-scrollbar"
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              maxWidth: "1400px",
              width: "90%",
              height: "90vh",
              overflowY: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "5px",
              }}
            >
              {wellType === "Dug Well"
                ? "Select Dug Well Fields to Include in Excel"
                : " Select Piezometer Fields to Include in Excel"}
            </h2>

            {/* Show fields dynamically based on wellType */}
            {(wellType === "Dug Well" ? fieldsDugWell : fieldsPiezometer).map(
              (section) => (
                <div key={section.subheading}>
                  <h3
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      marginTop:
                        section.subheading === "Well Profile" ? 0 : "20px",
                      marginBottom: "5px",
                    }}
                  >
                    {section.subheading}
                  </h3>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(6, 1fr)",
                      gap: "2px",
                    }}
                  >
                    {section.fields.map((field) => (
                      <label
                        key={field}
                        style={{fontSize:"14px"}}
                        className="flex items-center "
                      >
                        <input
                          type="checkbox"
                          checked={selectedFields.includes(field)}
                          onChange={() => handleFieldChange(field)}
                          style={{ marginRight: "5px" }}
                        />
                        {field}
                      </label>
                    ))}
                  </div>
                </div>
              )
            )}

            {/* Modal Action Buttons */}
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button
                onClick={handleCloseModal}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#ccc",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleDownload}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#28a745",
                  color: "white",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedWellHistory;
