import React, { useState, useEffect, useContext } from "react";
import plus from "../assets/plus.png";
import axiosInstance from "../interceptor/axiosInstance";
import Resizer from "react-image-file-resizer";
import { DistrictsContext } from "../context/DistrictsContext";
import { CircularProgress } from "@mui/material";
import UpdateSuccessModal from "./UpdateSuccessModal";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { dataURLtoBlob } from "../utils/dataUrlToBlob";

const MissedSurveyDetailsCard = ({
  survey,
  empId,
  fetchSurvey,
  setSelectedSurvey,
}) => {
  const [showProgress, setShowProgress] = useState();
  const [checkRequired, setCheckRequired] = useState(false);
  const adminName = useSelector((state) => state.auth.user?.Name);
  const [showOthers, setShowOthers] = useState(false);
  const [formData, setFormData] = useState({
    admin_name: adminName,
    well_id: survey?.well_id,
    emp_id: empId,
    admin_post: true,
    well_type: null,
    protection_box_status: null,
    type_of_lock: null,
    water_level_type: null,
    water_level: null,
    remarks: null,
    electrical_conductivity: null,
    temperature_c: null,
    ph: null,
    heavy_metal: null,
    base_parameter: null,
    depth: null,
    diameter: null,
    measuring_point: null,
    additional_info: null,
    curbing_depth: null,
    use_of_well: null,
    sample_collected: null,
    well_image: [],
  });
  console.log(survey);

  console.log(formData);

  const { updatedModal, setUpdatedModal } = useContext(DistrictsContext);

  const dugWellFormItems = [
    {
      label: "Depth Of Well(mbgl)",
      type: "number",
      key: "depth",
    },
    {
      label: "Measuring Point (magl)",
      type: "number",
      key: "measuring_point",
    },
    {
      label: "Diameter of Well (m)",
      type: "number",
      key: "diameter",
    },

    {
      label: "Curbing Depth (mbgl)",
      type: "number",
      key: "curbing_depth",
    },
    {
      label: "Use Of Well",
      type: "select",
      options: ["Domestic", "Irrigation", "Industrial", "Others", "Not in use"],
      key: "use_of_well",
    },
  ];

  const piezoFormItems = [
    {
      label: "Protection Box Status",
      type: "select",
      options: ["Good Condition", "Damaged"],
      key: "protection_box_status",
    },
    {
      label: "Type of Lock",
      type: "select",
      options: ["New", "Old"],
      key: "type_of_lock",
    },
  ];

  const commonFormItems = [
    {
      label: "Water Level (mbgl)",
      type: "select",
      options: ["Present", "Dry"],
      key: "water_level_type",
    },
    { label: "", type: "number", key: "water_level" },
    {
      label: "Electrical Conductivity (ms/cm)",
      type: "number",
      key: "electrical_conductivity",
    },
    { label: "Temperature °C", type: "number", key: "temperature_c" },
    { label: "pH", type: "number", key: "ph" },
    {
      label: "Sample Status",
      type: "select",
      options: ["Taken", "Not Taken"],
      key: "sample_collected",
    },
    {
      label: "Base Parameter",
      value: `Sample No : ${survey?.well_bp_id}`,
      key: "base_parameter",
    },
    {
      label: "Heavy Metal",
      value: `Sample No : ${survey?.well_hm_id}`,
      key: "heavy_metal",
    },
    {
      label: "Remarks",
      type: "select",
      options: ["Abandoned", "Not Approachable", "Other"],
      key: "remarks",
    },
    { label: "", type: "text", key: "additional_info" },
    { label: "Well Photos", type: "file", key: "well_image" },
  ];

  const onSurveySubmit = () => {
    fetchSurvey();
    setSelectedSurvey();
    setCheckRequired(false);
  };

  const getFormItems = () => {
    if (survey?.well_type.toLowerCase().includes("piezometer")) {
      return [...piezoFormItems, ...commonFormItems];
    } else if (survey?.well_type.toLowerCase().includes("dug well")) {
      return [...dugWellFormItems, ...commonFormItems];
    } else {
      return [];
    }
  };

  const formItems = getFormItems();

  const handleInputChange = async (e) => {
    const { name, value, files, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));

      if (name === "water_level") {
        if (!checked || value === "Dry") {
          setFormData((prev) => ({
            ...prev,
            electrical_conductivity: "",
            temperature_c: "",
            ph: "",
          }));
        }
      }
    } else if (type === "file" && formData?.well_image?.length <= 2) {
      const fileList = files ? [...files] : [];
      if (fileList.length > 0 && fileList.length <= 2) {
        const resizedFiles = [];
        // Resize and compress each image before uploading
        for (const file of fileList) {
          try {
            // Resize the image using react-image-file-resizer
            const resizedFile = await new Promise((resolve, reject) => {
              Resizer.imageFileResizer(
                file, // The file to resize
                800, // Maximum width (optional)
                800, // Maximum height (optional)
                "JPEG", // Output format (JPEG, PNG, etc.)
                80, // Quality of the image (0 to 100, higher is better quality but larger size)
                0, // Rotation angle (optional, 0 means no rotation)
                (uri) => {
                  // `uri` is the resized image data URL, convert it back to a Blob object
                  const resizedBlob = dataURLtoBlob(uri);
                  const resizedFile = new File([resizedBlob], file.name, {
                    type: "image/jpeg",
                  });
                  resolve(resizedFile);
                },
                (err) => reject(err) // Reject if there's an error
              );
            });

            resizedFiles.push(resizedFile);
          } catch (error) {
            console.error("Error resizing image:", error);
          }
        }

        if (resizedFiles.length > 0) {
          const formDataToUpload = new FormData();
          resizedFiles.forEach((file) =>
            formDataToUpload.append("image", file)
          );

          try {
            // Assuming multiple files can be uploaded at once
            setShowProgress("image upload");
            const response = await axiosInstance.post(
              "/api/employee/imageUpload",
              formDataToUpload
            );

            if (response.status === 200) {
              // Assuming the backend returns an array of URLs
              setFormData((prev) => ({
                ...prev,
                well_image: [
                  ...prev.well_image, // Keep the previous images
                  ...response.data, // Add the new images (URLs)
                ],
              }));
            } else {
              console.error("Error uploading images:", response.data.message);
            }
          } catch (error) {
            console.error("Error uploading images:", error);
          } finally {
            setShowProgress("");
          }
        }
      }
    } else if (type === "text" && name === "additional_info") {
      setFormData((prev) => ({
        ...prev,
        remarks: value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    if (survey?.well_type?.toLowerCase()?.includes("dug well")) {
      if (
        formData?.water_level === null ||
        formData?.water_level === "" ||
        formData?.depth === null ||
        formData?.depth === "" ||
        formData?.diameter === null ||
        formData?.diameter === "" ||
        formData?.measuring_point === null ||
        formData?.measuring_point === ""
      ) {
        setCheckRequired(true);
      } else {
        try {
          setShowProgress("submitting");
          console.log(formData);

          const response = await axiosInstance.post(
            "/api/well/survey/post",
            formData
          );
        } catch (error) {
          console.error("Error submitting form", error);
        } finally {
          setFormData(
            // resetting the values of formData
            Object.fromEntries(
              Object.keys(formData).map((key) => [
                key,
                key === "well_image" ? [] : null,
              ])
            )
          );
          setShowProgress("");
          setUpdatedModal(true);
        }
      }
    } else if (survey?.well_type?.toLowerCase()?.includes("piezometer")) {
      if (
        formData?.protection_box_status === null ||
        formData?.type_of_lock === null ||
        formData?.water_level === null ||
        formData?.water_level === ""
      ) {
        setCheckRequired(true);
      } else {
        try {
          setShowProgress("submitting");
          const response = await axiosInstance.post(
            "/api/well/survey/post",
            formData
          );
        } catch (error) {
          console.error("Error submitting form", error);
        } finally {
          setFormData(
            // resetting the values of formData
            Object.fromEntries(
              Object.keys(formData).map((key) => [
                key,
                key === "well_image" ? [] : null,
              ])
            )
          );
          setShowProgress("");
          setUpdatedModal(true);
        }
      }
    }
  };

  const handleRemoveImg = (url) => {
    setFormData((prev) => ({
      ...prev,
      well_image: prev?.well_image?.filter((imgUrl) => imgUrl !== url),
    }));
  };

  const handleSelectChange = (key, option) => {
    if (option === "Dry") {
      setFormData((prev) => ({
        ...prev,
        [key]: option,
        water_level: formData?.depth,
        electrical_conductivity: null,
        temperature_c: null,
        ph: null,
      }));
    } else if (option === "Present") {
      setFormData((prev) => ({
        ...prev,
        water_level: null,
        [key]: option,
      }));
    } else if (option === "Taken") {
      setFormData((prev) => ({
        ...prev,
        sample_collected: 1,
      }));
    } else if (option === "Not Taken") {
      setFormData((prev) => ({
        ...prev,
        sample_collected: 0,
      }));
    } else if (option === "Other") {
      setFormData((prev) => ({
        ...prev,
        remarks: option,
        additional_info: option,
      }));
    } else if (key === "use_of_well" && option === "Others") {
      setShowOthers(true);
      setFormData((prev) => ({
        ...prev,
        [key]: null,
      }));
    } else if (key === "use_of_well" && option !== "Others") {
      setShowOthers(false);
      setFormData((prev) => ({
        ...prev,
        [key]: option,
      }));
    } else if (key === "remarks" && option !== "Other") {
      setFormData((prev) => ({
        ...prev,
        [key]: option,
        additional_info: null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [key]: option,
      }));
    }
  };

  useEffect(() => {
    if (survey) {
      setFormData((prev) => ({
        ...prev,
        well_id: survey?.well_id,
        emp_id: empId,
        well_type: survey?.well_type,
        admin_post: true,
        protection_box_status: null,
        type_of_lock: null,
        water_level_type: null,
        water_level: null,
        remarks: null,
        electrical_conductivity: null,
        temperature_c: null,
        ph: null,
        heavy_metal: survey?.well_hm_id,
        base_parameter: survey?.well_bp_id,
        depth: survey?.depth,
        diameter: survey?.diameter,
        measuring_point: survey?.measuring_point,
        curbing_depth: survey?.curbing_depth,
        additional_info: null,
        sample_collected: null,
        use_of_well: survey?.use_of_well,
        well_image: [],
      }));
    }
    setCheckRequired(false);
  }, [survey]);

  return (
    <>
      <div className="font-roboto">
        <div className="flex flex-col gap-1">
          {formItems?.map((item, index) => {
            if (
              item.key === "electrical_conductivity" ||
              item.key === "temperature_c" ||
              item.key === "ph" ||
              item.key === "water_level"
            ) {
              if (formData["water_level_type"] !== "Present") {
                return null;
              }
            }
            if (item.type === "text" && item.key === "additional_info") {
              if (formData["additional_info"] !== "Other") {
                return null;
              }
            }

            return (
              <div key={index}>
                {item.value ? (
                  <div>
                    <p className="text-[13px] text-blackSubDark font-medium mt-2 mb-1">
                      {item.label}
                    </p>
                    <p className="text-[15px] font-semibold text-errorRed">
                      {item?.value}
                    </p>
                  </div>
                ) : item.type === "select" ? (
                  <div>
                    <p className="text-[13px] text-blackSubDark font-medium mt-2 mb-1">
                      {item.label}{" "}
                      {/* {[
                        "water_level_type",
                        "water_level",
                        "protection_box_status",
                        "type_of_lock",
                      ].includes(item?.key) && formData[item?.key] === null && checkRequired ? (
                        <span className="text-errorRed font-bold text-lg">*</span>
                      ) : null} */}
                    </p>
                    <div className="flex items-center flex-wrap gap-4">
                      {item?.options.map((option, optionIndex) => (
                        <button
                          key={optionIndex}
                          className={`${
                            formData[item?.key] ===
                            (option === "Taken"
                              ? 1
                              : option === "Not Taken"
                              ? 0
                              : option)
                              ? "bg-buttonPrimary text-white"
                              : "bg-softGrey text-blackSubDark"
                          } text-sm rounded-lg  w-[30%] py-2`}
                          onClick={() => {
                            handleSelectChange(item?.key, option);
                          }}
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                    {showOthers && item?.key === "use_of_well" ? (
                      <input className="mt-4 focus:outline-none bg-softGrey focus:border-blackSub rounded-lg px-3 py-1 w-full" />
                    ) : null}
                    {[
                      "water_level_type",
                      "water_level",
                      "protection_box_status",
                      "type_of_lock",
                      "use_of_well",
                      "sample_collected",
                    ].includes(item?.key) &&
                    formData[item?.key] === null &&
                    checkRequired ? (
                      <span className="text-errorRed text-[11px] mt-1 font-medium">
                        This field is required*
                      </span>
                    ) : null}
                  </div>
                ) : item.type === "file" ? (
                  <div>
                    <p className="text-[13px] text-blackSubDark font-medium mt-2 mb-1">
                      {item.label}
                    </p>
                    <div className="flex flex-col w-fit gap-2">
                      <input
                        type="file"
                        id={item.key}
                        name={item.key}
                        accept="image/*"
                        multiple
                        onChange={handleInputChange}
                        className="hidden"
                      />
                      {formData?.well_image?.length < 2 ? (
                        <label
                          htmlFor={item.key}
                          className="bg-softGrey border-[0.7px] bg-[#009DE326] border-dotted border-black focus:border-blackSub rounded-lg px-5 py-3 cursor-pointer text-xs"
                        >
                          <div className="flex items-center gap-2">
                            <img src={plus} alt="plus icon" />
                            <div>
                              <p className="text-[10px] font-medium">
                                Take Photo or Choose from Gallery
                              </p>
                              <p className="font-light text-[10px]">
                                Supports: PNG, JPG, JPEG
                              </p>
                              <p className="font-medium text-dullRed text-[10px]">
                                Upload maximum of two images
                              </p>
                            </div>
                          </div>
                        </label>
                      ) : null}

                      <div className="mt-2 flex flex-wrap items-center gap-2">
                        {formData["well_image"]?.length > 0 && (
                          <div className="flex gap-2 flex-wrap items-center">
                            {formData["well_image"]?.map((fileUrl, idx) => (
                              <div
                                key={idx}
                                className="flex flex-col items-center relative"
                              >
                                <img
                                  src={fileUrl}
                                  alt={`Preview ${idx + 1}`}
                                  className="w-32 h-32 object-cover rounded-md mb-2"
                                />
                                <IoClose
                                  onClick={() => handleRemoveImg(fileUrl)}
                                  className="absolute top-[3px] right-[3px] bg-white bg-opacity-30 rounded-full text-dullRed text-lg cursor-pointer"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {showProgress === "image upload" && (
                          <div className="p-4">
                            <CircularProgress size={20} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="text-[13px] text-blackSubDark font-medium mt-2 mb-1">
                      {item.label}
                    </p>
                    <input
                      key={survey?.well_id}
                      onChange={handleInputChange}
                      name={item.key}
                      value={
                        item?.key === "additional_info"
                          ? null
                          : formData[item.key] || ""
                      }
                      type={item.type}
                      className="focus:outline-none bg-softGrey focus:border-blackSub rounded-lg px-3 py-1 w-full"
                    />
                    {(([
                      "depth",
                      "diameter",
                      "measuring_point",
                      "water_level",
                    ].includes(item?.key) &&
                      formData[item.key] === null) ||
                      formData[item.key] === "") &&
                    checkRequired ? (
                      <p className="text-errorRed text-[11px] mt-1 font-medium">
                        This field is required*
                      </p>
                    ) : null}
                  </div>
                )}
              </div>
            );
          })}

          {survey?.well_type?.toLowerCase()?.includes("piezometer") &&
          (formData?.protection_box_status === null ||
            formData?.type_of_lock === null ||
            formData?.water_level === null ||
            formData?.water_level === "") &&
          checkRequired ? (
            <p className="font-poppins text-errorRed text-[11px] font-semibold text-center">
              Some required fields are missing!
            </p>
          ) : survey?.well_type?.toLowerCase()?.includes("dug well") &&
            (formData?.water_level === null ||
              formData?.water_level === "" ||
              formData?.depth_of_well === null ||
              formData?.depth_of_well === "" ||
              formData?.diameter === null ||
              formData?.diameter === "" ||
              formData?.measuring_point === null ||
              formData?.measuring_point === "") &&
            checkRequired ? (
            <p className="font-poppins text-errorRed text-[11px] font-semibold text-center">
              Some required fields are missing!
            </p>
          ) : null}
          <button
            className="bg-[#006CE7] w-1/2 mx-auto py-1 my-4 rounded-md font-medium text-white"
            onClick={handleSubmit}
          >
            {showProgress === "submitting" ? (
              <CircularProgress color="default" size={17} />
            ) : (
              "Submit & Approve"
            )}
          </button>
        </div>
        {updatedModal === true ? (
          <UpdateSuccessModal
            onSuccessFunction={onSurveySubmit}
            message={"Survey submitted successfully!"}
          />
        ) : null}
      </div>
    </>
  );
};

export default MissedSurveyDetailsCard;
