import React, { useEffect } from "react";
import { formatDateToShow } from "../utils/formatDate";
import { headerToKeyMap } from "../utils/tableData";

const SessionDataTableModal = ({
  setShowPreviewModal,
  getTableHeaders,
  sessionHistoryData,
}) => {
  useEffect(() => {
    const handleEscPress = (event) => {
      if (event.key === "Escape") {
        setShowPreviewModal(false);
      }
    };
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", handleEscPress);
    return () => {
      document.removeEventListener("keydown", handleEscPress);
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div
      onClick={() => setShowPreviewModal("")}
      className="fixed z-10 inset-0 flex items-center justify-center w-full h-[100vh] bg-black bg-opacity-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative z-20 w-[90%] h-[90%] custom-scrollbar overflow-y-auto bg-white rounded-2xl"
      >
        <table className="min-w-full table-cell border-collapse text-sm">
          <thead>
            <tr>
              {getTableHeaders()?.map((header, i) => (
                <th key={i} className="border px-4 py-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sessionHistoryData && sessionHistoryData.length > 0 ? (
              sessionHistoryData.map((item, i) => (
                <tr key={i}>
                  {getTableHeaders().map((header, index) => {
                    const key = headerToKeyMap[header];
                    if (header === "Employee") {
                      return (
                        <td className="border px-4 py-2">
                          {item.name || "-"} ({item.emp_id || "-"})
                        </td>
                      );
                    }
                    if (header === "No.") {
                      return <td className="border px-4 py-2">{i + 1}</td>;
                    }
                    if (
                      header === "Survey Assigned" ||
                      header === "Submitted Date"
                    ) {
                      return (
                        <td className="border px-4 py-2">
                          {formatDateToShow(item[key])}
                        </td>
                      );
                    }
                    return (
                      <td key={index} className="border px-4 py-2">
                        {key ? item[key] || "-" : "-"}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center border px-4 py-2">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SessionDataTableModal;
