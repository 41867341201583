import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import SelectedDistrict from "./pages/SelectedDistrict";
import { DistrictsProvider } from "./context/DistrictsContext";
import SelectedWellHistory from "./pages/SelectedWellHistory";
import DistrictManagement from "./pages/DistrictManagement";
import Navbar from "./components/Navbar";
import EmployeeManagement from "./pages/EmployeeManagement";
import SurveyReview from "./pages/SurveyReview";
import ReportsAndAnalytics from "./pages/ReportsAndAnalytics";
import EmployeeAssignments from "./pages/EmployeeAssignments";
import { EmpManagementProvider } from "./context/EmpManagementContext";
import { EmpAssignmentProvider } from "./context/EmpAssignmentContext";
import AssignWork from "./pages/AssignWork";
import SelectDistrictAndWell from "./pages/SelectDistrictAndWell";
import Login from "./pages/Login";
import MissedSurvey from "./pages/MissedSurvey";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";
import EmployeeAnalytics from "./pages/EmployeeAnalytics";
import { EmpAnalyticsProvider } from "./context/EmpAnalyticsContext";

function AppRoutes() {
  const location = useLocation();
  const showNavbar = location.pathname !== "/";
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? <Navigate to="/district-management" /> : <Login />
          }
        />
        <Route
          path="/district-management"
          element={
            <ProtectedRoute>
              {" "}
              <DistrictManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/selected-district/:district_id"
          element={
            <ProtectedRoute>
              {" "}
              <SelectedDistrict />
            </ProtectedRoute>
          }
        />
        <Route
          path="/selected-well/:district_id/:exWellId/:wellId"
          element={
            <ProtectedRoute>
              {" "}
              <SelectedWellHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee-management"
          element={
            <ProtectedRoute>
              {" "}
              <EmployeeManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee-assignments"
          element={
            <ProtectedRoute>
              {" "}
              <EmployeeAssignments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assign-work"
          element={
            <ProtectedRoute>
              {" "}
              <AssignWork />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assign-work/:district"
          element={
            <ProtectedRoute>
              {" "}
              <SelectDistrictAndWell />
            </ProtectedRoute>
          }
        />
        <Route
          path="/survey-review/:district_name/:emp_id"
          element={
            <ProtectedRoute>
              {" "}
              <SurveyReview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports-and-analytics"
          element={
            <ProtectedRoute>
              {" "}
              <ReportsAndAnalytics />
            </ProtectedRoute>
          }
        />

        <Route
          path="/missed-survey/:emp_id/:emp_name/:district_id"
          element={
            <ProtectedRoute>
              {" "}
              <MissedSurvey />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee-analytics"
          element={
            <ProtectedRoute>
              {" "}
              <EmpAnalyticsProvider>
                <EmployeeAnalytics />
              </EmpAnalyticsProvider>
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const checkDevice = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width < 768) {
        setIsMobile(true);
        setIsTablet(false);
      } else if (width >= 768 && width <= 1024) {
        setIsTablet(true);
        setIsMobile(false);
      } else {
        setIsTablet(false);
        setIsMobile(false);
      }

      setIsPortrait(height > width);
    };

    checkDevice();

    window.addEventListener("resize", checkDevice);

    return () => {
      window.removeEventListener("resize", checkDevice);
    };
  }, []);

  if (isMobile) {
    return (
      <div className="flex justify-center items-center w-full h-screen bg-white">
        <p style={{ fontSize: "24px", color: "black", textAlign: "center" }}>
          This app is not available on mobile screens. Please use a tablet or
          larger screen to access it.
        </p>
      </div>
    );
  }

  if (isTablet && isPortrait) {
    return (
      <div className="flex justify-center items-center w-full h-screen bg-white">
        <p style={{ fontSize: "24px", color: "black", textAlign: "center" }}>
          For a better experience, please switch to landscape mode.
        </p>
      </div>
    );
  }

  return (
    <div className="App">
      <DistrictsProvider>
        <EmpManagementProvider>
          <EmpAssignmentProvider>
            <Router>
              <AppRoutes />
            </Router>
          </EmpAssignmentProvider>
        </EmpManagementProvider>
      </DistrictsProvider>
    </div>
  );
}

export default App;
