import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar/dist/cjs/Calendar.js";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import excel from "../assets/Excel.png";
import Back from "../components/Back";
import SelectedDistrictTable from "../components/SelectedDistricTable"; // Check for the correct file name
import SurveyListCard from "../components/SurveyListCard";
import UpdateSuccessModal from "../components/UpdateSuccessModal";
import WellHistoryDetailsCard from "../components/WellHistoryDetailsCard";
import { DistrictsContext } from "../context/DistrictsContext";
import axiosInstance from "../interceptor/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  clearWellsForApproval,
  selectAllWellsForApproval,
  selectAllWellsSurveyIdForApproval,
} from "../redux/assignmentSlice";
import { formatDateToShow } from "../utils/formatDate";
import { FaArrowLeft } from "react-icons/fa6";

const SurveyReview = () => {
  const { district_name, emp_id } = useParams(); // Consolidate useParams
  const [survey, setsurvey] = useState([]);
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [dateFilteredSurveys, setDateFilteredSurveys] = useState([]);
  const [selectedWell, setSelectedWell] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { districts } = useContext(DistrictsContext);
  const [showScreen, setShowScreen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const { updatedModal, setUpdatedModal, getDistricts } =
    useContext(DistrictsContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { emp_name, date, emp_assigned_date } = location.state;
  const selectedWellsToApprove = useSelector(
    (state) => state.assignment.selectedWellsForApproval
  );
  const dispatch = useDispatch();
  const [showFieldSelectionModal, setShowFieldSelectionModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedWellType, setSelectedWellType] = useState(null); // To store selected well type

  const surveyReview = async () => {
    if (!district_name || !emp_id) {
      console.error("District name or employee ID is missing.");
      return;
    }

    try {
      setShowProgress("screen_loading");
      const response = await axiosInstance.get(
        `api/well/detail/get/${emp_id}/${emp_assigned_date}/${district_name}`
      );
      const result = response?.data?.Data;
      setsurvey(result || []); // Ensure default value
      setFilteredSurveys(result || []);
      setDateFilteredSurveys(result || []);
    } catch (error) {
      console.error("error:", error);
    } finally {
      setShowProgress("");
      setShowScreen(true);
    }
  };

  useEffect(() => {
    surveyReview();
  }, [district_name, emp_id]);

  useEffect(() => {
    getDistricts();
  }, []);

  const handleShowDetails = (well) => {
    setSelectedWell(well);
    setShowDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedWell(null);
  };

  const selectedDistrict = districts?.find(
    (item) => item.district_name === district_name
  );

  const districtId = selectedDistrict?.district_id;

  const unApprovedSurveys = survey.filter(
    (survey) => survey?.survey_approve !== "Approved"
  );

  const fieldNames = {
    // Dug Well fields (Well Profile)
    state: "State",
    district_name: "District",
    session_start_date: "Session start date",
    session_end_date: "Session end date",
    firka: "Firka",
    village: "Village",
    ex_well_id: "Well ID",
    well_type: "Well Type",
    status: "Well Status",
    location_detail: "Location Details",
    latitude: "Latitude",
    longitude: "Longitude",
    dms_latitude: "DMS Latitude",
    dms_longitude: "DMS Longitude",
    depth: "Depth Of well(mbgl)",
    measuring_point: "Measuring point(magl)",
    diameter: "Diameter Of well(m)",
    curbing_depth: "Curbing depth(mbgl)",
    use_of_well: "Use of well",

    // Dug Well fields (Survey Details)
    name: "Survey By",
    assign_date: "Assigne Date",
    createdat: "Survey Submit",
    survey_depth: "Depth Of Well(mbgl)",
    survey_measuring_point: "Measuring Point(magl)",
    survey_diameter: "Diameter Of Well(m)",
    survey_curbing_depth : "Curbing Depth(mbgl)",
    water_level: "Water Level(mbmp)",
    survey_use_of_well :"Use of Well",
    electrical_conductivity: "Electrical Conductivity (ms/cm)",
    temperature: "Temperature",
    ph_value: "pH Value",
    sample_taken: "Sample Collect Status",
    well_bp_id: "Base Parameter sample Number",
    th_value: "TH",
    ca_value: "Ca",
    mg_value: "Mg",
    na_value: "Na",
    k_value: "K",
    co3_value: "CO3",
    hco3_value: "HCO3",
    cl_value: "Cl",
    so4_value: "SO4",
    no3_value: "NO3",
    well_hm_id: "Heavy Metals Sample number",
    f_value: "F",
    u_value: "U",
    remarks: "Remarks",

    // Piezometer fields (Well Profile)
    key_no: "Key Number",
    vendor: "Vendor",
    protection_box_status: "Protection Box Status",
    type_of_lock: "Type of Lock",

    // Piezometer fields (Survey Details)
    // Repeat the same pattern for piezometer fields...
  };

  const fieldTitles = {
    "Dug Well": "Dug Well Profile Fields",
    Piezometer: "Piezometer Profile Fields",
  };
  const availableFields = {
    "Dug Well": {
      "Well Profile": [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "curbing_depth",
        "use_of_well",
      ],
      "Survey Details": [
        "name",
        "session_start_date",
        "session_end_date",
        "assign_date",
        "createdat",
        "survey_depth",
        "survey_measuring_point",
        "survey_diameter",
        "survey_curbing_depth",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "survey_use_of_well",
        "remarks",
        "sample_taken",
      ],
      "Ground Water Sample": {
        well_bp_id: [
          "well_bp_id",
          "th_value",
          "ca_value",
          "mg_value",
          "na_value",
          "k_value",
          "co3_value",
          "hco3_value",
          "cl_value",
          "so4_value",
          "no3_value",
        ],
        well_hm_id: ["well_hm_id", "f_value", "u_value"],
      },
    },
    Piezometer: {
      "Well Profile": [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "key_no",
        "vendor",
        
      ],
      "Survey Details": [
        "name",
        "session_end_date",
        "session_start_date",
        "assign_date",
        "createdat",
        // "survey_depth",
        "protection_box_status",
        "type_of_lock",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "remarks",
        "sample_taken",
      ],
      "Ground Water Sample": {
        well_bp_id: [
          "well_bp_id",
          "th_value",
          "ca_value",
          "mg_value",
          "na_value",
          "k_value",
          "co3_value",
          "hco3_value",
          "cl_value",
          "so4_value",
          "no3_value",
        ],
        well_hm_id: ["well_hm_id", "f_value", "u_value"],
      },
    },
  };
  const handleDownloadExcel = (wellType) => {
    // Clear previously selected fields
    setSelectedFields([]); // Reset selected fields

    setSelectedWellType(wellType); // Store the selected well type
    setShowFieldSelectionModal(true); // Show the modal
  };

  const handleFieldSelection = () => {
    // Generate the Excel sheet with the selected fields
    generateExcel(selectedFields);
    setShowFieldSelectionModal(false); // Close the modal after selection
  };

  const generateExcel = (fields) => {
    const filterData = survey.filter((well) =>
      well?.well_type.toLowerCase().includes(selectedWellType.toLowerCase())
    );

    const fixedOrder = {
      "Dug Well": [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "curbing_depth",
        "use_of_well",
        "name",
        "session_end_date",
        "session_start_date",
        "assign_date",
        "createdat",
        "survey_depth",
        "survey_measuring_point",
        "survey_diameter",
        "survey_curbing_depth",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "survey_use_of_well",
        "remarks",
        "sample_taken",
        "well_bp_id",
        "th_value",
        "ca_value",
        "mg_value",
        "na_value",
        "k_value",
        "co3_value",
        "hco3_value",
        "cl_value",
        "so4_value",
        "no3_value",
        "well_hm_id",
        "f_value",
        "u_value",
      ],
      Piezometer: [
        "state",
        "district_name",
        "firka",
        "village",
        "ex_well_id",
        "well_type",
        "status",
        "location_detail",
        "latitude",
        "longitude",
        "dms_latitude",
        "dms_longitude",
        "depth",
        "measuring_point",
        "diameter",
        "key_no",
        "vendor",        
        "name",
        "session_start_date",
        "session_end_date",
        "assign_date",
        "createdat",
        "survey_depth",
        "protection_box_status",
        "type_of_lock",
        "water_level",
        "electrical_conductivity",
        "temperature",
        "ph_value",
        "remarks",
        "sample_taken",
        "well_bp_id",
        "th_value",
        "ca_value",
        "mg_value",
        "na_value",
        "k_value",
        "co3_value",
        "hco3_value",
        "cl_value",
        "so4_value",
        "no3_value",
        "well_hm_id",
        "f_value",
        "u_value",
      ],
    };
    const sortedFields = fixedOrder[selectedWellType].filter((field) =>
      fields.includes(field)
    );

    const formattedData = filterData.map((well, i) => {
      let formattedWell = { "No.": i + 1 };

      sortedFields.forEach((field) => {
        // Format date fields
        if (
          field === "session_start_date" ||
          field === "session_end_date" ||
          field === "assign_date" ||
          field === "completed_date" ||
          field === "createdat"

        ) {
          formattedWell[fieldNames[field] || field] = formatDateToShow(
            well[field.toLowerCase()]
          );
        } else if (field === "sample_taken") {
          formattedWell[fieldNames[field] || field] =
            well[field.toLowerCase()] === 1 ? "Taken" : "Not Taken";
        } else {
          formattedWell[fieldNames[field] || field] =
            well[field.toLowerCase()] || "-";
        }
      });

      return formattedWell;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Adjust column widths based on the maximum content length in each column
    worksheet["!cols"] = sortedFields.map((field) => {
      const maxLength = Math.max(
        fieldNames[field]?.length || field.length,
        ...formattedData.map(
          (row) => row[fieldNames[field] || field]?.toString().length || 0
        )
      );
      return { wpx: (maxLength + 2) * 8 };
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `${selectedWellType} Data`
    );

    // Save the workbook
    XLSX.writeFile(
      workbook,
      `${selectedDistrict?.district_name.toLowerCase()}-${selectedWellType.toLowerCase()}-data.xlsx`
    );
  };

  const closeFieldSelectionModal = () => {
    setShowFieldSelectionModal(false);
  };

  // const handleDownloadExcel = (wellType) => {
  //   // Format data based on well_type
  //   const filterData = survey.filter((well) =>
  //     well?.well_type.toLowerCase().includes(wellType?.toLowerCase())
  //   );

  //   const formattedData = filterData.map((well, i) => {
  //     if (well?.well_type.toLowerCase().includes("dug well")) {
  //       return {
  //         "No.": i + 1,
  //         District: selectedDistrict?.district_name || "-",
  //         "Well Type": well?.well_type || "-",
  //         "Well ID": well?.well_id || "-",
  //         Village: well?.village || "-",
  //         Location: well?.location_detail || "-",
  //         Latitude: well?.latitude || "-",
  //         Longitude: well?.longitude || "-",
  //         Status: well?.status || "-",
  //         "Survey By": well?.name || "-",
  //         "Submitted Date": well?.completed_date,
  //         "Survey Depth": well?.survey_depth || "-",
  //         "Survey Diameter": well?.survey_diameter || "-",
  //         "Survey Measuring Point": well?.survey_measuring_point || "-",
  //         "Water Level(mbgl)": well?.water_level || "-",
  //         "Electrical Conductivity": well?.electrical_conductivity || "-",
  //         "Temperature(c*)": well?.temperature || "-",
  //         PH: well?.ph_value || "-",
  //         "Base Parameter": well?.well_bp_id || "-",
  //         TH: well?.th_value || "-",
  //         Ca: well?.ca_value || "-",
  //         Mg: well?.mg_value || "-",
  //         Na: well?.na_value || "-",
  //         K: well?.k_value || "-",
  //         CO3: well?.co3_value || "-",
  //         HCO3: well?.hco3_value || "-",
  //         CL: well?.cl_value || "-",
  //         SO4: well?.so4_value || "-",
  //         NO3: well?.no3_value || "-",
  //         "Heavy Metal": well?.well_hm_id || "-",
  //         F: well?.f_value || "-",
  //         U: well?.u_value || "-",
  //         Remark: well?.remarks || "-",
  //       };
  //     } else if (well?.well_type.toLowerCase().includes("piezometer")) {
  //       return {
  //         "No.": i + 1,
  //         District: selectedDistrict?.district_name,
  //         "Well Type": well.well_type || "-",
  //         "Well ID": well?.well_id || "-",
  //         Village: well?.village || "-",
  //         Location: well?.location_detail || "-",
  //         Latitude: well?.latitude || "-",
  //         Longitude: well?.longitude || "-",
  //         Status: well?.status || "-",
  //         "Survey By": well?.name || "-",
  //         "Submitted Date": well?.completed_date,
  //         "Key No": well?.key_no || "-",
  //         "Depth(m)": well?.depth || "-",
  //         "Diameter(m)": well?.diameter || "-",
  //         "Measuring Point(magl)": well?.measuring_point || "-",
  //         Vendor: well?.vendor || "-",
  //         "Protection Box": well?.protection_box_status || "-",
  //         "Type Of Lock": well?.type_of_lock || "-",
  //         "Water Level(mbgl)": well?.water_level || "-",
  //         "Electrical Conductivity": well?.electrical_conductivity || "-",
  //         "Temperature(c*)": well?.temperature || "-",
  //         PH: well?.ph_value || "-",
  //         "Base Parameter": well?.base_parameter || "-",
  //         TH: well?.th_value || "-",
  //         Ca: well?.ca_value || "-",
  //         Mg: well?.mg_value || "-",
  //         Na: well?.na_value || "-",
  //         K: well?.k_value || "-",
  //         CO3: well?.co3_value || "-",
  //         HCO3: well?.hco3_value || "-",
  //         CL: well?.cl_value || "-",
  //         SO4: well?.so4_value || "-",
  //         NO3: well?.no3_value || "-",
  //         "Heavy Metal": well?.heavy_metal || "-",
  //         F: well?.f_value || "-",
  //         U: well?.u_value || "-",
  //         Remark: well?.remarks || "-",
  //       };
  //     }
  //     return {};
  //   });

  //   const worksheet = XLSX.utils.json_to_sheet(formattedData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

  //   // Save the workbook
  //   XLSX.writeFile(
  //     workbook,
  //     `${selectedDistrict?.district_name.toLowerCase()}-${wellType.toLowerCase()}-data.xlsx`
  //   );
  // };

  const filteredBySampleTaken = survey?.filter(
    (survey) => survey?.sample_taken === 1
  );

  const handleDownloadSampleSheetAsExcel = () => {
    if (!filteredBySampleTaken?.length > 0) return;
    const formattedData = filteredBySampleTaken.map((well, i) => {
      return {
        "Ex Well ID": well?.ex_well_id ?? "",
        State: well?.state,
        District: well?.district_name,
        Firka: well?.firka,
        Village: well?.village,
        "Well Type": well?.well_type,
        Latitude: well?.latitude,
        Longitude: well?.longitude,
        "Date of Collection": well?.completed_date,
        "Base Parameter": well?.base_parameter,
        "TH Value": "",
        "Ca Value": "",
        "Mg Value": "",
        "Na Value": "",
        "K Value": "",
        "HCO3 Value": "",
        "Cl Value": "",
        "SO4 Value": "",
        "NO3 Value": "",
        "CO3 Value": "",
        "Heavy Metal": well?.well_hm_id,
        "F Value": "",
        "U Value": "",
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Well Data");

    XLSX.writeFile(
      workbook,
      `${selectedDistrict?.district_name.toLowerCase()}-${emp_name}-sample-sheet-data.xlsx`
    );
  };

  const handleSearch = (searchItem) => {
    if (searchItem) {
    }
    const filter = dateFilteredSurveys?.filter(
      (item) =>
        item?.village?.toLowerCase()?.includes(searchItem?.toLowerCase()) ||
        item?.well_type?.toLowerCase()?.includes(searchItem?.toLowerCase()) ||
        item?.ex_well_id?.toLowerCase()?.includes(searchItem?.toLowerCase())
    );
    setFilteredSurveys(filter);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };
  const handleDateFilter = () => {
    if (!selectedDate) return;

    const filtered = survey?.filter((item) => {
      const surveyDate = parseDate(item?.completed_date);
      return surveyDate?.toDateString() === selectedDate?.toDateString();
    });

    setFilteredSurveys(filtered);
    setDateFilteredSurveys(filtered);
    setOpenCalendar(false);
  };

  const handleApproveSelected = async () => {
    const body = {
      well_id: selectedWellsToApprove?.wellIds,
      Status: "Approved",
      admin_name: "Jalsakthi",
      survey_id: selectedWellsToApprove?.surveyIds,
    };

    try {
      setShowProgress("button_loading");
      const response = await axiosInstance.put(`/api/well/post/Approve`, body);
      if (!response.ok) {
        throw new Error("Error");
      }
    } catch (error) {
      console.error("Error updating status", error);
    } finally {
      setShowProgress("");
    }
  };

  const isAllSelected = unApprovedSurveys?.every(
    (well) =>
      selectedWellsToApprove?.wellIds?.includes(well.well_id) &&
      selectedWellsToApprove?.surveyIds?.includes(well.survey_id)
  );

  const handleChooseAll = () => {
    if (isAllSelected) {
      dispatch(clearWellsForApproval());
    } else {
      dispatch(
        selectAllWellsForApproval(
          unApprovedSurveys?.map((well) => well?.well_id)
        )
      );
      dispatch(
        selectAllWellsSurveyIdForApproval(
          unApprovedSurveys?.map((well) => well?.survey_id)
        )
      );
    }
  };

  const handleNavigateToMissedSurveys = () => {
    navigate(
      `/missed-survey/${emp_id}/${emp_name}/${districtId}`, {
        state: {emp_assigned_date}
      }
    )
  };

  return (
    <>
      <div className="w-[90%] mx-auto bg-softGrey">
        <div className="my-2 font-poppins flex items-center justify-between">
          <h2 className="font-bold relative flex items-center capitalize text-blackSubDark text-lg">
            <div
              onClick={() => navigate("/reports-and-analytics")}
              className="cursor-pointer w-fit px-2 py-1 rounded-full hover:bg-black hover:bg-opacity-10"
            >
              <FaArrowLeft className="inline-block text-skyBlue" />
            </div>
            {`${emp_name} Completed Survey`}
          </h2>
        </div>
        {showProgress === "screen_loading" ? (
          <div className="w-[90%] mx-auto h-[90vh] flex items-center justify-center">
            <CircularProgress size={24} />
          </div>
        ) : null}
        {showScreen ? (
          <div className="h-24">
            <div className="my-4 w-full h-full flex items-center justify-between gap-3">
              <div className="w-[45%]">
                {selectedDistrict && (
                  <SelectedDistrictTable selectedDistrict={selectedDistrict} />
                )}
              </div>
              <div className="bg-[#3EB4E9] h-full rounded-2xl w-[20%] px-4 py-2 flex items-start justify-center flex-col gap-2">
                <button
                  disabled={!filteredBySampleTaken?.length > 0}
                  onClick={handleDownloadSampleSheetAsExcel}
                  className={`${
                    !filteredBySampleTaken?.length > 0
                      ? "text-textGrey"
                      : "text-black"
                  } p-1 focus:outline-none shadow-sm w-full text-xs font-medium rounded-md bg-white flex items-center justify-center gap-1`}
                >
                  <img
                    className={`w-5 ${
                      !filteredBySampleTaken?.length > 0 && "grayscale"
                    }`}
                    src={excel}
                    alt="excel_img"
                  />{" "}
                  Download Lab Sample Sheet
                </button>
                <button
                  onClick={handleNavigateToMissedSurveys}
                  className="p-1 shadow-sm w-full text-sm font-medium rounded-md bg-white"
                >
                  Missed Survey
                </button>
              </div>
              <div className="w-[35%]">
                <div className="h-24 bg-[#3EB4E9] rounded-2xl font-poppins">
                  <h1 className="font-medium py-2  text-center text-sm">
                    Download Survey Well Data in Excel
                  </h1>
                  <div className="flex justify-center items-center gap-6 pt-2">
                    <div
                      onClick={() => handleDownloadExcel("Dug Well")}
                      className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
                    >
                      <img className="w-5" src={excel} alt="" />
                      <span>Dug Well</span>
                    </div>
                    <div
                      onClick={() => handleDownloadExcel("Piezometer")}
                      className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
                    >
                      <img className="w-5" src={excel} alt="" />
                      <span>Piezometer</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 relative flex items-center gap-5">
              <div
                onClick={() => setOpenCalendar(!openCalendar)}
                className="bg-white font-poppins rounded-md shadow flex items-center gap-5 w-fit px-2 py-[9px] text-[13px] cursor-pointer"
              >
                {selectedDate
                  ? selectedDate?.toDateString()
                  : "Survey Submitted Date"}{" "}
                <span>
                  <IoMdArrowDropdown
                    className={`${
                      openCalendar ? "rotate-180" : ""
                    } transition duration-300 text-lg`}
                  />
                </span>
              </div>
              <div
                className={`absolute font-poppins ${
                  openCalendar
                    ? "top-10 z-30 shadow-lg opacity-100"
                    : "-top-32 -z-30 opacity-0"
                }  left-0 bg-white rounded-md p-3 w-[240px] text-xs transition-all duration-300`}
              >
                <Calendar
                  value={selectedDate}
                  onChange={setSelectedDate}
                  className="flex flex-col items-center gap-2 text-center"
                  tileClassName={({ date }) => {
                    const isSelected =
                      selectedDate &&
                      date.toDateString() === selectedDate.toDateString();
                    const hasSurvey = survey.some(
                      (item) =>
                        new Date(item.date).toDateString() ===
                        date.toDateString()
                    );

                    return [
                      "p-2 rounded-full transition-all duration-200",
                      "hover:bg-lightBlue hover:text-buttonPrimary",
                      isSelected ? "bg-buttonPrimary text-white font-bold" : "",
                      hasSurvey ? "bg-green-100 text-green-600" : "",
                    ];
                  }}
                />
                <div className="flex items-center justify-end gap-3 mt-2">
                  <span
                    onClick={() => setOpenCalendar(false)}
                    className="cursor-pointer hover:underline"
                  >
                    Cancel
                  </span>
                  <button
                    disabled={!selectedDate}
                    onClick={() => {
                      setOpenCalendar(false);
                      setSelectedDate();
                      setFilteredSurveys(survey);
                    }}
                    className={`${
                      selectedDate
                        ? "cursor-pointer hover:underline"
                        : "text-lightGrey"
                    } transition-colors ml-2`}
                  >
                    Reset
                  </button>
                  <button
                    disabled={!selectedDate}
                    onClick={handleDateFilter}
                    className={`${
                      selectedDate
                        ? "cursor-pointer hover:bg-lightBlue text-buttonPrimary"
                        : "text-lightGrey"
                    } font-semibold p-2 rounded-full transition-colors`}
                  >
                    Ok
                  </button>
                </div>
              </div>

              <div className=" bg-white flex items-center w-[450px] h-[38px] border border-gray-300 rounded-lg px-4 justify-start gap-5 font-roboto shadow-md">
                <IoSearchOutline className="text-textGrey" />
                <input
                  type="text"
                  placeholder="Search by Well Id, Well Type, Village"
                  className="focus:outline-none w-full text-textGrey text-[14px]"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {filteredSurveys && filteredSurveys?.length > 0 ? (
                <button
                  disabled={
                    selectedWellsToApprove?.wellIds?.length <= 0 ||
                    selectedWellsToApprove?.surveyIds?.length <= 0
                  }
                  onClick={() => {
                    handleApproveSelected();
                    setUpdatedModal(true);
                  }}
                  className={`${
                    selectedWellsToApprove?.wellIds?.length > 0 &&
                    selectedWellsToApprove?.surveyIds?.length > 0
                      ? "bg-skyBlue text-white"
                      : "bg-gray-300"
                  }  px-8 py-2 m-2 text-[13px] rounded-md transition-colors duration-300`}
                >
                  {showProgress === "button_loading" ? (
                    <CircularProgress color="default" size={13} />
                  ) : (
                    "Approve Selected"
                  )}
                </button>
              ) : null}
            </div>
            <div className="flex items-center justify-between w-[56%]">
              {filteredSurveys &&
              filteredSurveys?.length > 0 &&
              unApprovedSurveys?.length > 0 ? (
                <p className="font-poppins font-medium">
                  {filteredSurveys?.length} completed surveys
                </p>
              ) : null}
              {unApprovedSurveys?.length > 0 && (
                <div className="flex items-center justify-end w-[56%] gap-1 cursor-pointer px-3 my-2">
                  <span className="font-poppins">Select All</span>
                  <input
                    checked={isAllSelected}
                    type="checkbox"
                    onChange={handleChooseAll}
                    className="w-4 h-4 cursor-pointer"
                  />
                </div>
              )}
            </div>

            <div className="my-4 flex items-start gap-5">
              <div className="w-[56%] relative flex flex-col gap-2 h-[65vh] overflow-y-auto custom-scrollbar">
                {filteredSurveys && filteredSurveys?.length > 0 ? (
                  filteredSurveys?.map((item, i) => (
                    <SurveyListCard
                      cardNumber={i + 1}
                      assignedDate={date}
                      key={item?.well_id}
                      item={item}
                      selectedWell={selectedWell}
                      handleShowDetails={handleShowDetails}
                    />
                  ))
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <p className="font-medium">No completed surveys</p>
                  </div>
                )}
              </div>
              <div className="w-[44%]">
                {showDetailsModal && selectedWell && (
                  <WellHistoryDetailsCard
                    setSelectedWell={setSelectedWell}
                    assignedDate={date}
                    approveBtn={true}
                    editBtn={true}
                    well={selectedWell}
                    closeDetailsModal={closeDetailsModal}
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {updatedModal === "sample updated" ? (
        <UpdateSuccessModal
          onSuccessFunction={surveyReview}
          message="Sample updated successfully!"
        />
      ) : null}
      {updatedModal === true ? (
        <UpdateSuccessModal
          onSuccessFunction={surveyReview}
          message="Surveys approved successfully!"
        />
      ) : null}
      {/* Add the Field Selection Modal */}
      {showFieldSelectionModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div className="bg-white rounded-md p-5 w-auto">
            <h3 className="font-semibold mb-4">
              {fieldTitles[selectedWellType] || "Select Fields to Download"}
            </h3>

            {/* Well Profile Section */}
            <div className="mb-4">
              <h4 className="font-semibold mb-2">Well Profile</h4>
              <div className="max-h-70 overflow-auto grid grid-cols-5 gap-x-4">
                {availableFields[selectedWellType]?.["Well Profile"]?.map(
                  (field) => (
                    <div key={field} className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        id={field}
                        value={field}
                        onChange={(e) => {
                          const newFields = e.target.checked
                            ? [...selectedFields, field]
                            : selectedFields.filter((f) => f !== field);
                          setSelectedFields(newFields);
                        }}
                      />
                      <label
                        htmlFor={field}
                        className="ml-2"
                        style={{ fontSize: "13px" }}
                      >
                        {fieldNames[field] || field}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* Survey Details Section */}
            <div>
              <h4 className="font-semibold mb-2">Survey Details</h4>
              <div className="max-h-70 overflow-auto grid grid-cols-5 gap-x-4">
                {availableFields[selectedWellType]?.["Survey Details"]?.map(
                  (field) => (
                    <div key={field} className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        id={field}
                        value={field}
                        onChange={(e) => {
                          const newFields = e.target.checked
                            ? [...selectedFields, field]
                            : selectedFields.filter((f) => f !== field);
                          setSelectedFields(newFields);
                        }}
                      />
                      <label
                        htmlFor={field}
                        className="ml-2"
                        style={{ fontSize: "13px" }}
                      >
                        {fieldNames[field] || field}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Ground Water Sample Section */}
            <div>
              <h4 className="font-semibold mb-2">Ground Water Sample</h4>

              {/* Base Parameters */}
              <div className="mb-4">
                <h5 className="font-semibold mb-2">Base Parameters</h5>
                <div className="max-h-70 overflow-auto grid grid-cols-5 gap-x-4">
                  {availableFields[selectedWellType]?.[
                    "Ground Water Sample"
                  ]?.well_bp_id?.map((field) => (
                    <div key={field} className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        id={field}
                        value={field}
                        onChange={(e) => {
                          const newFields = e.target.checked
                            ? [...selectedFields, field]
                            : selectedFields.filter((f) => f !== field);
                          setSelectedFields(newFields);
                        }}
                      />
                      <label
                        htmlFor={field}
                        className="ml-2"
                        style={{ fontSize: "13px" }}
                      >
                        {fieldNames[field] || field}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Heavy Metals */}
              <div>
                <h5 className="font-semibold mb-2">Heavy Metals</h5>
                <div className="max-h-70 overflow-auto grid grid-cols-5 gap-x-4">
                  {availableFields[selectedWellType]?.[
                    "Ground Water Sample"
                  ]?.well_hm_id?.map((field) => (
                    <div key={field} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={field}
                        value={field}
                        onChange={(e) => {
                          const newFields = e.target.checked
                            ? [...selectedFields, field]
                            : selectedFields.filter((f) => f !== field);
                          setSelectedFields(newFields);
                        }}
                      />
                      <label
                        htmlFor={field}
                        className="ml-2"
                        style={{ fontSize: "13px" }}
                      >
                        {fieldNames[field] || field}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-4">
              <button
                onClick={closeFieldSelectionModal}
                className="text-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleFieldSelection}
                className="bg-blue-500 text-white p-2 rounded-md"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveyReview;
