import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedEmp: null,
  selectedDist: null,
  selectedWell: [],
  wellDetails: [],
  selectedWellsForApproval: {
    wellIds: [],
    surveyIds: [],
  },
};

const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    setSelectedEmp: (state, action) => {
      state.selectedEmp = action.payload;
    },
    setSelectedDist: (state, action) => {
      state.selectedDist = action.payload;
    },
    setAllWells: (state, action) => {
      state.wellDetails = action.payload;
    },
    toggleWell: (state, action) => {
      const wellIds = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      wellIds.forEach((wellId) => {
        if (state.selectedWell.includes(wellId)) {
          state.selectedWell = state.selectedWell.filter((id) => id !== wellId);
        } else {
          state.selectedWell.push(wellId);
        }
      });
    },
    toggleWellForApproval: (state, action) => {
      const wellIds = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      wellIds.forEach((wellId) => {
        if (state.selectedWellsForApproval.wellIds.includes(wellId)) {
          state.selectedWellsForApproval.wellIds =
            state.selectedWellsForApproval.wellIds.filter(
              (id) => id !== wellId
            );
        } else {
          state.selectedWellsForApproval.wellIds.push(wellId);
        }
      });
    },
    toggleWellSurveyIdForApproval: (state, action) => {
      const wellIds = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      wellIds.forEach((wellId) => {
        if (state.selectedWellsForApproval.surveyIds.includes(wellId)) {
          state.selectedWellsForApproval.surveyIds =
            state.selectedWellsForApproval.surveyIds.filter(
              (id) => id !== wellId
            );
        } else {
          state.selectedWellsForApproval.surveyIds.push(wellId);
        }
      });
    },
    selectAllWells: (state, action) => {
      action.payload.forEach((well) => state.selectedWell.push(well));
    },
    selectAllWellsForApproval: (state, action) => {
      action.payload.forEach((well) =>
        state.selectedWellsForApproval.wellIds.push(well)
      );
    },
    selectAllWellsSurveyIdForApproval: (state, action) => {
      action.payload.forEach((well) =>
        state.selectedWellsForApproval.surveyIds.push(well)
      );
    },
    clearWells(state) {
      state.selectedWell = [];
    },
    clearWellsForApproval(state) {
      state.selectedWellsForApproval.wellIds = [];
      state.selectedWellsForApproval.surveyIds = [];
    },
    clearAssignment(state) {
      state.selectedEmp = null;
      state.selectedDist = null;
      state.selectedWell = [];
      state.wellDetails = [];
    },
  },
});

export const {
  setSelectedEmp,
  setSelectedDist,
  setAllWells,
  toggleWell,
  clearAssignment,
  selectAllWells,
  clearWells,
  toggleWellForApproval,
  selectAllWellsForApproval,
  clearWellsForApproval,
  toggleWellSurveyIdForApproval,
  selectAllWellsSurveyIdForApproval,
} = assignmentSlice.actions;

export const getSelectedEmp = (state) => state.assignment.selectedEmp;
export const getSelectedDist = (state) => state.assignment.selectedDist;
export const getSelectedWell = (state) => state.assignment.selectedWell;
export const getWellDetails = (state) => state.assignment.wellDetails;

export default assignmentSlice.reducer;
