import React, { useContext, useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { useSelector } from "react-redux";
import axiosInstance from "../interceptor/axiosInstance";
import { EmpAnalyticsContext } from "../context/EmpAnalyticsContext";
import { formatDateToShow } from "../utils/formatDate";

const filters = ["District", "Employee", "Session"];

const FilterComponents = () => {
  const {
    setDistrictData,
    setEmpData,
    setSessionData,
    setFilterDropDown,
    filterDropDown,
    districtData,
    empData,
    sessionData,
    selectedFilters,
    setSelectedFilters,
  } = useContext(EmpAnalyticsContext);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [emps, setEmps] = useState(empData);
  const [dists, setDists] = useState(districtData);
  const state = useSelector((state) => state.state.stateName);

  const handleDropDownSearch = (type, e) => {
    const value = e.target.value;
    setSearchInputValue(value);
    if (type === "Employee") {
      const filteredEmps = empData?.filter((emp) =>
        emp?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setEmps(filteredEmps);
    }
    if (type === "District") {
      const filteredDists = districtData?.filter((dt) =>
        dt?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setDists(filteredDists);
    }
  };

  useEffect(() => {
    setEmps(empData);
    setDists(districtData);
    setSearchInputValue("");
  }, [filterDropDown]);

  const getDataForDropDown = (filter) => {
    switch (filter) {
      case "District":
        return dists?.map((district, i) => (
          <div
            onClick={() => {
              setSelectedFilters((prev) => ({
                ...prev,
                district: district,
              }));
              setFilterDropDown(null);
            }}
            key={i}
            className={`cursor-pointer ${
              selectedFilters?.district === district
                ? "bg-buttonPrimary text-white hover:bg-buttonPrimary"
                : "hover:bg-gray-100"
            }  px-4 py-2`}
          >
            {district}
          </div>
        ));
      case "Employee":
        return emps?.map((employee, index) => (
          <div
            onClick={() => {
              setSelectedFilters((prev) => ({
                ...prev,
                employee: employee?.id,
              }));
              setFilterDropDown(null);
            }}
            key={index}
            className={`capitalize ${
              selectedFilters?.employee === employee?.id
                ? "bg-buttonPrimary text-white hover:bg-buttonPrimary"
                : "hover:bg-gray-100"
            } cursor-pointer  px-4 py-2`}
          >
            {employee.name}
          </div>
        ));
      case "Session":
        return sessionData?.length > 0 ? (
          sessionData?.map((session, index) => (
            <div
              onClick={() => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  session: session,
                }));
                setFilterDropDown(null);
              }}
              key={index}
              className={`cursor-pointer ${
                selectedFilters?.session === session
                  ? "bg-buttonPrimary text-white hover:bg-buttonPrimary"
                  : "hover:bg-gray-100"
              }  px-4 py-2`}
            >
              {formatDateToShow(session.session_start_date)} -{" "}
              {session?.session_end_date
                ? formatDateToShow(session?.session_end_date)
                : "Not assigned"}
            </div>
          ))
        ) : (
          <div className="px-4 py-2">No Sessions Found!</div>
        );
      default:
        return null;
    }
  };

  const getDropDown = (type) => {
    const selectedValue = {
      District: {
        value: selectedFilters?.district,
        width: "150px",
      },
      Employee: {
        value: selectedFilters?.employee,
        width: "160px",
      },
      Session: {
        value: selectedFilters?.session
          ? `${formatDateToShow(
              selectedFilters?.session?.session_start_date
            )} - 
           ${
             selectedFilters?.session?.session_end_date
               ? formatDateToShow(selectedFilters?.session?.session_end_date)
               : "Not assigned"
           }`
          : null,
        width: "217px",
      },
    };

    return (
      <div
        onClick={() => setFilterDropDown(filterDropDown === type ? null : type)}
        style={{ width: selectedValue[type]?.width }}
        className={`bg-white z-30 text-textGrey rounded-md px-4 py-2 text-xs flex items-center justify-between gap-2 font-medium`}
      >
        {filterDropDown === type && type !== "Session" ? (
          <div onClick={(e) => e.stopPropagation()}>
            <input
              placeholder={`Search ${type}`}
              value={searchInputValue}
              onChange={(e) => handleDropDownSearch(type, e)}
              className="focus:outline-none bg-inherit w-full"
            />
          </div>
        ) : (
          <span className={selectedValue[type]?.value && "text-black"}>
            {selectedValue[type]?.value || `Choose ${type}`}
          </span>
        )}

        <span
          className={`${
            filterDropDown === type ? "rotate-180" : "rotate-0"
          } transition-all duration-300`}
        >
          <FaChevronDown />
        </span>
      </div>
    );
  };
  const getDistricts = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/district/get/data/${state}`
      );
      const dists = response?.data?.Data;
      const districtName = dists?.map((dist) => dist?.district_name);
      setDistrictData(districtName);
    } catch (err) {
      console.log(err);
    }
  };
  const getEmployees = async () => {
    try {
      const response = await axiosInstance.get(`api/employee/all/users/data`);
      const users = response?.data?.Data;
      const filteredData = users.map((user) => ({
        id: user.emp_id,
        name: user.name,
      }));
      setEmpData(filteredData);
    } catch (err) {
      console.log(err);
    }
  };
  const getSessions = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/employee/get/session/status`
      );
      const data = response?.data?.Data;
      setSessionData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDistricts();
  }, [state]);

  useEffect(() => {
    getEmployees();
    getSessions();
  }, []);

  return (
    <>
      {filters?.map((filter, i) => (
        <div className="relative cursor-pointer" key={i}>
          {getDropDown(filter)}
          <div
            className={`${
              filterDropDown === filter
                ? "opacity-100 z-10 top-9"
                : "opacity-0 -z-20 top-0"
            } bg-white rounded-md absolute min-w-full max-h-[300px] overflow-y-auto hide-scrollbar text-xs left-0 shadow-md transition-all duration-300`}
          >
            {getDataForDropDown(filter)}
          </div>
        </div>
      ))}
    </>
  );
};

export default FilterComponents;
