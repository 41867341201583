export const tableHeadersDugWell = [
  "No.",
  "Well Id",
  "Well Type",
  "Employee",
  "District",
  "Village",
  "Firka",
  "Location Details",
  "Survey By",
  "Survey Assigned",
  "Submitted Date",
  "Depth of Well (mbgl)",
  "Diameter of Well (m)",
  "Measuring Point (magl)",
  "Curbing Depth (mbgl)",
  "Water Level (mbmp)",
  "Temperature °C",
  "Electrical Conductivity",
  "PH",
  "Use of Well",
  "Sample Status",
  "Base Parameter",
  "TH",
  "Ca",
  "Mg",
  "Na",
  "K",
  "CO3",
  "HCO3",
  "CL",
  "SO4",
  "NO3",
  "Heavy Metal",
  "F",
  "U",
  "Remarks",
];

export const tableHeadersPiezometer = [
  "No.",
  "Well Id",
  "Well Type",
  "Employee",
  "District",
  "Village",
  "Firka",
  "Location Details",
  "Survey By",
  "Survey Assigned",
  "Submitted Date",
  "Depth of Well (mbgl)",
  "Diameter of Well (m)",
  "Measuring Point (magl)",
  "Protection Box Status",
  "Type of Lock",
  "Water Level (mbmp)",
  "Temperature °C",
  "Electrical Conductivity",
  "PH",
  "Key No",
  "Vendor",
  "Sample Status",
  "Base Parameter",
  "TH",
  "Ca",
  "Mg",
  "Na",
  "K",
  "CO3",
  "HCO3",
  "CL",
  "SO4",
  "NO3",
  "Heavy Metal",
  "F",
  "U",
  "Remarks",
];

export const tableHeadersAllType = [
  "No.",
  "Well Id",
  "Well Type",
  "Employee",
  "District",
  "Village",
  "Firka",
  "Location Details",
  "Survey By",
  "Survey Assigned",
  "Submitted Date",
  "Depth of Well (mbgl)",
  "Diameter of Well (m)",
  "Measuring Point (magl)",
  "Curbing Depth (mbgl)",
  "Protection Box Status",
  "Type of Lock",
  "Water Level (mbmp)",
  "Temperature °C",
  "Electrical Conductivity",
  "PH",
  "Use of Well",
  "Key No",
  "Vendor",
  "Sample Status",
  "Base Parameter",
  "TH",
  "Ca",
  "Mg",
  "Na",
  "K",
  "CO3",
  "HCO3",
  "CL",
  "SO4",
  "NO3",
  "Heavy Metal",
  "F",
  "U",
  "Remarks",
];

export const headerToKeyMap = {
  "Well Id": "ex_well_id",
  "Well Type": "well_type",
  Employee: "name",
  District: "district_name",
  Village: "village",
  Firka: "firka",
  "Location Details": "location_detail",
  "Survey By": "name",
  "Survey Assigned": "assign_date",
  "Submitted Date": "createdat",
  "Depth of Well (mbgl)": "survey_depth",
  "Diameter of Well (m)": "survey_diameter",
  "Measuring Point (magl)": "survey_measuring_point",
  "Curbing Depth (mbgl)": "survey_curbing_depth",
  "Protection Box Status": "protection_box_status",
  "Type of Lock": "type_of_lock",
  "Water Level (mbmp)": "water_level",
  "Temperature °C": "temperature",
  "Electrical Conductivity": "electrical_conductivity",
  PH: "ph_value",
  "Use of Well": "survey_use_of_well",
  "Key No": "key_no",
  Vendor: "vendor",
  "Sample Status": "sample_taken",
  "Base Parameter": "well_bp_id",
  TH: "th_value",
  Ca: "ca_value",
  Mg: "mg_value",
  Na: "na_value",
  K: "k_value",
  CO3: "co3_value",
  HCO3: "hco3_value",
  CL: "cl_value",
  SO4: "so4_value",
  NO3: "no3_value",
  "Heavy Metal": "well_hm_id",
  F: "f_value",
  U: "u_value",
  Remarks: "remarks",
};
