import React, { useContext } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { DistrictsContext } from "../context/DistrictsContext";

const Back = ({ to }) => {
  const { setCurrTab } = useContext(DistrictsContext);

  const navigate = useNavigate();
  const goBack = () => {
    if (to) {
      navigate("/dashboard");
      setCurrTab("");
    } else {
      navigate(-1);
    }
  };
  return (
    <div
      onClick={goBack}
      className="cursor-pointer w-fit px-2 py-1 rounded-full hover:bg-black hover:bg-opacity-10"
    >
      <FaArrowLeft className="inline-block text-skyBlue" />
      <span className=" font-bold text-skyBlue text-xs">{to ? to : ""}</span>
    </div>
  );
};

export default Back;
