import React, { useContext, useState } from "react";
import upload from "../assets/upload_btn.png";
import UploadModal from "./UploadModal";
import { DistrictsContext } from "../context/DistrictsContext";

const UploadWell = ({ selectedDistrict, reload }) => {
  const [oepnModal, setOpenModal] = useState("");
  return (
    <>
      {oepnModal && (
        <UploadModal
          reload={reload}
          selectedDistrict={selectedDistrict}
          setOpenModal={setOpenModal}
          type={oepnModal}
        />
      )}
      <div className="h-24 px-20 bg-[#3EB4E9] rounded-2xl font-poppins">
        <h1 className="font-medium py-2  text-center text-base">
          Upload {selectedDistrict} well data
        </h1>
        <div className="flex justify-center items-center gap-6 pt-2">
          <div
            onClick={() => setOpenModal("Dug Well")}
            className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
          >
            <img className="w-5" src={upload} alt="" />
            <span>Dug Well</span>
          </div>
          <div
            onClick={() => setOpenModal("Piezometer")}
            className="cursor-pointer justify-center gap-2 flex items-center bg-white w-[130px] py-2 rounded-lg font-semibold text-xs"
          >
            <img className="w-5" src={upload} alt="" />
            <span>Piezometer</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadWell;
