export const toDMS = (latitude, longitude) => {
  const convertToDMS = (decimal, isLatitude) => {
    const degrees = Math.floor(Math.abs(decimal));
    const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
    const minutes = Math.floor(minutesDecimal);
    const seconds = Math.round((minutesDecimal - minutes) * 60);

    const direction = isLatitude
      ? decimal >= 0
        ? "N"
        : "S"
      : decimal >= 0
      ? "E"
      : "W";

    // Format each component for compact representation
    const formattedDegrees = String(degrees).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return {
      compactDMS: `${formattedDegrees}${formattedMinutes}${formattedSeconds}`,
      formattedDMS: `${formattedDegrees}°${formattedMinutes}'${formattedSeconds}\" ${direction}`,
    };
  };

  const latitudeResult = convertToDMS(latitude, true);
  const longitudeResult = convertToDMS(longitude, false);
  console.log(latitudeResult);
  console.log(longitudeResult);
  
  const latitudeDMS = latitudeResult.compactDMS;
  const longitudeDMS = longitudeResult.compactDMS;

  return {
    fullCompactDMS: `W${latitudeDMS}0${longitudeDMS}`,
    latitudeDMS: latitudeResult.formattedDMS,
    longitudeDMS: longitudeResult.formattedDMS,
  };
};
