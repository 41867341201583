import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmpAssignmentContext } from "../context/EmpAssignmentContext";
import AssignBtn from "../buttons/AssignBtn";
import { useDispatch } from "react-redux";
import { setSelectedEmp } from "../redux/assignmentSlice";
import axiosInstance from "../interceptor/axiosInstance";
import { CircularProgress } from "@mui/material";
import { setUsers } from "../redux/usersSlice";
import UpdateSuccessModal from "./UpdateSuccessModal";
import { DistrictsContext } from "../context/DistrictsContext";

const EmpListToAssign = ({ getEmpAssignment, searchTerms, selectEmp }) => {
  const [emps, setEmps] = useState([]);
  const [showProgress, setShowProgress] = useState();
  const { showAssignmentsForEmp, setShowAssignmentsForEmp } =
    useContext(EmpAssignmentContext);
  const { updatedModal } = useContext(DistrictsContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeUsers = emps?.filter((user) => user?.user_status === "Active");

  const filteredUser = activeUsers?.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerms.toLowerCase()) ||
      user.emp_id.toString().includes(searchTerms)
  );
  const getUsers = async () => {
    try {
      setShowProgress(true);
      const response = await axiosInstance.get("api/employee/all/users/data");
      const users = response?.data?.Data;
      setEmps(users);
      dispatch(setUsers(users));
    } catch (err) {
      console.error(err);
    }
    setShowProgress(false);
    selectEmp();
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleAssignClick = (user) => {
    dispatch(setSelectedEmp(user));
    navigate("/assign-work");
  };

  const handleSelectedEmp = (user) => {
    dispatch(setSelectedEmp(user));
    setShowAssignmentsForEmp(user?.emp_id);
    getEmpAssignment(user?.emp_id);
  };

  return (
    <>
      {showProgress === true ? (
        <div className="flex flex-col h-full justify-center items-center">
          <CircularProgress sx={{ margin: "24px" }} size={24} />
        </div>
      ) : (
        <div className="">
          {filteredUser?.length > 0 ? (
            <table className="w-full border-b-collapse">
              <thead>
                <tr className="text-sm font-bold">
                  <td className="px-4 py-2">#</td>
                  <td className="px-4 py-2">Emp Name</td>
                  <td className="px-4 py-2">Status</td>
                  <td className="px-4 py-2">Emp Id</td>
                  <td className="px-4 py-2">Survey Status</td>
                  <td className="px-4 py-2"></td>
                </tr>
              </thead>
              <tbody>
                {filteredUser?.map((user, index) => (
                  <tr
                    className={`${
                      showAssignmentsForEmp === user?.emp_id
                        ? "bg-softGrey"
                        : ""
                    } capitalize text-sm ${
                      index < emps.length - 1 ? "border-b" : ""
                    } hover:bg-softGrey cursor-pointer border-lightGrey text-sm`}
                    key={index}
                  >
                    <td
                      onClick={() => handleSelectedEmp(user)}
                      className="px-4 py-2"
                    >
                      {index + 1}
                    </td>
                    <td
                      onClick={() => handleSelectedEmp(user)}
                      className="px-4 py-2"
                    >
                      {user?.name?.length > 20
                        ? `${user.name.slice(0, 20)}...`
                        : user?.name}
                    </td>
                    <td
                      onClick={() => handleSelectedEmp(user)}
                      className={`px-4 py-2 font-medium ${
                        user?.user_status === "Active"
                          ? "text-green-500"
                          : "text-errorRed"
                      }`}
                    >
                      {user?.user_status}
                    </td>
                    <td
                      onClick={() => handleSelectedEmp(user)}
                      className="px-4 py-2"
                    >
                      {user?.emp_id}
                    </td>
                    <td
                      onClick={() => handleSelectedEmp(user)}
                      className="px-4 py-2"
                    >
                      {user?.assigned_status}
                    </td>
                    <td className="pl-4 py-2 relative">
                      <div
                        className="w-fit"
                        onClick={() => handleAssignClick(user)}
                      >
                        <AssignBtn label="Assign" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center my-4">No users found.</p>
          )}
        </div>
      )}
      {updatedModal === true ? (
        <UpdateSuccessModal
          onSuccessFunction={getUsers}
          message={"Work reassigned successfully!"}
        />
      ) : null}
    </>
  );
};

export default EmpListToAssign;
