import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stateName: "Tamil Nadu",
};

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    setState: (state, action) => {
      state.stateName = action.payload;
    },
  },
});

export const { setState } = stateSlice.actions;
export default stateSlice.reducer;
